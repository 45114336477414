/* Styles */
import {
    Container,
    Column1,
    Column2,
    ContainerRow,
    InvisibleContainer,
} from './services-included.styles';

import { useState, useContext, useEffect } from 'react';

/* Material ui */
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import ListItemText from '@material-ui/core/ListItemText';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core';

/* Context */
import { statePriceContext } from '../../contexts/StatePriceContext';
import Price from '../price/price.component';
import { clientContext } from '../../contexts/ClientContext';

// Components
import Subtotal from '../subtotal/subtotal.component';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '14rem',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14.5,
        '@media (max-width: 680px)': {
            paddingTop: 20,
        },
        '@media (max-width: 759px)': {
            textAlign: 'center',
            justifyContent: 'center',
            margin: '2vh auto',
            paddingBottom: '2vh',
            paddingRight: 10,
        },
    },
    column2Title: {
        textAlign: 'center',
        padding: '20px',
        '@media (max-width: 765px)': {
            display: 'none',
        },
    },
    title: {
        paddingTop: 20,
        paddingLeft: 10,
        marginBottom: -10,
        fontFamily: 'Roboto',
        fontWeight: '500',
        color: '#444446',
        '@media (max-width: 759px)': {
            marginBottom: -20,
            paddingLeft: 0,
            paddingRight: 10,
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    column2: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 200,
        padding: 10,
    },
    typography: {
        display: 'grid',
        fontFamily: 'Roboto, sans-serif',
        paddingLeft: 10,
        paddingTop: 30,
        letterSpacing: 0.8,
        fontWeight: 300,
        '@media (max-width: 759px)': {
            padding: '10px auto',
            paddingLeft: 0,
            justifyContent: 'center',
            textAlign: 'center',
            paddingRight: 10,
        },
    },
    listItem: {
        marginTop: -10,
    },
    subtotal: {
        padding: '40px 20px 20px 20px',
        color: '#424248',
        fontWeight: 300,
        '@media (max-width: 802px)': {
            fontSize: '1.5rem',
        },
        '@media (max-width: 530px)': {
            padding: 'auto',
            paddingRight: -20,
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            marginBottom: '-2rem',
        },
    },
    subtotalPrice: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '28px',
        letterSpacing: '1.2px',
        lineHeight: '10px',
        fontWeight: '400px',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 970px)': {
            marginBottom: '-3rem',
            justifyContent: 'end',
            transform: 'translateY(-87.5px)',
            marginRight: '2rem',
            borderLeft: 'none',
        },
        '@media (max-width: 802px)': {
            marginRight: '2rem',
            transform: 'translateY(-78px)',
        },

        '@media (max-width: 530px)': {
            transform: 'translateY(0px)',
            marginBottom: '2rem',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '3.2rem',
        },
        '@media (max-width: 360px)': {
            fontSize: '22px',
        },
    },
}));

export default function ServicesIncluded({ proposalData }) {
    const { statePrice } = useContext(statePriceContext);
    const { setServicesPercentages, serviceSubPrice } =
        useContext(clientContext);

    // state que guarda os serviços vindos do banco
    const [services, setServices] = useState([]);

    const [selectedServices, setSelectedServices] = useState([]);
    const [serviceOption, setServiceOption] = useState('');

    const classes = useStyles();

    const defaultPropsServices = {
        options: services,
        value: serviceOption,
        getOptionLabel: (option) => {
            return typeof option === 'object' ? option.toString() : option;
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        getOptionDisabled: (option) => {
            return !!selectedServices.find((curr) => curr === option);
        },
        onChange: (event, newValue) => {
            setServiceOption(newValue);
            if (!selectedServices.includes(newValue)) {
                setSelectedServices([...selectedServices, newValue]);
                setServicesPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    [newValue]: statePrice.services[newValue],
                }));
            }
        },
        onBlur: (event) => {
            if (
                event.target.value === serviceOption &&
                event.target.value !== '' &&
                !selectedServices.includes(event.target.value)
            ) {
                setServiceOption(event.target.value);
                setSelectedServices([...selectedServices, event.target.value]);
                setServicesPercentages((servicePercentage) => ({
                    ...servicePercentage,

                    [event.target.value]:
                        statePrice.services[event.target.value],
                }));
            }
        },
    };

    // coloca as entradas no input
    useEffect(() => {
        if (statePrice) {
            setServices(Object.keys(statePrice.services));
            if (proposalData) {
                const proposalServices = Object.entries(proposalData?.services)
                    .map((service) => {
                        if (service[1] !== null) return service[0];
                        else return null;
                    })
                    .filter((curr) => curr !== null);

                setSelectedServices(proposalServices);
            } else {
                setSelectedServices([
                    'Amostra de solo completa',
                    'Vetorização, Layout e Organização BD',
                    'Coleta de solo grid, método Drakkar',
                ]);
            }
        }
    }, [proposalData, statePrice]);

    useEffect(() => {
        const obj = {};
        selectedServices.map(
            (service) => (obj[service] = statePrice.services[service])
        );
        setServicesPercentages(obj);
    }, [selectedServices, setServicesPercentages, statePrice]);

    return (
        <>
            <InvisibleContainer id="services-included-container"></InvisibleContainer>
            <Container>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.title} variant="h5">
                            Serviços Inclusos:
                        </Typography>
                    </Column1>
                    <Column2>
                        <Typography
                            className={classes.column2Title}
                            variant="h5"
                        >
                            Acréscimos ou descontos:
                        </Typography>
                    </Column2>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            Opções:
                        </Typography>
                        <Autocomplete
                            {...defaultPropsServices}
                            id="input-services"
                            autoSelect
                            disableClearable
                            blurOnSelect="mouse"
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Column1>
                </ContainerRow>
                {selectedServices.map((value, index) => {
                    return (
                        <ContainerRow key={value}>
                            <Column1 className={classes.title}>
                                <List
                                    dense
                                    key={value}
                                    className={classes.root}
                                >
                                    <ListItem
                                        key={value}
                                        className={classes.listItem}
                                        button
                                    >
                                        <ListItemText
                                            id={index}
                                            primary={value}
                                        />
                                        <Button
                                            startIcon={<ClearIcon />}
                                            onClick={() => {
                                                const newServices =
                                                    selectedServices.filter(
                                                        (curr) =>
                                                            curr !==
                                                            selectedServices[
                                                                index
                                                            ]
                                                    );
                                                setSelectedServices(
                                                    newServices
                                                );
                                                setServicesPercentages(
                                                    (servicePercentage) => ({
                                                        ...servicePercentage,
                                                        [value]: 0,
                                                    })
                                                );
                                            }}
                                        ></Button>
                                    </ListItem>
                                </List>
                            </Column1>
                            <Column2 className={classes.column2}>
                                <Price price={statePrice.services[value]} />
                            </Column2>
                        </ContainerRow>
                    );
                })}
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.subtotal} variant="h4">
                            Subtotal do valor:
                        </Typography>
                    </Column1>
                    <Column2 className={classes.subtotalPrice}>
                        <Subtotal
                            subPrice={serviceSubPrice}
                            haMade={proposalData?.haMade}
                        />
                    </Column2>
                </ContainerRow>
            </Container>
        </>
    );
}
