import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '20rem',
        fontWeight: 'bolder',
        borderRadius: '15px',
        padding: '15px',
        marginTop: '2rem',
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.4)',
        color: '#fff',
        fontSize: '18px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
}));

export default function ActionButtons({
    label,
    color,
    startIcon,
    disabled,
    onClick,
}) {
    const classes = useStyles();

    return (
        <div>
            <Button
                variant="contained"
                color={color}
                className={classes.button}
                endIcon={startIcon}
                disabled={disabled ? true : false}
                onClick={() => {
                    onClick();
                }}
            >
                {label}
            </Button>
        </div>
    );
}
