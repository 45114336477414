import { Container } from './yes-no-button.styles';

/* Material UI */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        borderRadius: '25px',
        width: '6rem',
        textTransform: 'capitalize',
        '@media (max-width: 540px)': {
            width: '5rem',
        },
    },
}));

export default function YesNo({ check, setCheck }) {
    const classes = useStyles();
    return (
        <Container>
            <Button
                variant="outlined"
                className={classes.button}
                startIcon={
                    check === 1 ? (
                        <CheckCircleIcon color="primary" />
                    ) : (
                        <RadioButtonUncheckedIcon />
                    )
                }
                onClick={() => setCheck(1)}
            >
                Sim
            </Button>
            <Button
                variant="outlined"
                className={classes.button}
                startIcon={
                    check === 0 ? (
                        <CheckCircleIcon color="primary" />
                    ) : (
                        <RadioButtonUncheckedIcon />
                    )
                }
                onClick={() => setCheck(0)}
            >
                Não
            </Button>
        </Container>
    );
}
