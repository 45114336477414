import styled, { css } from 'styled-components';

const SignInButtonStyles = css`
    background-color: rgb(215, 59, 48);
    color: white;
    border: none;
    border-radius: 10px;
    width: 100%;

    &:hover {
        background-color: #bf0000;
        border: none;
    }
`;

const getButtonStyles = (props) => {
    if (props.signInButton) {
        return SignInButtonStyles;
    }
};

export const CustomButtonContainer = styled.button`
    min-width: 110px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 12px 0 12px;
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bolder;
    cursor: pointer;
    outline: none;
    margin: 10px 0;

    ${getButtonStyles}
`;
