import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { lightGreen, red } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Modal from '@material-ui/core/Modal';
import PopUp from '../popup/popup.component';
import Autocomplete from '@material-ui/lab/Autocomplete';

// styles

// Components
import { Container, Title } from './goals-data.styles';

// Context
import { authContext } from '../../contexts/AuthContext';

// API
import { updateGoal, findAllGoals, findGoalYears } from '../../api/ApiGoal';

const theme = createTheme({
    palette: {
        primary: { main: lightGreen[300] },
        secondary: { main: red[600] },
    },
});

const useStyles = makeStyles({
    table: {
        marginTop: '100px',
        maxHeight: '30%',
        border: '.2px solid #ededed',
        width: '80%',
        margin: '2rem auto',
        fontSize: 20,
        transform: 'scale(1.1)',
        editIcon: {
            cursor: 'pointer',
            maxWidth: '20px',
            marginLeft: '10px',
            transform: 'translate(0px, 4px)',
        },
    },
    container: {
        maxHeight: '675px',
    },
    editIcon: {
        cursor: 'pointer',
        maxWidth: '20px',
        marginLeft: '10px',
        transform: 'translate(0px, 4px)',
    },
    formControl: {
        width: '7rem',
        marginTop: '10px',
        marginRight: '85px',
        marginBottom: '10px',
        float: 'right',
    },
    textControl: {
        width: '45%',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    currencyField: {
        width: '30%',
        padding: 20,
        fontSize: 'smaller',
        margin: '5px',
    },
    titleEdit: {
        fontSize: '24px',
        fontWeight: 900,
        padding: '20px 10px 0px 10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    paper: {
        position: 'relative',
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: '2rem auto',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    buttonModal: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        right: 20,
        textTransform: 'inherit',
        float: 'center',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4rem',
        marginTop: '1rem',
        fontSize: 15,
        transform: 'translate(-1rem, -12px) scale(0.9)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
});

export default function GoalsData() {
    const { auth } = useContext(authContext);

    const [saGoalList, setSaGoalList] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);

    const [currentSaId, setCurrentSaId] = useState('');
    const [currentSaName, setCurrentSaName] = useState('');
    const [currentSaGoal, setCurrentSaGoal] = useState(null);
    const [currCommercialYear, setCurrCommercialYear] = useState('');
    const [goalYears, setGoalYears] = useState([]);
    const [goalYear, setGoalYear] = useState(
        new Date().getFullYear().toString()
    );

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);

    const classes = useStyles();

    const handleOpenEdit = (id, name, goal) => {
        setCurrentSaId(id);
        setCurrentSaName(name);
        setCurrentSaGoal(goal);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleEditGoal = async (saId, goal) => {
        try {
            const response = await updateGoal(auth, saId, goal);
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Meta editada com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Meta não pode ser editada: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    useEffect(() => {
        const callApiFindAllGoals = async () => {
            const response = await findAllGoals(auth, goalYear);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setSaGoalList(payload);
        };

        try {
            callApiFindAllGoals();
        } catch (err) {
            console.log(err);
        }
    }, [auth, goalYear]);

    useEffect(() => {
        const callApiFindGoalYears = async () => {
            const response = await findGoalYears(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setCurrCommercialYear(Object.values(payload[0]).toString());
            setGoalYears(payload.map((curr) => curr.year.toString()));
        };

        try {
            callApiFindGoalYears();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    const defaultGoalYears = {
        options: goalYears,
        value: goalYear,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setGoalYear(newValue);
        },
    };

    const editBody = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Editar meta
            </h2>

            <TextField
                id="new-goal"
                className={classes.textControl}
                label="Nome do RTV"
                style={{ margin: 8 }}
                placeholder="Informe aqui"
                margin="normal"
                type="text"
                value={currentSaName}
                disabled
            />

            <CurrencyTextField
                className={classes.currencyField}
                variant="standard"
                inputMode="numeric"
                currencySymbol="$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                value={currentSaGoal}
                onChange={(event, value) => setCurrentSaGoal(value)}
            />

            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={() => handleEditGoal(currentSaId, currentSaGoal)}
                >
                    Editar
                </Button>
            </ThemeProvider>
        </div>
    );

    return (
        <>
            {' '}
            <Container>
                <Title id="tableTitle" component="div">
                    {'Menu de Metas'}
                </Title>
                <Autocomplete
                    {...defaultGoalYears}
                    id="input-goal-year"
                    autoSelect
                    disableClearable
                    className={classes.formControl}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                />
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>RTV</TableCell>
                                <TableCell align="center">Metas</TableCell>
                                {goalYear === currCommercialYear ? (
                                    <TableCell align="right">Ações</TableCell>
                                ) : (
                                    <TableCell align="right">Vendas</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {saGoalList.map((sa) => (
                                <TableRow key={sa.saId}>
                                    <TableCell component="th" scope="row">
                                        {sa.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {sa.goal.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}
                                    </TableCell>
                                    {goalYear === currCommercialYear ? (
                                        <TableCell align="right">
                                            <EditIcon
                                                className={classes.editIcon}
                                                onClick={() =>
                                                    handleOpenEdit(
                                                        sa.saId,
                                                        sa.name,
                                                        sa.goal
                                                    )
                                                }
                                            />
                                        </TableCell>
                                    ) : (
                                        <TableCell align="right">
                                            {sa.value.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {popup ? (
                    <PopUp
                        title={popupTitle}
                        string={popupText}
                        success={success}
                    />
                ) : null}
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {editBody}
                </Modal>
            </Container>
        </>
    );
}
