import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { lightGreen, red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Modal from '@material-ui/core/Modal';

// styles
import { Row1Modal, Row2Modal } from './fertilizer-data.styles';

// Components
import { Container, Title } from './fertilizer-data.styles';
import PopUp from '../popup/popup.component';

// Context
import { authContext } from '../../contexts/AuthContext';

// APÍ
import {
    createFertilizer,
    deleteFertilizer,
    editFertilizer,
    findAllFertilizer,
} from '../../api/ApiFertilizer';
import { Autocomplete } from '@material-ui/lab';
import { findAllStates } from '../../api/ApiState';
import orderByObjectKey from '../../utils/orderByObjectKey';
import { set } from 'date-fns';

const theme = createTheme({
    palette: {
        primary: { main: lightGreen[300] },
        secondary: { main: red[600] },
    },
});

const useStyles = makeStyles({
    table: {
        border: '.2px solid #ededed',
        width: '80%',
        margin: '2rem auto',
        fontSize: 20,
        transform: 'scale(1.1)',
    },
    editIcon: {
        cursor: 'pointer',
        maxWidth: '20px',
        marginLeft: '10px',
        transform: 'translate(0px, 4px)',
    },
    button: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        right: 20,
        textTransform: 'inherit',
        float: 'left',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4.2rem',
        fontSize: 15,
        transform: 'translateY(12px)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '60%',
    },
    saStatesSelect: {
        width: '12rem',
        marginTop: '10px',
        marginRight: '3.7rem',
        marginBottom: '10px',
        float: 'right',
    },
    textControl: {
        width: '35%',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    currencyField: {
        width: '25%',
        padding: 20,
        fontSize: 'smaller',
        margin: '5px',
    },
    titleEdit: {
        fontSize: '24px',
        fontWeight: 900,
        padding: '20px 10px 0px 10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    paper: {
        position: 'relative',
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: '2rem auto',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    buttonModal: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        right: 20,
        textTransform: 'inherit',
        float: 'center',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4rem',
        marginTop: '1rem',
        fontSize: 15,
        transform: 'translate(-1rem, -12px) scale(0.9)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        maxHeight: 800,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        padding: '2rem 0',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    titleButton: {
        color: '#fff',
        fontWeight: '500',
    },
    buttonModalRemove: {
        fontFamily: 'Poppins, sans-serif',
        width: '10rem',
        margin: '12px auto',
        fontWeight: 'bolder',
        background: '#e0645e',
        borderRadius: '15px',
        height: '3.5rem',
        color: '#fff !important',
        padding: '12px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
    buttonModalRemoveNot: {
        fontFamily: 'Poppins, sans-serif',
        width: '10rem',
        margin: '12px auto',
        fontWeight: 'bolder',
        background: '#c4c2c2',
        borderRadius: '15px',
        height: '3.5rem',
        color: '#fff !important',
        padding: '12px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
});

export default function FertilizerData() {
    const { auth } = useContext(authContext);

    const [fertilizers, setFertilizers] = useState([]);
    const [open, setOpen] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [currentFertilizerName, setCurrentFertilizerName] = useState('');
    const [currentFertilizerValue, setCurrentFertilizerValue] = useState(0);

    const [fertilizerName, setFertilizerName] = useState('');
    const [fertilizerValue, setFertilizerValue] = useState(0);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [saStateList, setSaStatesList] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStates(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setSaStatesList(orderedStates);
            setSelectedState(orderedStates[0]);
        };
        
        try {
            callApiFindAllStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callApiFindAllFertilizer = async () => {
            if(selectedState === null) return;
            const response = await findAllFertilizer(
                auth,
                selectedState.stateId
            );

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const data = [];
            Object.entries(payload[0].fertilizer).map((curr) =>
                data.push({ name: curr[0], value: curr[1] })
            );
            setFertilizers(data);
        };

        try {
            callApiFindAllFertilizer();
        } catch (err) {
            console.log(err);
        }
    }, [selectedState]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenRemove = (name) => {
        setOpenRemove(true);
        setCurrentFertilizerName(name);
    };

    const handleCloseRemove = () => {
        setOpenRemove(false);
    };

    const handleOpenEdit = (name, value) => {
        setOpenEdit(true);
        setCurrentFertilizerName(name);
        setCurrentFertilizerValue(value);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleNewFertilizer = async () => {
        try {
            const response = await createFertilizer(
                auth,
                selectedState.stateId,
                fertilizerName.trim(),
                fertilizerValue
            );
            if (response.status !== 201) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Adubo criado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Adubo não pode ser criado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    const handleEditFertilizer = async () => {
        try {
            const response = await editFertilizer(
                auth,
                selectedState.stateId,
                currentFertilizerName,
                currentFertilizerValue
            );
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Adubo editado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Adubo não pode ser editado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    const handleRemoveFertilizer = async () => {
        try {
            const response = await deleteFertilizer(
                auth,
                selectedState.stateId,
                currentFertilizerName
            );
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Adubo deletado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Adubo não pode ser deletado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    const defaultSaStates = {
        options: saStateList,
        value: selectedState,
        getOptionLabel: (option) => {
            return option && option.uf;
        },
        getOptionSelected: (option) => {
            return option && option.uf;
        },
        onChange: (event, newValue) => {
            setSelectedState(newValue);
        },
    };

    const body = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Adicionar novo adubo
            </h2>

            <TextField
                id="new-fertilizer"
                className={classes.textControl}
                label="Nome do adubo"
                style={{ margin: 8 }}
                placeholder="Informe aqui"
                margin="normal"
                type="text"
                value={fertilizerName}
                onChange={(event) => setFertilizerName(event.target.value)}
            />

            <CurrencyTextField
                className={classes.currencyField}
                variant="standard"
                inputMode="numeric"
                currencySymbol="$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                value={fertilizerValue}
                onChange={(e, value) => setFertilizerValue(value)}
            />

            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleNewFertilizer}
                >
                    Adicionar
                    {<AddIcon />}
                </Button>
            </ThemeProvider>
        </div>
    );

    const deleteBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Remover este adubo?
            </h2>
            <Row1Modal>
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="button"
                        className={classes.buttonModalRemove}
                        onClick={handleRemoveFertilizer}
                    >
                        <span className={classes.titleButton}>Remover</span>
                    </Button>
                </ThemeProvider>
            </Row1Modal>
            <Row2Modal>
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        type="button"
                        className={classes.buttonModalRemoveNot}
                        onClick={handleCloseRemove}
                    >
                        <span className={classes.titleButton}>Cancelar</span>
                    </Button>
                </ThemeProvider>
            </Row2Modal>
        </div>
    );

    const editBody = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Editar adubo
            </h2>

            <TextField
                id="new-fertilizer"
                className={classes.textControl}
                label="Nome do adubo"
                style={{ margin: 8 }}
                placeholder="Informe aqui"
                margin="normal"
                type="text"
                value={currentFertilizerName}
                disabled
            />

            <CurrencyTextField
                className={classes.currencyField}
                variant="standard"
                inputMode="numeric"
                currencySymbol="$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                value={currentFertilizerValue}
                onChange={(event, value) => setCurrentFertilizerValue(value)}
            />

            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleEditFertilizer}
                >
                    Editar
                </Button>
            </ThemeProvider>
        </div>
    );

    return (
        <>
            {' '}
            <Container>
                <Title id="tableTitle" component="div">
                    {'Menu de Fertilizantes'}
                </Title>
                <Autocomplete
                    {...defaultSaStates}
                    id="input-goal-year"
                    autoSelect
                    disableClearable
                    className={classes.saStatesSelect}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                />

                <TableContainer>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Adubo</TableCell>
                                <TableCell align="center">Valor</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fertilizers.map((fertilizer) => (
                                <TableRow key={fertilizer.name}>
                                    <TableCell component="th" scope="row">
                                        {fertilizer.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {fertilizer.value.toLocaleString(
                                            'pt-br',
                                            {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <EditIcon
                                            className={classes.editIcon}
                                            onClick={() =>
                                                handleOpenEdit(
                                                    fertilizer.name,
                                                    fertilizer.value
                                                )
                                            }
                                        />
                                        <DeleteForeverIcon
                                            onClick={() =>
                                                handleOpenRemove(
                                                    fertilizer.name
                                                )
                                            }
                                            className={classes.editIcon}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                </TableContainer>
                {popup ? (
                    <PopUp
                        title={popupTitle}
                        string={popupText}
                        success={success}
                    />
                ) : null}
                <Modal
                    open={openRemove}
                    onClose={handleCloseRemove}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {deleteBody}
                </Modal>
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {editBody}
                </Modal>

                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color={'secondary'}
                        className={classes.button}
                        onClick={handleOpen}
                    >
                        {<AddIcon> </AddIcon>} Novo Adubo
                    </Button>
                </ThemeProvider>
            </Container>
        </>
    );
}
