import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { lightGreen, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import PopUp from '../popup/popup.component';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

// Context
import { authContext } from '../../contexts/AuthContext';

//Styles
import { Container, Title } from './director-data.styles';

// API
import {
    createDirector,
    findAllSaFromDirector,
    findAllDirectors,
} from '../../api/ApiDirector';
import { findAllSas } from '../../api/ApiSa';
import orderByObjectKey from '../../utils/orderByObjectKey';

const theme = createTheme({
    palette: {
        primary: { main: lightGreen[300] },
        secondary: { main: red[600] },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        display: 'flex',
        width: '12rem',
        background: '#e0645e',
        height: '55px',
        textTransform: 'inherit',
        fontFamily: 'Roboto',
        fontWeight: 700,
        margin: '1rem 2.5rem',
        fontSize: 15,
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    buttonItem: {
        fontFamily: 'Poppins, sans-serif',
        width: '5rem',
        margin: '12px',
        fontWeight: 'bolder',
        background: '#e0645e',
        borderRadius: '15px',
        height: '3.5rem',
        color: '#fff !important',
        padding: '12px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
    paperModal: {
        overflowY: 'scroll',
        marginTop: '6%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        maxHeight: 600,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        padding: '2rem 0',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    titleEdit: {
        fontSize: '24px',
        fontWeight: 900,
        padding: '20px 10px 0px 10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    buttonModal: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        right: 20,
        textTransform: 'inherit',
        float: 'center',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4rem',
        marginTop: '2rem',
        fontSize: 15,
        transform: 'translate(-1rem, -12px) scale(0.9)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    formControl: {
        margin: '0 auto',
        width: '80%',
        marginTop: '1rem',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    name: {
        fontSize: '16px',
        fontWeight: '400',
        padding: '20px 10px 0px 50px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
}));

export default function DirectorData() {
    const { auth } = useContext(authContext);
    const classes = useStyles();

    const [directorList, setDirectorList] = useState([]);
    const [saList, setSaList] = useState([]);

    // estados para salvar os AS's selecionados no modal de criar diretor
    const [selectedDirector, setSelectedDirector] = React.useState(null);
    const [selectedSa, setSelectedSa] = React.useState(null);
    const [saFromDirector, setSaFromDirector] = React.useState(null);
    const [currentDirectorName, setCurrentDirectorName] = React.useState('');
    const [currentDirectorId, setCurrentDirectorId] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenAdd = (name, id) => {
        setCurrentDirectorName(name);
        setCurrentDirectorId(id);
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setCurrentDirectorName('');
        setCurrentDirectorId(null);
        setOpenAdd(false);
    };

    const defaultPropsDirector = {
        options: [...directorList, ...saList],
        value: currentDirectorId
            ? { saId: currentDirectorId, name: currentDirectorName }
            : selectedDirector,
        getOptionLabel: (option) => {
            return option.saId
                ? option.saId && `${option.saId} - ${option.name}`
                : '';
        },
        getOptionSelected: (option) => {
            return option && `${option.saId} - ${option.name} `;
        },
        onChange: (event, newValue) => {
            setSelectedDirector(newValue);
        },
    };

    const defaultPropsSa = {
        options: saList,
        value: selectedSa,
        getOptionLabel: (option) => {
            return option.saId
                ? option.saId && `${option.saId} - ${option.name}`
                : '';
        },
        getOptionSelected: (option) => {
            return option && `${option.saId} - ${option.name} `;
        },
        onChange: (event, newValue) => {
            setSelectedSa(newValue);
        },
    };

    const handleNewDirector = async () => {
        try {
            const response = await createDirector(
                auth,
                selectedSa.saId,
                currentDirectorId ?? selectedDirector.saId
            );
            if (response.status !== 201) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Diretor criado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Diretor não pode ser criado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    // TODO:
    // Buscar todos os diretores e fazer listagem na tela
    // Impedir que exista uma relação entre 2 AS's, onde um é diretor do outro
    useEffect(() => {
        const callApiFindAllSas = async () => {
            const response = await findAllSas(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedDirectors = orderByObjectKey(payload, 'name');

            setSaList(
                orderedDirectors.filter((curr) =>
                    directorList.every((currDir) => currDir.saId !== curr.saId)
                )
            );
        };

        try {
            callApiFindAllSas();
        } catch (err) {
            console.log(err);
        }
    }, [auth, directorList]);

    useEffect(() => {
        const callApiFindAllDirectors = async () => {
            const response = await findAllDirectors(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedDirectors = orderByObjectKey(payload, 'name');

            setDirectorList(orderedDirectors);
        };

        try {
            callApiFindAllDirectors();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callApiFindAllSaFromDirector = async () => {
            const response = await findAllSaFromDirector(
                auth,
                currentDirectorId
            );

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedSa = orderByObjectKey(payload, 'name');

            setSaFromDirector(orderedSa);
        };
        try {
            if (currentDirectorId) callApiFindAllSaFromDirector();
        } catch (err) {
            console.log(err);
        }
    }, [auth, currentDirectorId]);

    const body = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Adicionar ao diretor
            </h2>

            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    {...defaultPropsDirector}
                    id="input-Director"
                    disabled={currentDirectorId ? true : false}
                    autoSelect
                    className={classes.formControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nome do Diretor"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    {...defaultPropsSa}
                    id="input-AS"
                    autoSelect
                    className={classes.formControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nome do RTV"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>

            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleNewDirector}
                >
                    Adicionar
                </Button>
            </ThemeProvider>
        </div>
    );

    const listBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                {currentDirectorName}
            </h2>
            <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {saFromDirector?.map((sa) => (
                        <TableRow key={sa.name}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.name}
                            >
                                {sa.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={() => handleOpen(currentDirectorName)}
                >
                    {<AddIcon />} Colaborador
                </Button>
            </ThemeProvider>
        </div>
    );

    return (
        <Container>
            <Title id="tableTitle" component="div">
                {'Menu de Diretor'}
            </Title>
            <TableContainer>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {directorList.map((director) => (
                            <TableRow key={director.name}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.name}
                                >
                                    {director.name}
                                </TableCell>
                                <TableCell align="right">
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            variant="contained"
                                            color={'secondary'}
                                            className={classes.buttonItem}
                                            onClick={() =>
                                                handleOpenAdd(
                                                    director.name,
                                                    director.saId
                                                )
                                            }
                                        >
                                            {<ListAltIcon />}
                                        </Button>
                                    </ThemeProvider>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color={'secondary'}
                        onClick={handleOpen}
                        className={classes.button}
                    >
                        {<AddIcon />} Nova Relação
                    </Button>
                </ThemeProvider>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Modal
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {listBody}
                </Modal>
            </TableContainer>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                />
            ) : null}
        </Container>
    );
}
