import { useContext } from 'react';

//Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

import { statePriceContext } from '../../contexts/StatePriceContext';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '20rem',
        borderRadius: '10px',
        margin: '0 auto',
        zIndex: 1,
        marginBottom: '80px',
        marginTop: '2.2rem',
        '@media (max-width: 350px)': {
            transform: 'scale(0.8)',
        },
    },
    titleSize: {
        fontSize: '18px',
        fontWeight: 'bold',
        border: '1px solid #e0e0e0',
    },
    letterSize: {
        fontSize: '18px',
        border: '1px solid #e0e0e0',
    },
}));

export default function TablePointsProgram({ baseValue }) {
    const { statePrice } = useContext(statePriceContext);

    const pointsCalculate = (gridValue) => {
        let points = 0;

        while (true) {
            if (points * baseValue >= gridValue || points > gridValue * 10000) {
                break;
            }
            points++;
        }

        return points;
    };

    const classes = useStyles();
    return (
        <>
            <TableContainer className={classes.table} component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={classes.titleSize}
                                align="center"
                            >
                                Grid: Valor
                            </TableCell>
                            <TableCell
                                className={classes.titleSize}
                                align="center"
                            >
                                Pontos
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statePrice &&
                            Object.entries(statePrice.grid).map((row) => (
                                <TableRow
                                    key={row[0]}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        className={classes.letterSize}
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        {`${row[0]}: ${row[1]}`}
                                    </TableCell>
                                    <TableCell
                                        className={classes.letterSize}
                                        align="center"
                                    >
                                        {pointsCalculate(Number(row[1]))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow
                            key={'base'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="center"
                                component="th"
                                scope="row"
                            >
                                {'base'}
                            </TableCell>
                            <TableCell
                                className={classes.letterSize}
                                align="center"
                            >
                                {baseValue}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
