import { apiUrl } from './Api';
import Axios from 'axios';

export async function findAllPointsProgram(auth) {
    try {
        return await Axios.get(`${apiUrl}/pointsPrograms`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}
