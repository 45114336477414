const getPermission = (permissions, permissionName) => {
    const { payload } = permissions.data;

    for (let module of payload[0].permissions.modules) {
        if (module.moduleName === permissionName) return true;
        for (let submodule of module.submodules) {
            if (submodule.submoduleName === permissionName) return true;
        }
    }
    return false;
};

export default getPermission;
