import { useEffect } from 'react';

import { DrawerAdjustment } from './director.styles';
import DirectorData from '../../components/director-data/director-data.component';
/* Components */
import Header from '../../components/header/header.component';

export default function Director() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <DirectorData />
        </DrawerAdjustment>
    );
}
