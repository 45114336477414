import { useEffect } from 'react';

import { DrawerAdjustment } from './states.styles';

/* Components */
import Header from '../../components/header/header.component';
import StatesData from '../../components/states-data/states-data.component';

export default function Goals() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <StatesData />
        </DrawerAdjustment>
    );
}
