/* Styles */
import {
    Container,
    ContainerRow,
    Column1,
    Column2,
    Title,
    Info,
    Chart,
    InsideColumn2,
    FilterRow,
    FilterHeader0,
    FilterHeader1,
    FilterHeader2,
    FilterColumn1,
    FilterColumn2,
    FilterColumn0,
    InfoText,
} from './dashboard-data.styles';

import { useState, useEffect, useContext } from 'react';
import EditProposalTable from '../edit-proposal-table/edit-proposal-table.component';

/* API */
import { findAllProposalsValues } from '../../api/ApiProposal';
import { findProposalsFromYear } from '../../api/ApiProposal';
import { findProposalValuesFromMetas } from '../../api/ApiProposal';
import { findProposalsByStatus } from '../../api/ApiState';
import { findAllProposalsStatus } from '../../api/ApiProposal';
import { findAllStates } from '../../api/ApiState';
import { proposalValuesFromState } from '../../api/ApiState';
import { findAllSaFromState, findSaState, findAllSas } from '../../api/ApiSa';
import { findSaPermission } from '../../api/ApiPermission';
import { findGoalYears } from '../../api/ApiGoal';

// Utils
import orderByObjectKey from '../../utils/orderByObjectKey';
import getPermission from '../../utils/getPermission';

/* Context */
import { authContext } from '../../contexts/AuthContext';

/* Material UI */
import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/* Charts Library */
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const COLORS = ['#6ebc96', '#f44336c9', '#ffe699'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    styleTitle: {
        textAlign: 'start',
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    progress: {
        height: 100,
        borderRadius: '10px',
        margin: '20px 10px',
    },
    progressText: {
        textAlign: 'left',
        fontSize: '22px',
        fontWeight: 300,
        paddingLeft: 20,
        marginTop: -20,
        fontFamily: 'Roboto',
        color: '#444446',
    },
    progressTextRight: {
        textAlign: 'left',
        fontSize: '22px',
        fontWeight: 300,
        fontFamily: 'Roboto',
        color: '#444446',
        paddingLeft: 20,
        marginTop: 9,
    },
    h3: {
        display: 'inline-block',
        marginBottom: -10,
        marginTop: 10,
        paddingTop: 5,
        fontWeight: 500,
        color: '#444446',
        fontFamily: 'Roboto',
        letterSpacing: 'normal',
    },
    filtersField: {
        width: 'auto',
    },
}));

export default function DashboardData() {
    const { auth } = useContext(authContext);
    const [progress, setProgress] = useState(0);
    const [dataChart, setDataChart] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [acceptedProposal, setAcceptedProposal] = useState(0);
    const [selectedSa, setSelectedSa] = useState(null);
    const [saList, setSaList] = useState([]);
    const [selectedState, setSelectedState] = useState(
        auth.data.user.permissionId === 1
            ? { stateId: 1, uf: 'TODOS' }
            : auth.data.user.permissionId === 2
            ? { stateId: auth.data.user.stateId, uf: auth.data.user.uf }
            : null
    );

    const [meta, setMeta] = useState(0);

    // estados que guardam o valor selecionado de cada campo
    const [year, setYear] = useState(new Date().getFullYear().toString());
    // estados que guardam as opções do input vindas do banco
    const [years, setYears] = useState([]);

    //Estados para permissões nos filtros
    const [showStateFilter, setShowStateFilter] = useState(false);
    const [showSaFilter, setShowSaFilter] = useState(false);
    const [showAllProposals, setShowAllProposals] = useState(false);

    const metaProgressPercent = acceptedProposal
        ? Math.floor((acceptedProposal / meta) * 100) >= 100
            ? 100
            : Math.floor((acceptedProposal / meta) * 100)
        : 0;

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= metaProgressPercent
                    ? metaProgressPercent
                    : prevProgress + 1
            );
        }, 25);
        return () => {
            clearInterval(timer);
        };
    }, [metaProgressPercent]);

    useEffect(() => {
        const callFindGoalYears = async () => {
            const response = await findGoalYears(auth);
            let { message, payload } = response.data;
            if (response.status !== 200) throw Error(message);

            payload = payload.map((curr) => String(curr.year));

            setYears(payload);
        };

        try {
            callFindGoalYears();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedSa, year]);

    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStates(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);

            if (showAllProposals) {
                setStateList([{ stateId: 1, uf: 'TODOS' }, ...orderedStates]);
            }

            // permissão de gerente
            if (showSaFilter && !showStateFilter) {
                orderedStates.forEach((state) => {
                    if (state.stateId === auth.data.user.stateId)
                        setSelectedState(state);
                });
            }
        };

        const callApiFindSaState = async () => {
            const response = await findSaState(auth, auth.data.user.saId);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setStateList(payload);
        };

        try {
            if (auth.data.user.permissionId !== 1) callApiFindSaState();
            else callApiFindAllStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth, showSaFilter, showStateFilter, showAllProposals]);

    useEffect(() => {
        const callApiFindAllSaFromState = async () => {
            if (selectedState) {
                let response;
                if (selectedState.uf === 'TODOS') {
                    response = await findAllSas(auth);
                } else {
                    response = await findAllSaFromState(
                        auth,
                        selectedState.stateId
                    );
                }
                const { message, payload } = response.data;

                if (response.status !== 200) throw Error(message);
                setSaList(payload);
            }
        };

        try {
            callApiFindAllSaFromState();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedState]);

    useEffect(() => {
        const callCheckPermission = async () => {
            const response = await findSaPermission(
                auth,
                selectedState !== null && auth.data.user.permissionId !== 1
                    ? selectedState.stateId
                    : auth.data.user.stateId,
                auth.data.user.saId
            );

            setShowStateFilter(
                getPermission(response, 'Filtro do Dashboard de Estado')
            );
            setShowSaFilter(
                getPermission(response, 'Filtro do Dashboard de AS')
            );
            setShowAllProposals(
                getPermission(response, 'Últimas Propostas Gerais')
            );
        };
        try {
            callCheckPermission();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, selectedState]);

    useEffect(() => {
        const callApiFindProposalsFromYear = async () => {
            let response;
            let AsPermissionId = 3;

            if (selectedState?.stateId && !selectedSa?.saId && auth.data.user.permissionId !== AsPermissionId) {
                if (selectedState.uf === 'TODOS') {
                    response = await findAllProposalsStatus(auth, year);
                } else {
                    response = await findProposalsByStatus(
                        auth,
                        selectedState.stateId,
                        year
                    );
                }
            } else {
                response = await findProposalsFromYear(
                    auth,
                    selectedSa?.saId || auth.data.user.saId,
                    selectedState?.stateId === 1 || selectedState === null ? null : selectedState.stateId,
                    year,
                );
            }

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            let openStatus = {
                status: 'Em Aberto',
                countedProposals: 0,
                sumProposal: 0,
            };
            const data = payload.filter((current, index, arr) => {
                if (
                    current.status === 'Em Andamento' ||
                    current.status === 'Enviada'
                ) {
                    openStatus.countedProposals += current.countedProposals;
                    openStatus.sumProposal += current.sumProposal;
                    return false;
                }
                return true;
            });
            data.push(openStatus);
            setDataChart(data);
        };

        try {
            callApiFindProposalsFromYear();
        } catch (err) {
            console.log(err);
        }
    }, [auth, year, selectedState, selectedSa]);

    useEffect(() => {
        const callApiFindProposalValuesFromMetas = async () => {
            const response = await findProposalValuesFromMetas(
                auth,
                selectedSa?.saId || auth.data.user.saId,
                year
            );
            const { message, payload } = response.data;
            if (response.status !== 200) throw Error(message);

            setAcceptedProposal(payload[0]?.totalValue);
            setMeta(selectedSa?.goal || auth.data.user.goal);
        };

        const callApiProposalValuesFromState = async () => {
            let response;
            if (selectedState) {
                if (selectedState.uf === 'TODOS') {
                    response = await findAllProposalsValues(auth, year);
                } else {
                    response = await proposalValuesFromState(
                        auth,
                        selectedState.stateId,
                        year
                    );
                }
            }
            const { message, payload } = response.data;
            if (response.status !== 200) throw Error(message);

            setAcceptedProposal(Number(payload[0]?.sumProposal));
            setMeta(Number(payload[0]?.sumGoal));
        };

        try {
            if (
                selectedState?.stateId &&
                !selectedSa?.saId &&
                showSaFilter &&
                auth.data.user.permissionId !== 3
            )
                callApiProposalValuesFromState();
            else callApiFindProposalValuesFromMetas();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedSa, selectedState, year, showSaFilter]);

    const defaultPropsYear = {
        options: years,
        value: year,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setYear(newValue);
        },
    };

    const classes = useStyles();

    const defaultPropsState = {
        options: stateList,
        value: selectedState,
        getOptionLabel: (option) => {
            return option && option.uf;
        },
        getOptionSelected: (option) => {
            return option && option.uf;
        },
        onChange: (event, newValue) => {
            setSelectedState(newValue);
        },
    };

    const defaultPropsSa = {
        options: saList,
        value: selectedSa,
        getOptionLabel: (option) => {
            return option && option.name;
        },
        getOptionSelected: (option) => {
            return option && option.name;
        },
        onChange: (event, newValue) => {
            setSelectedSa(newValue);
        },
    };

    return (
        <>
            <Container>
                <Title className={classes.styleTitle}>Dashboard</Title>
                <FilterRow>
                    <FilterHeader0>Filtrar metas por Ano Comercial</FilterHeader0>
                    <FilterColumn0>
                        <Autocomplete
                            {...defaultPropsYear}
                            id="year-selector"
                            style={{ margin: 'auto', width: 'auto' }}
                            autoSelect
                            disableClearable
                            className={classes.option}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </FilterColumn0>

                    {showStateFilter && (
                        <>
                            <FilterHeader1>
                                Filtrar metas por Estado
                            </FilterHeader1>
                            <FilterColumn1>
                                <Autocomplete
                                    {...defaultPropsState}
                                    id="state-selector"
                                    style={{ margin: 'auto', width: 'auto' }}
                                    autoSelect
                                    className={classes.filtersField}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FilterColumn1>
                        </>
                    )}

                    {showSaFilter && (
                        <>
                            <FilterHeader2>Filtrar metas por RTV</FilterHeader2>
                            <FilterColumn2>
                                <Autocomplete
                                    {...defaultPropsSa}
                                    id="sa-selector"
                                    autoSelect
                                    disabled={selectedState?.uf ? false : true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FilterColumn2>
                        </>
                    )}
                </FilterRow>
                <ContainerRow>
                    <Column1>
                        <div>
                            <LinearProgressWithLabel
                                className={classes.progress}
                                value={progress}
                            />
                        </div>
                        <div className={classes.progressText}>
                            <h3 className={classes.h3}>
                                {' '}
                                {(acceptedProposal || 0).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }
                                )}
                            </h3>{' '}
                            {selectedState?.stateId > 1 && !selectedSa?.saId
                                ? showSaFilter &&
                                  auth.data.user.permissionId !== 3
                                    ? 'total em vendas no estado.'
                                    : 'total em vendas.'
                                : selectedState?.stateId > 1 &&
                                  showSaFilter &&
                                  auth.data.user.permissionId !== 3
                                ? 'total em vendas do RTV.'
                                : 'total em vendas.'}
                            <br />
                            <h3 className={classes.h3}>
                                {(meta || 0).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </h3>{' '}
                            {selectedState?.stateId && !selectedSa?.saId
                                ? showSaFilter &&
                                  selectedState?.stateId !== 1 &&
                                  auth.data.user.permissionId !== 3
                                    ? 'é a meta do estado.'
                                    : selectedState?.stateId > 1
                                    ? 'é sua meta.'
                                    : 'é a meta total.'
                                : selectedState?.stateId &&
                                  showSaFilter &&
                                  auth.data.user.permissionId !== 3
                                ? 'é a meta do RTV.'
                                : 'é sua meta.'}
                        </div>
                    </Column1>
                    <Column2>
                        <InsideColumn2>
                            <Info>
                                {dataChart.length >= 1 && (
                                    <div className={classes.progressTextRight}>
                                        <h3 className={classes.h3}>
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Aceita'
                                            )
                                                ? dataChart.find(
                                                      (curr) =>
                                                          curr.status ===
                                                          'Aceita'
                                                  ).countedProposals
                                                : 0}
                                        </h3>{' '}
                                        <InfoText>
                                            {' '}
                                            propostas aceitas.{' '}
                                        </InfoText>
                                        <br />
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                                color: '#6ebc96',
                                            }}
                                        >
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Aceita'
                                            )
                                                ? dataChart
                                                      .find(
                                                          (curr) =>
                                                              curr.status ===
                                                              'Aceita'
                                                      )
                                                      .sumProposal.toLocaleString(
                                                          'pt-br',
                                                          {
                                                              style: 'currency',
                                                              currency: 'BRL',
                                                          }
                                                      )
                                                : 0}
                                        </span>
                                        <br />
                                        <h3 className={classes.h3}>
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Recusada'
                                            )
                                                ? dataChart.find(
                                                      (curr) =>
                                                          curr.status ===
                                                          'Recusada'
                                                  ).countedProposals
                                                : 0}{' '}
                                        </h3>{' '}
                                        <InfoText>
                                            {' '}
                                            propostas recusadas.
                                        </InfoText>
                                        <br />
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                                color: '#f44336c9',
                                            }}
                                        >
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Recusada'
                                            )
                                                ? dataChart
                                                      .find(
                                                          (curr) =>
                                                              curr.status ===
                                                              'Recusada'
                                                      )
                                                      .sumProposal.toLocaleString(
                                                          'pt-br',
                                                          {
                                                              style: 'currency',
                                                              currency: 'BRL',
                                                          }
                                                      )
                                                : 0}
                                        </span>
                                        <br />
                                        <h3 className={classes.h3}>
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Em Aberto'
                                            )
                                                ? dataChart.find(
                                                      (curr) =>
                                                          curr.status ===
                                                          'Em Aberto'
                                                  ).countedProposals
                                                : 0}{' '}
                                        </h3>{' '}
                                        <InfoText>
                                            {' '}
                                            propostas em aberto.
                                        </InfoText>
                                        <br />
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                                color: '#ffe699',
                                            }}
                                        >
                                            {dataChart.find(
                                                (curr) =>
                                                    curr.status === 'Em Aberto'
                                            )
                                                ? dataChart
                                                      .find(
                                                          (curr) =>
                                                              curr.status ===
                                                              'Em Aberto'
                                                      )
                                                      .sumProposal.toLocaleString(
                                                          'pt-br',
                                                          {
                                                              style: 'currency',
                                                              currency: 'BRL',
                                                          }
                                                      )
                                                : 0}
                                        </span>
                                        <br />
                                        <h3 className={classes.h3}>
                                            {dataChart.reduce(
                                                (acc, curr) =>
                                                    acc + curr.countedProposals,
                                                0
                                            )}{' '}
                                        </h3>{' '}
                                        <InfoText style={{ fontSize: '18px' }}>
                                            propostas criadas neste ano. <br />
                                        </InfoText>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                                color: '#4f4f4f',
                                            }}
                                        >
                                            {dataChart
                                                .reduce(
                                                    (acc, curr) =>
                                                        acc + curr.sumProposal,
                                                    0
                                                )
                                                .toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}
                                        </span>
                                    </div>
                                )}
                            </Info>

                            <Chart>
                                <PieChart width={180} height={180}>
                                    <Pie
                                        data={dataChart}
                                        cx={80}
                                        cy={80}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        nameKey="status"
                                        dataKey="countedProposals"
                                    >
                                        {dataChart.map((curr, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    curr.status === 'Aceita'
                                                        ? COLORS[0]
                                                        : curr.status ===
                                                          'Recusada'
                                                        ? COLORS[1]
                                                        : COLORS[2]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </Chart>
                        </InsideColumn2>
                    </Column2>
                </ContainerRow>
            </Container>
            <EditProposalTable staticRows={5} isDashboard={true} />
        </>
    );
}
