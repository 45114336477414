import { Container, ParcelTitle, DateDiv, ButtonX } from './parcel.styles';

/* Date */
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

/* Material UI */
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { makeStyles } from '@material-ui/core';

import { isValidDate } from '../../utils/isValidDate';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    currencyField: {
        width: '25%',
        padding: 20,
        fontSize: 'smaller',
        margin: '10px',
    },
    datePicker: {},
}));

export default function Parcel({
    parcel,
    parcels,
    setParcels,
    inCash = false,
    disabled,
}) {
    const classes = useStyles();
    const [isDateValid, setIsDateValid] = useState(true);
    const [invalidDateMessage, setInvalidDateMessage] = useState('');
    

    const invalidParcels = (date, othersParcels) => {
        return othersParcels.some((otherParcel) => {
            if (otherParcel.dueDate !== null) {
                const formattedDate = new Date(otherParcel.dueDate);
                date.setHours(0, 0, 0, 0);
    
                if (otherParcel.id < parcel.id && formattedDate.getTime() > date.getTime()) {
                    setInvalidDateMessage(`Data inferior a ${otherParcel.id}º parcela`);
                    return true;
                }
    
                if (otherParcel.id > parcel.id && formattedDate.getTime() < date.getTime()) {
                    setInvalidDateMessage(`Data superior a ${otherParcel.id}º parcela`);
                    return true;
                }
    
                if (formattedDate.getTime() === date.getTime()) {
                    setInvalidDateMessage(`Data igual a ${otherParcel.id}º parcela`);
                    return true;
                }
            }
            return false;
        });
    }

    const validDate = (date) => {
        return date && isValidDate(date)? true: false;
    }

    const validDateInCash = (date) => {
        if (validDate(date)) {
            setParcels((prevState) => {
                return {
                    ...prevState,
                    dueDate: format(date, 'MM/dd/yyyy'),
                };
            });
            setIsDateValid(true);
        } else {
            setIsDateValid(false);
            setInvalidDateMessage('Data Inválida');
        }
    }

    const validDateInParcels = (date) => {
        if(!validDate(date)) {
            setIsDateValid(false);
            setInvalidDateMessage('Data Inválida');
            return;
        }

        const newParcels = parcels.filter(
            (curr) => curr.id !== parcel.id
        );

        if(invalidParcels(date, newParcels)){ 
            return;
        };

        setParcels(() => {    
            newParcels.push({
                id: parcel.id,
                value: parcel.value,
                dueDate: format(date, 'MM/dd/yyyy'),
                additionalValue:
                    parcel.additionalValue,
            });
            return newParcels; 
        });

        setIsDateValid(true);  
    }

    return (
        <Container>
            <ParcelTitle>
                {inCash ? 'À vista:' : `${parcel.id}ª Parcela:`}{' '}
            </ParcelTitle>
            <CurrencyTextField
                className={classes.currencyField}
                variant="standard"
                style={{ width: '100%', padding: 22 }}
                value={parcel.value < 0 ? 0 : parcel.value}
                inputMode="numeric"
                currencySymbol="$"
                minimumValue="0"
                outputFormat="number"
                disabled={disabled}
                decimalCharacter=","
                digitGroupSeparator="."
                onBlur={(event, value) => {
                    setParcels((prevState) => {
                        if (inCash) {
                            return {
                                ...prevState,
                                value: value,
                            };
                        } else {
                            const newParcels = parcels.filter(
                                (curr) => curr.id !== parcel.id
                            );
                            newParcels.push({ ...parcel, value });
                            return newParcels;
                        }
                    });
                }}
            />
            {/* <Percent>{parcel.percent}%</Percent> */}
            <DateDiv>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        style={{ width: '100%', padding: 5 }}
                        className={classes.datePicker}
                        margin="normal"
                        id={`date-${parcel.id}`}
                        label="  Vencimento"
                        value={parcel.dueDate}
                        disabled={disabled}
                        onChange={inCash ? validDateInCash : validDateInParcels}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        error={!isDateValid}
                        helperText={
                            !isDateValid
                                ? invalidDateMessage
                                : ''
                        }
                    />
                </MuiPickersUtilsProvider>
            </DateDiv>
            <ButtonX>
                {!inCash && (
                    <Button
                        disabled={parcels.length <= 1}
                        onClick={() => {
                            setParcels(() => {
                                let newParcels = parcels
                                    .filter((curr) => curr.id !== parcel.id)
                                    .map((curr) => {
                                        if (curr.id > parcel.id) {
                                            return {
                                                ...curr,
                                                id: curr.id - 1,
                                            };
                                        } else {
                                            return curr;
                                        }
                                    });

                                return newParcels;
                            });
                        }}
                    >
                        {<ClearIcon />}
                    </Button>
                )}
            </ButtonX>
        </Container>
    );
}
