import { useContext, useState, useEffect } from 'react';

/* Material UI */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/* Context */
import { clientContext } from '../../contexts/ClientContext';
import { authContext } from '../../contexts/AuthContext';

import TableSummaryValue from '../table-summary-value/table-summary-value.component';

// API
import { findAllFertilizer } from '../../api/ApiFertilizer';

const specNames = [
    'Desconto de área total',
    'Laboratório',
    'Ciclo',
    'Monitoramento',
    'Acompanhamento',
    'Mapa de Colheita',
    'Sazonalidade',
];

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        width: '50rem',
        borderRadius: '15px',
        margin: '0 auto',
        justifySelf: 'center',
        transform: 'scale(0.8)',
        '@media (max-width: 835px)': {
            transform: 'scale(0.7)',
            marginTop: '-20%',
        },
        '@media (max-width: 600px)': {
            transform: 'scale(0.6)',
            marginTop: '-25%',
        },
        '@media (max-width: 513px)': {
            transform: 'scale(0.5)',
            marginTop: '-35%',
        },
        '@media (max-width: 430px)': {
            transform: 'scale(0.45)',
            marginTop: '-45%',
        },
        '@media (max-width: 390px)': {
            transform: 'scale(0.4)',
            marginTop: '-50%',
        },
        '@media (max-width: 360px)': {
            transform: 'scale(0.35)',
            marginTop: '-55%',
        },
    },
    titleSize: {
        fontSize: '1.5rem',
        padding: '1rem 0rem 1rem 0rem ',
        fontWeight: 'bold',
        border: '1px solid #e0e0e0',
    },
    letterSize: {
        fontSize: '21.5px',
        paddingTop: '30px',
        paddingLeft: '45px',
        paddingBottom: '30px',
        letterSpacing: '0.8px',
        border: '1px solid #e0e0e0',
    },
    letterSizeProfileMap: {
        fontSize: '21.5px',
        paddingTop: '40px',
        paddingLeft: '80px',
        paddingBottom: '40px',
        border: '1px solid #e0e0e0',
    },
    profileMap: {
        fontSize: '21.5px',
        paddingTop: '30px',
        paddingLeft: '45px',
        paddingBottom: '30px',
        letterSpacing: '0.8px',
        borderLeft: '1px solid #e0e0e0',
    },
    valueSize: {
        transform: 'scale(0.90)',
        '@media (max-width: 370px)': {
            maxWidth: '100%',
            marginLeft: '-3.3rem',
            marginTop: '-20rem',
            overflow: 'hidden',
        },
    },
    importValue: {
        fontSize: '25px',
        transform: 'scale(0.90)',
        fontWeight: 'bold',
    },
    soyField: {
        position: 'absolute',
        left: '21.5rem',
        bottom: '27rem',
        border: 'none',
        fontWeight: 'bold',
        '@media (max-width: 760px)': {
            display: 'none',
        },
    },
    soyField2: {
        display: 'none',
        fontWeight: 'bold',
        '@media (max-width: 760px)': {
            display: 'block',
            marginTop: '10px',
        },
    },
    soyNumber: {
        fontSize: 22,
        fontWeight: '600',
    },
    fertilizerText: {
        borderTop: '35px solid white',
        borderRight: '35px solid white',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '14rem',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14.5,
        '@media (max-width: 680px)': {
            paddingTop: 20,
        },
        '@media (max-width: 759px)': {
            textAlign: 'center',
            justifyContent: 'center',
            margin: '2vh auto',
            paddingBottom: '2vh',
            paddingRight: 10,
        },
    },

    fertilizerPrice: {
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: 20,
        transform: 'translateY(-5px)',
        letterSpacing: '0.3px',
        fontWeight: '300',
        color: '#2B303B',
    },
}));

export default function TableSummaryProgram({ proposalData }) {
    const { auth } = useContext(authContext);

    const [fertilizers, setFertilizers] = useState([]);

    const [fertilizer1, setFertilizer1] = useState({
        name: '',
        baseValue: '',
    });
    const [fertilizer2, setFertilizer2] = useState({
        name: '',
        baseValue: '',
    });

    const {
        client,
        specificationPercentages,
        servicesPercentages,
        profileMapPercentage,
        paymentPercentages,
        serviceSubPrice,
        paymentSubPrice,
        setSaveOptions,
        saveOptions,
    } = useContext(clientContext);

    const VALUE_PER_HECTARE = client.clientId
        ? paymentSubPrice /
          (saveOptions?.areaMade ? saveOptions?.areaMade : client.ha)
        : null;

    const VALUE_PER_HECTARE_IN_BAGS =
        !saveOptions?.soyValue || !client.clientId
            ? 0
            : VALUE_PER_HECTARE / saveOptions?.soyValue;

    const FERTILIZER_VALUE1 = fertilizer1.baseValue
        ? (
              paymentSubPrice /
              (saveOptions?.areaMade ? saveOptions?.areaMade : client.ha) /
              fertilizer1.baseValue
          ).toFixed(2)
        : null;

    const FERTILIZER_VALUE2 = fertilizer2.baseValue
        ? (
              paymentSubPrice /
              (saveOptions?.areaMade ? saveOptions?.areaMade : client.ha) /
              fertilizer2.baseValue
          ).toFixed(2)
        : null;

    useEffect(() => {
        setSaveOptions((options) => ({
            ...options,
            compost1: null,
            compost2: null,
        }));
    }, [setSaveOptions]);

    useEffect(() => {
        if (
            proposalData &&
            Object.keys(proposalData.fertilizers).length !== 0
        ) {
            const names = Object.keys(proposalData.fertilizers);
            if (names[0]) {
                setFertilizer1({
                    name: names[0],
                    baseValue: proposalData.fertilizers[names[0]],
                });
            }
            if (names[1]) {
                setFertilizer2({
                    name: names[1],
                    baseValue: proposalData.fertilizers[names[1]],
                });
            }
        }
    }, [proposalData, fertilizers]);

    useEffect(() => {
        const callApiFindAllFertilizer = async () => {
            const response = await findAllFertilizer(
                auth,
                auth.data.user.stateId
            );

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const data = [];
            Object.entries(payload[0].fertilizer).map((curr) =>
                data.push({ name: curr[0], baseValue: curr[1] })
            );
            setFertilizers(data);
        };

        try {
            callApiFindAllFertilizer();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    const defaultPropsFertilizer1 = {
        options: [{ baseValue: null, name: 'Nenhum' }, ...fertilizers],
        value: fertilizer1,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        getOptionDisabled: (option) => {
            return !!fertilizer2.name === option.name;
        },
        onChange: (event, newValue) => {
            setFertilizer1({
                name: newValue.name,
                baseValue: newValue.baseValue,
            });
            setSaveOptions((options) => ({
                ...options,
                compost1: {
                    name: newValue.name,
                    baseValue: newValue.baseValue,
                },
            }));
        },
    };

    const defaultPropsFertilizer2 = {
        options: [{ baseValue: null, name: 'Nenhum' }, ...fertilizers],
        value: fertilizer2,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        getOptionDisabled: (option) => {
            return !!fertilizer1.name === option.name;
        },
        onChange: (event, newValue) => {
            setFertilizer2({
                name: newValue.name,
                baseValue: newValue.baseValue,
            });
            setSaveOptions((options) => ({
                ...options,
                compost2: {
                    name: newValue.name,
                    baseValue: newValue.baseValue,
                },
            }));
        },
    };

    const classes = useStyles();
    return (
        <>
            <TableContainer className={classes.table} component={Paper}>
                <Table
                    sx={{ minWidth: 250 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={classes.titleSize}
                                align="center"
                            >
                                Itens
                            </TableCell>
                            <TableCell
                                className={classes.titleSize}
                                align="center"
                            >
                                Valor
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={'grid-x-ha-initial'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                Grid X Ha: Valor inicial
                            </TableCell>
                            <TableCell
                                className={classes.importValue}
                                align="center"
                            >
                                {(
                                    client.grid *
                                    (saveOptions?.areaMade
                                        ? saveOptions?.areaMade
                                        : client.ha)
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </TableCell>
                        </TableRow>
                        {specificationPercentages &&
                            Object.entries(specificationPercentages).map(
                                (curr, index) => {
                                    return (
                                        <TableRow
                                            key={curr[0]}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            <TableCell
                                                className={classes.letterSize}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                {specNames[index]}
                                            </TableCell>
                                            <TableCell
                                                className={classes.valueSize}
                                                align="center"
                                            >
                                                <TableSummaryValue
                                                    value={curr[1]}
                                                    areaMade={
                                                        saveOptions?.areaMade
                                                            ? saveOptions?.areaMade
                                                            : client.ha
                                                    }
                                                    grid={client.grid}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        <TableRow
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.profileMap}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                {' '}
                                Mapa de Perfil
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {profileMapPercentage &&
                            Object.entries(profileMapPercentage).map((curr) => {
                                return (
                                    <TableRow
                                        key={curr[0]}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            className={
                                                classes.letterSizeProfileMap
                                            }
                                            align="left"
                                            component="th"
                                            scope="row"
                                        >
                                            {curr[0]}
                                        </TableCell>
                                        <TableCell
                                            className={classes.valueSize}
                                            align="center"
                                        >
                                            <TableSummaryValue
                                                value={curr[1]}
                                                areaMade={
                                                    saveOptions?.areaMade
                                                        ? saveOptions?.areaMade
                                                        : client.ha
                                                }
                                                grid={client.grid}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {servicesPercentages &&
                            Object.entries(servicesPercentages).map((curr) => {
                                return (
                                    <TableRow
                                        key={curr[0]}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            className={classes.letterSize}
                                            align="left"
                                            component="th"
                                            scope="row"
                                        >
                                            {curr[0]}
                                        </TableCell>
                                        <TableCell
                                            className={classes.valueSize}
                                            align="center"
                                        >
                                            <TableSummaryValue
                                                value={curr[1]}
                                                areaMade={
                                                    saveOptions?.areaMade
                                                        ? saveOptions?.areaMade
                                                        : client.ha
                                                }
                                                grid={client.grid}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        <TableRow
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                Desconto À Vista
                            </TableCell>
                            <TableCell align="center">
                                <TableSummaryValue
                                    value={
                                        serviceSubPrice *
                                        (paymentPercentages?.buyIn / 100)
                                    }
                                    buyIn={
                                        serviceSubPrice &&
                                        paymentPercentages.buyIn !== 0
                                            ? true
                                            : false
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                Juros Residual
                            </TableCell>
                            <TableCell align="center">
                                <TableSummaryValue
                                    interestSum={true}
                                    value={paymentPercentages?.interestSum ? paymentPercentages.interestSum : 0}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'final-amount'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                {'Valor Final'}
                            </TableCell>
                            <TableCell
                                className={classes.importValue}
                                align="center"
                            >
                                {(client.clientId
                                    ? paymentSubPrice
                                    : 0
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'final-amount-ha'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                {'Valor Final/ha'}
                            </TableCell>
                            <TableCell
                                className={classes.importValue}
                                align="center"
                            >
                                {(VALUE_PER_HECTARE
                                    ? VALUE_PER_HECTARE
                                    : 0
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'final-amount-soy'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                {'Valor Final/ha'}
                                <br />
                                {'em sacas de Soja'}
                            </TableCell>
                            <TableCell
                                className={classes.soyNumber}
                                align="center"
                                component="th"
                                scope="row"
                            >
                                {`${VALUE_PER_HECTARE_IN_BAGS.toFixed(
                                    2
                                )} em sacas`}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'final-amount-soy-per3'}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                {'Valor Final/ha'}
                                <br />
                                {'em sacas de Soja diluído em 3 anos'}
                            </TableCell>
                            <TableCell
                                className={classes.soyNumber}
                                align="center"
                            >
                                {`${(VALUE_PER_HECTARE_IN_BAGS / 3).toFixed(
                                    2
                                )} em sacas`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                <em className={classes.fertilizerText}>
                                    Adubo 1:
                                </em>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Autocomplete
                                        {...defaultPropsFertilizer1}
                                        id="input-fertliizer-1"
                                        autoSelect
                                        disabled={
                                            client.clientId ? false : true
                                        }
                                        disableClearable
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.soyNumber}
                            >
                                {FERTILIZER_VALUE1 && (
                                    <>
                                        {FERTILIZER_VALUE1} {fertilizer1.name}
                                        /ha
                                        <div
                                            className={classes.fertilizerPrice}
                                        >
                                            {`R$ ${fertilizer1.baseValue}`}
                                        </div>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.letterSize}
                                align="left"
                                component="th"
                                scope="row"
                            >
                                <em className={classes.fertilizerText}>
                                    Adubo 2:
                                </em>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Autocomplete
                                        {...defaultPropsFertilizer2}
                                        id="input-fertliizer-2"
                                        autoSelect
                                        disabled={
                                            client.clientId ? false : true
                                        }
                                        disableClearable
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.soyNumber}
                            >
                                {FERTILIZER_VALUE2 && (
                                    <>
                                        {FERTILIZER_VALUE2} {fertilizer2.name}
                                        /ha
                                        <div
                                            className={classes.fertilizerPrice}
                                        >
                                            {`R$ ${fertilizer2.baseValue}`}
                                        </div>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
