import { useState, useContext, useEffect } from 'react';

import {
    Container,
    Title,
    SummaryTable,
    SendButtons,
    InvisibleContainer,
} from './general-summary.styles';

/* Material UI */
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { red, blue } from '@material-ui/core/colors';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

/* Components */
import TableSummaryProgram from '../table-summary/table-summary.component';
import ActionButtons from '../action-buttons/action-buttons.component';
import PopUp from '../popup/popup.component';

/*  PDF Imports */
import { BlobProvider } from '@react-pdf/renderer';
import SummaryProposalPdf from '../../utils/pdf/react-pdf/summaryProposalPdf';

/* Context */
import { authContext } from '../../contexts/AuthContext';
import { clientContext } from '../../contexts/ClientContext';

/* API */
import { createProposal, updateProposal } from '../../api/ApiProposal';

const theme = createTheme({
    palette: {
        primary: { main: blue[900] },
        secondary: { main: red[600] },
    },
});

const theme2 = createTheme({
    palette: {
        primary: { main: blue[700] },
    },
});

const useStyles = makeStyles((theme) => ({
    icon: {
        transform: 'scale(1.8)',
        margin: '10px',
    },
    styleTitle: {
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    textField: {
        justifySelf: 'center',
        marginTop: '30px',
        marginLeft: '10px',
        marginBottom: '-350px',
        width: '80%',
        '@media (max-width: 835px)': {
            transform: 'scale(0.6)',
            marginTop: '-5%',
        },
    },
}));

export default function GeneralSummary({ proposalData }) {
    const {
        client,
        saveOptions,
        specificationPercentages,
        profileMapPercentage,
        servicesPercentages,
        paymentPercentages,
        paymentSubPrice,
        setSaveOptions,
    } = useContext(clientContext);
    const { auth } = useContext(authContext);
    const [ready, setReady] = useState(false);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [observations, setObservations] = useState(null);

    useEffect(() => {
        setSaveOptions((options) => ({
            ...options,
            observations: observations,
        }));
    }, [setSaveOptions, observations]);

    useEffect(() => {
        if (proposalData) {
            setObservations(proposalData?.observations);
        }
    }, [proposalData]);

    const saveProposal = async () => {
        try {
            const response = await createProposal(
                auth,
                client,
                saveOptions,
                specificationPercentages,
                profileMapPercentage,
                servicesPercentages,
                paymentSubPrice
            );
            if (response.status !== 201) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Proposta criada com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Proposta não pode ser criada: ${error.message}`);
            setPopup(true);
            setSuccess(0);
        }
    };

    const editProposal = async () => {
        try {
            const response = await updateProposal(
                auth,
                client,
                saveOptions,
                specificationPercentages,
                profileMapPercentage,
                servicesPercentages,
                paymentSubPrice,
                proposalData.proposalId
            );
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Proposta atualizada com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Proposta não pode ser atualizada: ${error.message}`);
            setPopup(true);
            setSuccess(0);
        }
    };

    const classes = useStyles();
    return (
        <>
            <InvisibleContainer id="general-summary-container"></InvisibleContainer>
            <Container>
                <Title className={classes.styleTitle}>Resumo Geral</Title>
                <SummaryTable>
                    <TextField
                        className={classes.textField}
                        id="outlined-multiline-static"
                        label="Observações"
                        multiline
                        minRows={4}
                        variant="outlined"
                        value={observations}
                        defaultValue={observations}
                        onChange={(event) => {
                            setObservations(event.target.value);
                            setSaveOptions((options) => ({
                                ...options,
                                observations: event.target.value,
                            }));
                        }}
                    />
                    <TableSummaryProgram proposalData={proposalData} />
                </SummaryTable>
                <SendButtons>
                    <ThemeProvider theme={theme}>
                        <ActionButtons
                            label="Enviar pelo Whatsapp"
                            color="primary"
                            startIcon={
                                <WhatsAppIcon className={classes.icon} />
                            }
                            disabled={true}
                        />
                        {ready && (
                            <BlobProvider
                                document={
                                    <SummaryProposalPdf
                                        auth={auth}
                                        proposalId={proposalData?.proposalId}
                                        saName={client.saName}
                                        client={client}
                                        specificationPercentages={
                                            specificationPercentages
                                        }
                                        profileMapPercentage={
                                            profileMapPercentage
                                        }
                                        servicesPercentages={
                                            servicesPercentages
                                        }
                                        paymentPercentages={paymentPercentages}
                                        paymentSubPrice={paymentSubPrice}
                                        saveOptions={saveOptions}
                                    />
                                }
                            >
                                {({ url }) => (
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <ActionButtons
                                            label="Visualizar PDF"
                                            color="primary"
                                            disabled={
                                                client.clientId ? false : true
                                            }
                                            startIcon={
                                                <PictureAsPdfIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            onClick={() => setReady(false)}
                                        />
                                    </a>
                                )}
                            </BlobProvider>
                        )}
                        {!ready && (
                            <ActionButtons
                                label="Criar arquivo PDF"
                                className="buttons"
                                color="secondary"
                                disabled={
                                    client.clientId &&
                                    saveOptions.soyValue !== 0 &&
                                    saveOptions.soyValue
                                        ? false
                                        : true
                                }
                                startIcon={
                                    <PictureAsPdfIcon
                                        className={classes.icon}
                                    />
                                }
                                onClick={() => {
                                    setReady(false);
                                    setTimeout(() => {
                                        setReady(true);
                                    }, 1);
                                }}
                            />
                        )}
                        <ThemeProvider theme={theme2}>
                            <ActionButtons
                                label={
                                    proposalData
                                        ? 'Atualizar proposta'
                                        : 'Salvar no sistema'
                                }
                                color="primary"
                                disabled={
                                    client.clientId &&
                                    saveOptions.soyValue !== 0 &&
                                    saveOptions.soyValue
                                        ? false
                                        : true
                                }
                                startIcon={
                                    <SaveIcon className={classes.icon} />
                                }
                                onClick={() => {
                                    proposalData
                                        ? editProposal()
                                        : saveProposal();
                                }}
                            />
                        </ThemeProvider>
                    </ThemeProvider>
                </SendButtons>
                {popup ? (
                    <PopUp
                        auth={auth}
                        setPopup={setPopup}
                        title={popupTitle}
                        string={popupText}
                        success={success}
                        isForPdf={true}
                        saName={auth.data.user.name}
                        client={client}
                        specificationPercentages={specificationPercentages}
                        servicesPercentages={servicesPercentages}
                        paymentPercentages={paymentPercentages}
                        paymentSubPrice={paymentSubPrice}
                        saveOptions={saveOptions}
                        profileMapPercentage={profileMapPercentage}
                    />
                ) : null}
            </Container>
        </>
    );
}
