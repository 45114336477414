export const links = [
    {
        id: 1,
        text: 'Buscar Cliente',
        url: '#find-client-container',
    },
    {
        id: 2,
        text: 'Dados Gerais',
        url: '#general-data-container',
    },
    {
        id: 3,
        text: 'Especificações',
        url: '#specification-container',
    },
    {
        id: 4,
        text: 'Serviços',
        url: '#services-included-container',
    },
    {
        id: 5,
        text: 'Pagamento',
        url: '#payment-method-container',
    },
    {
        id: 6,
        text: 'Pontos',
        url: '#points-program-container',
    },
    {
        id: 7,
        text: 'Resumo',
        url: '#general-summary-container',
    },
];
