import jwt from 'jsonwebtoken';
import Axios from 'axios';
import { config } from '../config';

export const apiUrl = config.apiUrl;

export const login = async (email, password) => {
    try {
        const response = await Axios.get(`${apiUrl}/auth/login`, {
            auth: {
                username: email,
                password: password,
            },
        });
        const data = await handleResponse(response);

        return {
            token: data.payload[0].token,
            data: jwt.decode(data.payload[0].token),
        };
    } catch (err) {
        return {
            error: true,
            message: err,
        };
    }
};

export const logout = () => {
    localStorage.removeItem('user');
    return 0;
};

export const handleResponse = async (response) => {
    try {
        if (response.status !== 200) {
            if (response.status === 404 || response.status === 500) {
                logout();
                History.push('/login');
                window.location.reload();
            }
            const error =
                (response.data && response.data.message) || response.statusText;
            throw error;
        }
        return response.data;
    } catch (err) {
        logout();
    }
};

export async function forgotPassword(email) {
    try {
        return await Axios.put(`${apiUrl}/auth/forgotPassword`, {
            email,
        });
    } catch (err) {
        return err;
    }
}
