import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 0px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    max-width: 80vw;
    overflow: hidden;
    display: grid;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    grid-template-columns: repeat autofit(minmax(200px, auto));
    grid-template-areas:
        'title title title'
        'search search grid'
        'obs obs obs';

    @media screen and (max-width: 1150px) {
        grid-template-areas:
            'title title title'
            'search search search'
            'grid grid grid'
            'obs obs obs';
        max-width: 80vw;
    }

    @media screen and (max-width: 700px) {
        max-width: 94vw;
    }
`;

export const Search = styled.div`
    margin-top: 2rem;
    font-size: 18px;
    grid-area: search;
    grid-gap: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    grid-area: title;
    border-bottom: 1px solid #e0e0e0;
    font-size: 22px;
    padding: 10px 10px 10px 18px;
    font-weight: 500;
    color: #444446;
    font-family: 'Roboto';
`;

export const Grid = styled.div`
    grid-area: grid;
    font-size: 16px;
    margin: 2rem auto;
    @media screen and (max-width: 700px) {
        margin: 20px auto;
    }
`;

export const Observation = styled.div`
    padding: 10px 30px 10px 20px;
    grid-area: obs;
    border-top: 1px solid #e0e0e0;
    margin-left: 10px;
    margin-right: 22px;
`;

export const InvisibleContainer = styled.div`
    height: 70px;
`;
