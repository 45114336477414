import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container } from './custom-text-field-value.style';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textControl: {
        width: '100%',
        paddingTop: '20px',
    },
}));

export default function CustomTextFieldValue({
    isTotalArea,
    areaTotal,
    setAreaMade,
    initialValue,
}) {
    const [label, setLabel] = useState('Qual área será feita?');
    const [value, setValue] = useState(0);
    const [error, setError] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        if (isTotalArea) {
            setLabel('Qual área será feita?');
            setError(false);
        }
    }, [isTotalArea]);

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const validate = (params) => {
        const eventValue = params.target.value;
        if (eventValue <= 0 || eventValue > areaTotal) {
            setLabel('Valor invalido! Será utilizada área total.');
            setValue(eventValue);
            setError(true);
            setAreaMade(0);
            return;
        }
        setLabel('Qual deve ser a área?');
        setError(false);
        setValue(eventValue);
        setAreaMade(Number(eventValue));
    };

    return (
        <Container>
            <TextField
                id="area-made"
                className={classes.textControl}
                label={label}
                placeholder="Informe aqui"
                margin="normal"
                type="number"
                error={error}
                value={value}
                onChange={(event) => validate(event)}
            />
        </Container>
    );
}
