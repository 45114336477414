import React, { createContext, useState, useEffect, useContext } from 'react';

import { authContext } from './AuthContext';

/* API */
import { findPrincingFromState } from '../api/ApiState';

export const statePriceContext = createContext({});

const StatePriceProvider = ({ proposalStatePrice, children }) => {
    const { auth } = useContext(authContext);
    const [statePrice, setStatePrice] = useState(null);

    useEffect(() => {
        const callApifindPrincingFromState = async () => {
            const response = await findPrincingFromState(
                auth,
                auth.data.user.stateId
            );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            payload[0]['stateId'] = auth.data.user.stateId;
            setStatePrice(payload[0]);
        };

        try {
            if (proposalStatePrice) setStatePrice(proposalStatePrice);
            else if (auth.data !== null) callApifindPrincingFromState();
        } catch (err) {
            console.log(err);
        }
    }, [auth, proposalStatePrice]);

    return (
        <statePriceContext.Provider value={{ statePrice, setStatePrice }}>
            {children}
        </statePriceContext.Provider>
    );
};

export default StatePriceProvider;
