const orderByObjectKey = (objects, key) => {
    if (objects.length === 0 && objects) return [];
    if (typeof objects[0][key] === 'number')
        return objects.sort((a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });

    if (typeof objects[0][key] === 'string')
        return objects.sort((a, b) => {
            if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
            if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
            return 0;
        });

    return false;
};

export default orderByObjectKey;
