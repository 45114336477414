import { useState, useEffect, useContext, useMemo } from 'react';

import {
    Container,
    Column1,
    Column2,
    Column3,
    ContainerRow,
    InvisibleContainer,
    CustomTitleParcel,
    RemainingValue,
    Chip,
    InCashSwitch,
    ResidualValue,
} from './payment-method.styles';

import orderByObjectKey from '../../utils/orderByObjectKey';

/* Context */
import { clientContext } from '../../contexts/ClientContext';
import { statePriceContext } from '../../contexts/StatePriceContext';

/* Material ui */
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { lightGreen, red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

/* Components */
import Parcel from '../parcel/parcel.component';
import Price from '../price/price.component';

import { format, addMonths, isBefore, set } from 'date-fns';
import Subtotal from '../subtotal/subtotal.component';
import { isValidDate } from '../../utils/isValidDate';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const theme = createTheme({
    palette: {
        primary: { main: lightGreen[300] },
        secondary: { main: red[600] },
    },
});

const useStyles = makeStyles((theme) => ({
    styleTitle: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingBottom: 20,
        fontFamily: 'Roboto',
        fontWeight: '500',
        color: '#444446',
        '@media (max-width: 759px)': {
            marginBottom: -10,
            paddingLeft: 0,
            paddingTop: 10,
            paddingRight: 10,
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    formControlLab: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1),
        width: '14rem',
    },
    button: {
        width: '2rem',
        margin: '0 auto',
        height: '60px',
        float: 'right',
        transform: 'translateY(-65px)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    subtotal: {
        padding: '40px 20px 20px 20px',
        color: '#424248',
        fontWeight: 300,
        '@media (max-width: 802px)': {
            fontSize: '1.5rem',
        },
        '@media (max-width: 759px)': {
            padding: 'auto',
            paddingRight: -20,
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            marginBottom: '-10rem',
        },
    },
    switch: {
        width: '3.5rem',
        marginRight: '-30px',
    },

    subtotalPrice: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '28px',
        letterSpacing: '1.2px',
        fontWeight: '400px',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 1400px)': {
            marginBottom: '-10rem',
            justifyContent: 'end',
            transform: 'translateY(-111.5px)',
            marginRight: '2rem',
            borderLeft: 'none',
        },
        '@media (max-width: 1086px)': {
            marginBottom: '-15rem',
            justifyContent: 'end',
            transform: 'translateY(-205.5px)',
            marginRight: '2rem',
            borderLeft: 'none',
        },
        '@media (max-width: 970px)': {
            marginBottom: '-3rem',
            justifyContent: 'end',
            transform: 'translateY(-110.5px)',
            marginRight: '2rem',
            borderLeft: 'none',
        },
        '@media (max-width: 802px)': {
            marginRight: '2rem',
            fontSize: '1.5rem',
            transform: 'translateY(-100px)',
        },

        '@media (max-width: 759px)': {
            transform: 'translateY(-70px)',
            marginBottom: '-5rem',
            fontSize: '1.2rem',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '2.3rem',
        },
        '@media (max-width: 360px)': {
            fontSize: '22px',
        },
    },
}));

export default function PaymentMethod({ proposalData }) {
    const today = useMemo(() => new Date(), []);

    const { statePrice } = useContext(statePriceContext);
    const {
        client,
        serviceSubPrice,
        paymentSubPrice,
        setSaveOptions,
        setPaymentPercentages,
    } = useContext(clientContext);

    const [paymentOptions, setPaymentOptions] = useState([]);
    const [paymentSelected, setPaymentSelected] = useState('');

    /* 
    [
        {id: 1, value: 22555, dueDate: 17/04/2022, additionalValue: ''},
        {id: 2, value: 30000, dueDate: 17/06/2023, additionalValue: ''},
        {id: 3, value: 20000, dueDate: 18/06/2023, additionalValue: ''},
        ...
    ]
    */
    const [parcels, setParcels] = useState([]);

    const [inCashParcel, setInCashParcel] = useState({
        value: 0,
        dueDate: format(today, 'MM/dd/yyyy'),
    });

    // valor restante a pagar (mostrado em tela)
    const [remainingValue, setRemainingValue] = useState(paymentSubPrice);

    // desconto a vista
    const [buyInPrice, setBuyInPrice] = useState(null);

    // flag se é à vista
    const [isInCash, setIsInCash] = useState(true);

    const [biggerParcels, setBiggerParcels] = useState([]);

    const [expectedCollectDate, setExpectedCollectDate] = useState(today);
    const [proposalDate] = useState(
        proposalData?.createdAt ? new Date(proposalData?.createdAt) : today
    );

    const [interestSum, setInterestSum] = useState(0);

    useEffect(() => {
        setBiggerParcels(
            parcels.filter((curr) => {
                const parcelDate = new Date(curr.dueDate);
                let collectDate = new Date(expectedCollectDate);
                const limitDate = new Date(
                    collectDate.getUTCFullYear(),
                    collectDate.getUTCMonth() +
                        statePrice?.paymentForm.residualInterestMonth,
                        collectDate.getUTCDate()
                );
                return parcelDate > limitDate;
            })
        );
    }, [
        parcels,
        expectedCollectDate,
        statePrice?.paymentForm.parcialDiscountMonth,
        statePrice?.paymentForm.residualInterestMonth,
    ]);

    useEffect(() => {
        // funcao que recebe duas datas e retorna a diferença entre meses
        const getDiffMonths = (biggestDate, smallestDate) => {
            biggestDate = new Date(biggestDate);
            smallestDate = new Date(smallestDate);

            if (
                !smallestDate ||
                isBefore(
                    smallestDate,
                    new Date(
                        format(
                            addMonths(
                                expectedCollectDate,
                                statePrice?.paymentForm.residualInterestMonth
                            ),
                            'MM/dd/yyyy'
                        )
                    )
                )
            ) {
                smallestDate = new Date(
                    format(
                        addMonths(
                            today,
                            statePrice?.paymentForm.residualInterestMonth
                        ),
                        'MM/dd/yyyy'
                    )
                );
            }

            const res =
                biggestDate.getMonth() -
                smallestDate.getMonth() +
                12 * (biggestDate.getFullYear() - smallestDate.getFullYear());
            return isNaN(res) ? 0 : res;
        };
        const setSaveOptionsData = (data) => {
            let allParcels = {};
            !isInCash &&
                parcels.map(
                    (curr) =>
                        (allParcels[curr.id] = {
                            value: Number(curr.value),
                            dueDate: curr.dueDate,
                            additionalValue: curr.additionalValue,
                        })
                );
            setSaveOptions((options) => ({
                ...options,
                parcels: data || allParcels,
            }));
        };

        if (statePrice) {
            if (isInCash) {
                inCashParcel.value = Number(inCashParcel.value);
                setSaveOptionsData({ 'À vista': inCashParcel });
                setBuyInPrice(statePrice.paymentForm.cashDiscount);
                setPaymentPercentages((paymentPercentage) => ({
                    ...paymentPercentage,
                    buyIn: statePrice.paymentForm.cashDiscount,
                }));
            } else if (biggerParcels.length >= 1) {
                setParcels((parcels) => {
                    biggerParcels.forEach((curr) => {
                        if (parcels[curr.id - 1]) {
                            parcels[curr.id - 1].additionalValue =
                                (serviceSubPrice -
                                    parcels.reduce(
                                        (acc, parcel) =>
                                            isBefore(
                                                new Date(parcel.dueDate),
                                                new Date(curr.dueDate)
                                            )
                                                ? acc + parcel.value
                                                : acc,
                                        0
                                    )) *
                                (
                                    (getDiffMonths(
                                        parcels[curr.id - 1].dueDate,
                                        parcels[curr.id - 2]?.dueDate
                                            ? parcels[curr.id - 2].dueDate
                                            : null
                                    ) *
                                        statePrice?.paymentForm
                                            .residualInterest) /
                                    100
                                ).toFixed(2);
                        }
                    });
                    return parcels;
                });

                let newInterest = parcels.reduce(
                    (acc, parcel) => acc + parcel?.additionalValue,
                    0
                )

                setInterestSum(newInterest);
                
                setRemainingValue(
                    isInCash
                        ? paymentSubPrice.toFixed(2) - inCashParcel.value
                        : parcels.reduce(
                              (acc, curr) => acc - curr.value,
                              paymentSubPrice.toFixed(2)
                          )
                );

                setBuyInPrice(null);
                setPaymentPercentages((paymentPercentage) => ({
                    ...paymentPercentage,
                    buyIn: null,
                    interestSum,
                }));
                setSaveOptionsData();
              
                if (
                    (parcels.filter((curr) => curr?.value > 0).length ===
                        parcels.length ||
                    parcels.filter((curr) => curr?.value > 0).length ===
                        parcels.length - 1) || proposalData
                ) {
                    parcels[parcels.length - 1].value = 
                    serviceSubPrice + 
                        parcels.reduce(
                            (acc, parcel) => acc + parcel?.additionalValue,
                            0
                        ) -
                        parcels.reduce((acc, parcel, index) => {
                            if (index === parcels.length - 1) return acc;
                            return acc + parcel?.value;
                        }, 0);
                }
            } else {
                setRemainingValue(
                    isInCash
                        ? paymentSubPrice.toFixed(2) - inCashParcel.value
                        : parcels.reduce(
                              (acc, curr) => acc - curr.value,
                              paymentSubPrice.toFixed(2)
                          )
                );
                setBuyInPrice(null);
                setPaymentPercentages((paymentPercentage) => ({
                    ...paymentPercentage,
                    buyIn: null,
                    interestSum: 0,
                }));
                setSaveOptionsData();
            }
            let result = paymentSubPrice.toFixed(2);

            setRemainingValue(
                isInCash
                    ? result - inCashParcel.value
                    : parcels.reduce((acc, curr) => acc - curr.value, result)
            );
            setSaveOptions((options) => ({
                ...options,
                remainingValue,
            }));
        }
    }, [
        inCashParcel,
        isInCash,
        parcels,
        paymentSubPrice,
        remainingValue,
        serviceSubPrice,
        setPaymentPercentages,
        setSaveOptions,
        statePrice,
        expectedCollectDate,
        biggerParcels,
        interestSum,
        today,
        proposalData,
    ]);

    useEffect(() => {
        if (proposalData) {
            if (proposalData.parcels.hasOwnProperty('À vista')) {
                setInCashParcel({
                    value: proposalData.parcels['À vista'].value,
                    dueDate: proposalData.parcels['À vista'].dueDate,
                });
                setIsInCash(true);
            } else {
                setIsInCash(false);
                const proposalParcels = Object.values(proposalData.parcels).map(
                    (curr, index) => {
                        return {
                            id: +`${index + 1}`,
                            value: curr.value,
                            dueDate: curr.dueDate,
                            additionalValue: curr.additionalValue,
                        };
                    }
                );

                proposalParcels.sort((a, b) => a.id - b.id);
                setParcels(proposalParcels);
            }
        }
    }, [proposalData]);

    useEffect(() => {
        const options = Array.from(Array(6).keys()).map(
            (curr, index) => `${index + 1}x`
        );

        setPaymentOptions(options);
        setSaveOptions((options) => ({
            ...options,
            expectedCollectDate: format(expectedCollectDate, 'MM/dd/yyyy'),
        }));

    }, []);

    useEffect(() => {
        if (paymentSelected !== '') {
            let [index] = paymentSelected.split('x');

            const newParcels = [];

            for (let i = 0; i < index; i++) {
                newParcels[i] = {
                    id: i + 1,
                    value: 0,
                    dueDate: null,
                    additionalValue: 0,
                };
            }

            setParcels(newParcels);
        }
    }, [paymentSelected, today]);

    useEffect(() => {   
        if(!proposalData?.expectedCollectDate) return;
        
        setExpectedCollectDate(new Date(proposalData.expectedCollectDate));
        setSaveOptions((options) => ({
            ...options,
            expectedCollectDate: proposalData.expectedCollectDate,
        }));
    }, [proposalData?.expectedCollectDate]);

    const handleDateChange = (date) => {
        if (date && isValidDate(date)) {
            setExpectedCollectDate(date);

            setSaveOptions((options) => ({
                ...options,
                expectedCollectDate: format(date, 'MM/dd/yyyy'),
            }));
        }
    };

    const disableIfInvalidParcel = (index) => {
        if(index === 0) return true;
        for(let i = 0; i < index; i++) {
            /* Não desabilita caso coloque um data válida e depois uma inválida 
               Possível solução: botar campo isValid na parcela ou adaptar uma função genérica
               para resolver esse problema e utilizar no componente de Parcel também
            */
            if(parcels[i].value === 0 || parcels[i].dueDate === null) {
                return false;
            }
        }
        return true;
    }

    const defaultPropsPayment = {
        options: paymentOptions,
        value: paymentSelected,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setPaymentSelected(newValue);
        },
    };

    const classes = useStyles();

    return (
        <>
            <InvisibleContainer id="payment-method-container"></InvisibleContainer>
            <Container>
                <ContainerRow>
                    <Typography variant="h5" className={classes.styleTitle}>
                        Formas de Pagamento
                    </Typography>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Chip>
                            {isInCash && (
                                <Parcel
                                    parcel={inCashParcel}
                                    parcels={parcels}
                                    setParcels={setInCashParcel}
                                    inCash={true}
                                    disabled={false}
                                />
                            )}
                        </Chip>
                        <Chip>
                            {!isInCash &&
                                parcels.length > 0 &&
                                orderByObjectKey(parcels, 'id').map(
                                    (parcel, index) => (
                                        <Parcel
                                            key={parcel.id}
                                            parcel={parcel}
                                            parcels={parcels}
                                            setParcels={setParcels}
                                            disabled={!disableIfInvalidParcel(index)}
                                        />
                                    )
                                )}
                        </Chip>
                    </Column1>
                    <Column2>
                        <Price
                            price={isInCash ? buyInPrice : null}
                            noText={true}
                        />
                    </Column2>
                    <Column3>
                        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={false}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker"
                                label="Data de Coleta Prevista"
                                value={expectedCollectDate}
                                onChange={handleDateChange}
                                // minDate={today}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <CustomTitleParcel>
                            Criar parcelas personalizadas:
                        </CustomTitleParcel>
                        <div>
                            <Autocomplete
                                {...defaultPropsPayment}
                                id="input-payment"
                                autoSelect
                                disableClearable
                                disabled={isInCash}
                                className={classes.formControlLab}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={`1x`}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <ThemeProvider theme={theme}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color={'primary'}
                                    disabled={
                                        client.clientId &&
                                        !isInCash &&
                                        parcels.length < 6
                                            ? false
                                            : true
                                    }
                                    onClick={() => {
                                        setParcels((prevState) => [
                                            ...prevState,
                                            {
                                                id: parcels.length + 1,
                                                value: 0,
                                                dueDate: null,
                                                additionalValue: 0,
                                            },
                                        ]);
                                    }}
                                >
                                    {<AddIcon />}
                                </Button>
                            </ThemeProvider>
                        </div>
                        <InCashSwitch>
                            <CustomTitleParcel>
                                {isInCash ? 'À vista' : 'Parcelado'}
                            </CustomTitleParcel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switch}
                                        checked={isInCash}
                                        onChange={() => setIsInCash(!isInCash)}
                                    />
                                }
                            />
                        </InCashSwitch>
                        <RemainingValue>
                            <h3>Valor Restante: </h3>
                            <p>
                                {Number(remainingValue).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }
                                )}
                            </p>
                        </RemainingValue>

                        {biggerParcels.length > 0 && !isInCash && (
                            <ResidualValue>
                                Juros Residual de{' '}
                                <span
                                    style={{
                                        color: '#e0645e',
                                        display: 'block',
                                    }}
                                >
                                    {` ${statePrice?.paymentForm?.residualInterest}`}
                                    %{' '}
                                </span>{' '}
                                para parcelas maiores{' '}
                                <span
                                    style={{
                                        color: '#e0645e',
                                        display: 'block',
                                    }}
                                >
                                    {`${statePrice?.paymentForm?.residualInterestMonth} `}
                                    meses a partir da data de coleta prevista
                                </span>
                            </ResidualValue>
                        )}
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.subtotal} variant="h4">
                            Subtotal do valor:
                        </Typography>
                    </Column1>
                    <Column2></Column2>
                    <Column3 className={classes.subtotalPrice}>
                        <Subtotal
                            subPrice={paymentSubPrice}
                            haMade={proposalData?.haMade}
                        />
                    </Column3>
                </ContainerRow>
            </Container>
        </>
    );
}
