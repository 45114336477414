import { useContext } from 'react';

import { links } from '../header/header-components/navData';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import ReorderIcon from '@material-ui/icons/Reorder';

import { clientContext } from '../../contexts/ClientContext';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        textAlign: 'center',
        fontSize: '18px',
        maxWidth: '20rem',
        maxHeight: '30rem',
        borderRadius: 10,
    },

    linkStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 14,
    },

    Query: {
        display: 'none',
        '@media (max-width: 1452px)': {
            display: 'block',
        },
    },

    ToggleMenu: {
        backgroundColor: 'transparent',
        position: 'fixed',
        zIndex: 100000,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        border: '1px solid rgba(666, 666, 666, 0.01)',
        cursor: 'pointer',
        bottom: 115,
        right: 40,
    },

    ReorderIcon: {
        borderRadius: '50%',
        border: '7px solid rgba(666, 666, 666, 0.01)',
        backgroundColor: 'rgb(215, 59, 48)',
        fontSize: 40,
        color: 'white',
    },

    extendedIcon: {
        position: 'fixed',
        zIndex: 100000,
        width: '175px',
        height: '80px',
        border: '1px solid rgba(666, 666, 666, 0.01)',
        bottom: 25,
        right: 40,
        fontFamily: 'Roboto',
        textAlign: 'center',
        color: 'white',
        fontSize: 15,
        fontWeight: 550,
        backgroundColor: 'rgb(215, 59, 48)',
    },
}));

export default function TransitionsModal() {
    const { paymentSubPrice } = useContext(clientContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={classes.Query}>
                <button
                    className={classes.ToggleMenu}
                    type="button"
                    onClick={handleOpen}
                >
                    <ReorderIcon className={classes.ReorderIcon} />
                </button>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <p id="transition-modal-description">
                                {links.map((link, index) => {
                                    return (
                                        <span className="" key={link.id}>
                                            <a
                                                onClick={handleClose}
                                                className={classes.linkStyle}
                                                href={link.url}
                                            >
                                                {link.text}
                                            </a>
                                        </span>
                                    );
                                })}
                            </p>
                        </div>
                    </Fade>
                </Modal>
            </div>

            <Fab
                variant="extended"
                href={links[4].url}
                className={classes.extendedIcon}
            >
                Valor Final{' '}
                {Number(paymentSubPrice).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })}
            </Fab>
        </>
    );
}
