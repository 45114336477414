import {
    Container,
    Title,
    Search,
    Grid,
    Observation,
    InvisibleContainer,
} from './general-data.styles';

/* Material UI */
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';

/* API */
import { findAllStates } from '../../api/ApiState';
import { findPrincingFromState } from '../../api/ApiState';
import { findSaState } from '../../api/ApiSa';

/* Context */
import { authContext } from '../../contexts/AuthContext';
import { statePriceContext } from '../../contexts/StatePriceContext';
import { clientContext } from '../../contexts/ClientContext';

import orderByObjectKey from '../../utils/orderByObjectKey';

/* Components */
import GridValues from '../grid-values/grid-values.component';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '480px',
        '@media (max-width: 680px)': {
            width: '80%',
            paddingTop: 20,
        },
    },
    button: {
        textTransform: 'none',
    },
    styleTitle: {
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    textControl: {
        width: '450px',

        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    observationText: {
        '@media screen and (max-width: 759px)': {
            fontSize: '1em',
        },
    },
}));

export default function GeneralData({
    proposalStatePrice = null,
    proposalData = null,
}) {
    const { auth } = useContext(authContext);
    const { setStatePrice } = useContext(statePriceContext);
    const { client, setClient } = useContext(clientContext);

    const [state, setState] = useState({
        uf: auth.data.user.uf,
        stateId: auth.data.user.stateId,
    });
    const [stateList, setStateList] = useState([]);
    const [areaSize, setAreaSize] = useState('');

    const classes = useStyles();

    useEffect(() => {
        setAreaSize(client.ha);
    }, [client]);

    useEffect(() => {
        if (client.stateId)
            setState({
                uf: client.uf,
                stateId: client.stateId,
            });
    }, [client.stateId, client.uf]);

    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStates(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);
            if (proposalData) {
                const state = orderedStates.filter((curr) => {
                    return curr.stateId === proposalData.stateId ? true : false;
                });
                setState(state[0]);
            }
        };

        const callApiFindSaState = async () => {
            const response = await findSaState(auth, auth.data.user.saId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);

            if (proposalData) {
                const state = orderedStates.filter((curr) => {
                    return curr.stateId === proposalData.stateId ? true : false;
                });
                setState(state[0]);
            }
        };

        try {
            if (auth.data.user.permissionId === 1) {
                // master
                callApiFindAllStates();
            } else {
                // gerente e AS
                callApiFindSaState();
            }
        } catch (err) {
            console.log(err);
        }
    }, [auth, proposalData]);

    useEffect(() => {
        const callApifindPrincingFromState = async () => {
            const response = await findPrincingFromState(auth, state.stateId);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            payload[0]['stateId'] = state.stateId;
            setStatePrice(payload[0]);
        };

        try {
            if (!proposalStatePrice) callApifindPrincingFromState();
        } catch (err) {
            console.log(err);
        }
    }, [auth, state, setStatePrice, proposalStatePrice]);

    const defaultPropsState = {
        options: stateList,
        value: state,
        getOptionLabel: (option) => {
            return option.uf || '';
        },
        getOptionSelected: (option) => {
            return option.uf || '';
        },
        onChange: (event, newValue) => {
            if (client.stateId !== newValue.stateId)
                setClient({
                    clientId: null,
                    name: '',
                    ha: null,
                    areaMade: null,
                    grid: null,
                    stateId: null,
                });
            setState({ uf: newValue.uf, stateId: newValue.stateId });
        },
    };

    return (
        <>
            <InvisibleContainer id="general-data-container">
                {' '}
            </InvisibleContainer>
            <Container>
                <Title className={classes.styleTitle}>Dados Gerais</Title>
                <Search>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <Autocomplete
                            {...defaultPropsState}
                            id="input-states"
                            autoSelect
                            disableClearable
                            disabled={
                                proposalData
                                    ? true
                                    : client.stateId
                                    ? true
                                    : false
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Estado"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                    <TextField
                        id="area-size-ha"
                        className={classes.textControl}
                        label="Tamanho da área (ha)"
                        style={{ margin: 8 }}
                        placeholder="Informe aqui"
                        margin="normal"
                        type="number"
                        value={areaSize || ''}
                        InputProps={{ readOnly: true }}
                        onChange={({ target }) => {
                            setAreaSize(target.value);
                        }}
                    />
                </Search>
                <Grid>
                    <GridValues proposalData={proposalData} />
                </Grid>
                <Observation>
                    <h3>Observações:</h3>
                    <div>
                        <ul>
                            <li className={classes.observationText}>
                                Grids superiores a 5 hectares tem limitações
                                técnicas{' '}
                                <span>(sem taxa de semente, por exemplo)</span>{' '}
                                e reduzem a capacidade de gerar resultados, mas
                                podem dar noções muito boas da área;
                            </li>
                        </ul>
                    </div>
                </Observation>
            </Container>
        </>
    );
}
