import styled from 'styled-components/macro';

export const EachRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const EachElement = styled.div`
    width: 15rem;
    border: 1px solid #e0e0e0;
    font-family: Roboto, sans-serif;
    display: grid;
    justify-content: center;
    align-items: center;

    @media (min-width: 1025px) and (max-width: 1280px) {
        width: 12rem;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        width: 11rem;
    }
    @media (min-width: 481px) and (max-width: 767px) {
        width: 11rem;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        width: 8rem;
    }
    @media screen and (max-width: 320px) {
        width: 7rem;
    }
`;
