import { useEffect } from 'react';

import { DrawerAdjustment } from './new-proposal.styles';

/* Components */
import Header from '../../components/header/header.component';
import FindClientModal from '../../components/find-client-modal/find-client-modal.component';
import GeneralData from '../../components/general-data/general-data.component';
import Specification from '../../components/specification/specification.component';
import ServicesIncluded from '../../components/services-included/services-included.component';
import PaymentMethod from '../../components/payment-method/payment-method.component';
import SoyValue from '../../components/soy-value/soy-value.component';
import PointsProgram from '../../components/points-program/points-program.component';
import GeneralSummary from '../../components/general-summary/general-summary-component';
import ToggleMenu from '../../components/toggle-menu/toggle-menu.component';

/* Context */
import StatePriceProvider from '../../contexts/StatePriceContext';
import ClientProvider from '../../contexts/ClientContext';

export default function NewProposal() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <StatePriceProvider>
                <ClientProvider>
                    <ToggleMenu />
                    <Header navBar={true} />
                    <FindClientModal />
                    <GeneralData />
                    <Specification />
                    <ServicesIncluded />
                    <PaymentMethod />
                    <SoyValue />
                    <PointsProgram />
                    <GeneralSummary />
                </ClientProvider>
            </StatePriceProvider>
        </DrawerAdjustment>
    );
}
