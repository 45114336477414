import { Text, Value, Observation, Empty } from './price.styles';

export default function Price({ price, observation, noText }) {
    return (
        <>
            {price !== null ? (
                <Text>
                    <Value value={Number(price)}> {price}% </Value>{' '}
                    {noText ? '' : 'no Preço Final'}
                </Text>
            ) : null}
            {observation ? (
                <Observation> {'1% de desconto a cada 1000 Ha'} </Observation>
            ) : null}{' '}
            {!price && price !== 0 && !observation ? (
                <Empty> --- </Empty>
            ) : null}
        </>
    );
}
