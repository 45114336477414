import styled from 'styled-components/macro';

export const Container = styled.div`
    display: grid;
    width: 93%;
    justify-items: center;
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 40px;
    grid-template-columns: 20% 35% 35% 10%;
    grid-template-areas: 'column1 column2 column3 column4';
    height: 7rem;
    margin: 50px auto;
    padding: 10px;
    @media screen and (max-width: 525px) {
        grid-template-areas:
            'column1 column1 column2 column2'
            'column3 column3 column3 column4';
        height: 12rem;
    }
`;

export const ParcelTitle = styled.div`
    display: grid;
    grid-area: column1;
    align-items: center;
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 10px;
    color: rgb(44 44 46);
`;

export const Percent = styled.div`
    display: grid;
    grid-area: column3;
    align-items: center;
    color: #107000;
    font-weight: normal;
    font-size: 14px;
`;

export const DateDiv = styled.div`
    display: grid;
    grid-area: column2;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: center;
    align-items: center;
    transform: scale(1) translateY(-12px);
`;

export const ButtonX = styled.div`
    display: grid;
    grid-area: column4;
`;
