import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { lightGreen, red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import PopUp from '../popup/popup.component';
import AssignmentIcon from '@material-ui/icons/Assignment';

// Components
import { Container, Title } from './states-data.styles';

// Context
import { authContext } from '../../contexts/AuthContext';

// API
import {
    findAllStatesFromSas,
    setSaState,
    deleteSaState,
    findSaState,
} from '../../api/ApiSa';
import { findAllStates } from '../../api/ApiState';
import { findAllPermissions } from '../../api/ApiPermission';

// Utils
import orderByObjectKey from '../../utils/orderByObjectKey';

const theme = createTheme({
    palette: {
        primary: { main: lightGreen[300] },
        secondary: { main: red[600] },
    },
});

const useStyles = makeStyles({
    table: {
        marginTop: '15%',
        maxHeight: '30%',
        border: '.2px solid #ededed',
        width: '80%',
        margin: '2rem auto',
        fontSize: 20,
        transform: 'scale(1.1)',
    },
    container: {
        maxHeight: '675px',
    },
    editIcon: {
        cursor: 'pointer',
        maxWidth: '20px',
        marginLeft: '10px',
        transform: 'translate(0px, 4px)',
    },
    assignmentIcon: {
        cursor: 'pointer',
        maxWidth: '20px',

        transform: 'translate(0px, 4px)',
    },
    paperModal: {
        overflowY: 'scroll',
        marginTop: '5%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        maxHeight: 600,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        padding: '2rem 0',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    textControl: {
        marginLeft: '10px',
        width: '70%',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    titleEdit: {
        fontSize: '24px',
        fontWeight: 900,
        padding: '20px 10px 0px 10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    formControl: {
        alignItems: 'center',
        margin: '0 auto',
        width: '80%',
        marginTop: '1rem',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    selectControl: {
        width: '80%',
        marginTop: '1rem',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    buttonModal: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        textTransform: 'inherit',
        float: 'center',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4rem',
        marginTop: '2.5rem',
        fontSize: 15,
        transform: 'translate(-1rem, -12px) scale(0.9)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    buttonCreate: {
        width: '10rem',
        background: '#e0645e',
        height: '55px',
        textTransform: 'inherit',
        float: 'right',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '4rem',
        marginTop: '2.5rem',
        marginRight: '4rem',
        fontSize: 15,
        transform: 'translate(-1rem, -12px) scale(0.9)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    listItem: {
        marginTop: -10,
    },
});

export default function StatesData() {
    const { auth } = useContext(authContext);

    const [saList, setSaList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);
    const [saStateList, setSaStatesList] = useState([]);
    const [currentSaPermission, setCurrentSaPermission] = useState('');
    const [currentSaId, setCurrentSaId] = useState(null);
    const [currentSaName, setCurrentSaName] = useState('');
    const [currentSaState, setCurrentSaState] = useState('');

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openPermissions, setOpenPermissions] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);

    const handleOpenEdit = (id, name) => {
        setCurrentSaId(id);
        setCurrentSaName(name);
        setOpenEdit(true);
    };

    const handleOpenDelete = (id, name) => {
        setCurrentSaId(id);
        setCurrentSaName(name);
        setOpenDelete(true);
    };

    const handleOpenPermissions = (id, name) => {
        setCurrentSaId(id);
        setCurrentSaName(name);
        setOpenPermissions(true);
    };

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setCurrentSaState('');
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setCurrentSaState('');
    };

    const handleClosePermissions = () => {
        setOpenPermissions(false);
    };

    const handleCloseCreate = () => {
        setOpenCreate(false);
    };

    const handleEditState = async () => {
        try {
            const response = await setSaState(
                auth,
                currentSaId,
                currentSaState.stateId,
                currentSaPermission.permissionId
            );
            if (response.status !== 201) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Estado adicionado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Estado não pode ser adicionado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    const handleDeleteState = async () => {
        try {
            const response = await deleteSaState(
                auth,
                currentSaId,
                currentSaState.stateId
            );
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Estado deletado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Estado não pode ser deletado: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    useEffect(() => {
        const callApiFindAllStatesFromSas = async () => {
            const response = await findAllStatesFromSas(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedSa = orderByObjectKey(payload, 'name');

            setSaList(orderedSa);
        };

        try {
            callApiFindAllStatesFromSas();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callApiFindSaStates = async () => {
            const response = await findSaState(auth, currentSaId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedSaStates = orderByObjectKey(payload, 'uf');

            setSaStatesList(orderedSaStates);
        };

        try {
            if (currentSaId) callApiFindSaStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth, currentSaId]);

    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStates(auth);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);
        };
        try {
            callApiFindAllStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callApiFindAllPermissions = async () => {
            const response = await findAllPermissions(auth);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setPermissionsList(payload);
            setCurrentSaPermission(
                payload.find((element) => element.permissionName === 'RTV')
            );
        };
        try {
            callApiFindAllPermissions();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    const classes = useStyles();

    const defaultPropsState = {
        value: currentSaState,
        getOptionLabel: (option) => {
            return option.uf || '';
        },
        getOptionSelected: (option) => {
            return option.uf || '';
        },
        onChange: (event, newValue) => {
            setCurrentSaState(newValue);
        },
    };

    const defaultPropsPermissions = {
        options: [...permissionsList],
        value: currentSaPermission,
        getOptionLabel: (option) => {
            return option.permissionName || '';
        },
        getOptionSelected: (option) => {
            return option.permissionName || '';
        },
        onChange: (event, newValue) => {
            setCurrentSaPermission(newValue);
        },
    };

    const defaultPropsSas = {
        options: [...saList],
        getOptionLabel: (option) => {
            return option.saId ? option.saId && `${option.name}` : '';
        },
        getOptionSelected: (option) => {
            return option.saId ? option.saId && `${option.name}` : '';
        },
        onChange: (event, newValue) => {
            setCurrentSaId(newValue?.saId);
        },
    };

    const editBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Associar Estado ao RTV
            </h2>
            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    id="new-state"
                    className={classes.textControl}
                    label="Nome do RTV"
                    margin="normal"
                    type="text"
                    value={currentSaName}
                    disabled
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    {...defaultPropsPermissions}
                    id="input-permission"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Permissões"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    options={currentSaId ? stateList : []}
                    {...defaultPropsState}
                    id="input-AS"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Estados"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleEditState}
                >
                    Adicionar
                </Button>
            </ThemeProvider>
        </div>
    );

    const deleteBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Desassociar Estado do RTV
            </h2>
            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    id="new-state"
                    className={classes.textControl}
                    label="Nome do RTV"
                    margin="normal"
                    type="text"
                    value={currentSaName}
                    disabled
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    options={
                        currentSaId
                            ? stateList.filter((state) => {
                                  const sa = saList.find(
                                      (element) => element.saId === currentSaId
                                  );
                                  if (!sa.states) return false;
                                  return sa.states
                                      .map((state) => state.uf)
                                      .includes(state.uf);
                              })
                            : []
                    }
                    {...defaultPropsState}
                    id="input-AS"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Estados"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleDeleteState}
                >
                    Deletar
                </Button>
            </ThemeProvider>
        </div>
    );

    const permissionsBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                {currentSaName}
            </h2>
            <TableContainer className={classes.container}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Estados</TableCell>
                            <TableCell align="center">Permissões</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {saStateList.map((state) => (
                            <TableRow key={state.stateId}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {state.uf}
                                </TableCell>
                                <TableCell align="center">
                                    {state.permissionName}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

    const createBody = (
        <div className={classes.paperModal}>
            <h2 id="simple-modal-title" className={classes.titleEdit}>
                Associar Estado ao RTV
            </h2>
            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    {...defaultPropsSas}
                    id="input-sa"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Representante Técnico de Vendas"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    {...defaultPropsPermissions}
                    id="input-permission"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Permissões"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    options={stateList}
                    {...defaultPropsState}
                    id="input-AS"
                    autoSelect
                    className={classes.selectControl}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Estados"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button
                    variant="contained"
                    color={'secondary'}
                    className={classes.buttonModal}
                    onClick={handleEditState}
                >
                    Adicionar
                </Button>
            </ThemeProvider>
        </div>
    );

    return (
        <>
            <Container>
                <Title id="tableTitle" component="div">
                    {'Menu de Estados'}
                </Title>
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color={'secondary'}
                        onClick={handleOpenCreate}
                        className={classes.buttonCreate}
                    >
                        Edição Geral
                    </Button>
                </ThemeProvider>
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    Representante Técnico de Vendas
                                </TableCell>
                                <TableCell align="center">Estados</TableCell>
                                <TableCell align="center">Permissões</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {saList.map((sa) => (
                                <TableRow key={sa.saId}>
                                    <TableCell component="th" scope="row">
                                        {sa.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {sa.states
                                            ? sa.states.map((state) => (
                                                  <span key={state.stateId}>
                                                      {state.uf}{' '}
                                                  </span>
                                              ))
                                            : 'Não associado'}
                                    </TableCell>
                                    <TableCell align="center">
                                        <AssignmentIcon
                                            className={classes.assignmentIcon}
                                            onClick={() =>
                                                handleOpenPermissions(
                                                    sa.saId,
                                                    sa.name
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {sa.status}
                                    </TableCell>
                                    <TableCell align="right">
                                        <EditIcon
                                            className={classes.editIcon}
                                            onClick={() =>
                                                handleOpenEdit(sa.saId, sa.name)
                                            }
                                        />
                                        <DeleteForeverIcon
                                            className={classes.editIcon}
                                            onClick={() =>
                                                handleOpenDelete(
                                                    sa.saId,
                                                    sa.name
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {popup ? (
                    <PopUp
                        title={popupTitle}
                        string={popupText}
                        success={success}
                    />
                ) : null}
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {editBody}
                </Modal>
                <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {deleteBody}
                </Modal>
                <Modal
                    open={openPermissions}
                    onClose={handleClosePermissions}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {permissionsBody}
                </Modal>
                <Modal
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {createBody}
                </Modal>
            </Container>
        </>
    );
}
