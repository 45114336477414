import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 4.8rem auto 30px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    max-width: 80vw;

    @media screen and (max-width: 700px) {
        margin-top: 100px;
        margin-bottom: 50px;
        max-width: 94vw;
        padding-left: 0px;
    }
`;

export const Title = styled.div`
    grid-area: title;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    font-size: 22px;
    text-align: left;
    padding: 10px 10px 10px 18px;
    font-weight: 500;
    color: #2b2b2b;
    font-family: 'Roboto';
    @media screen and (max-width: 700px) {
        text-align: center;
    }
`;

export const Acumulator = styled.div`
    margin: 4.8rem auto 30px auto;
    display: grid;
    grid-template-areas: 'Title Table';
    grid-template-columns: 1fr 4fr;
    height: 20vh;
    margin: 0px auto;
    background-color: #fff;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px 10px 0 0;
    overflow: auto;
    max-width: 80vw;

    @media screen and (max-width: 920px) {
        grid-template-areas:
            'Title Title'
            'Table Table';
        grid-template-columns: 1fr 4fr;
    }
`;

export const AcumulatorTitle = styled.div`
    display: grid;
    grid-area: Title;
    font-size: 22px;
    text-align: center;
    align-self: center;
    font-weight: 500;
    color: #2b2b2b;
    padding: 0 24px;

    font-family: 'Roboto';
    @media screen and (max-width: 920px) {
        border-bottom: 1px solid #e0e0e0;
        padding: 24px;
    }
`;

export const AcumulatorTable = styled.div`
    display: grid;
    grid-area: Table;
    border-left: 1px solid #e0e0e0;
    @media screen and (max-width: 920px) {
        border-left: 0px solid #e0e0e0;
    }
`;

export const ContainerFilters = styled.div`
    display: grid;
    grid-template-columns: 3fr 0.5fr;
    @media screen and (max-width: 440px) {
        grid-template-columns: 1fr;
    }
`;

export const SearchButton = styled.button`
    display: grid;
    cursor: pointer;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    height: 50px;
    width: 60px;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-content: center;
    @media screen and (max-width: 440px) {
        margin: 12px;
    }
`;
