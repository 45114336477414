// PDF Imports
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from '@react-pdf/renderer';

// Fonts and Assets Imports
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf';
import RobotoBold from './assets/fonts/Roboto-Bold.ttf';
import RobotoLight from './assets/fonts/Roboto-Light.ttf';
import RobotoMedium from './assets/fonts/Roboto-Light.ttf';
import Header from './assets/drakkar-header.png';
import Footer from './assets/drakkar-footer.png';
import Description from './assets/drakkar-description-information.png';
import PaymentHeader from './assets/drakkar-payment-header.png';
import PaymentSubheader from './assets/drakkar-payment-subheader.png';
import PaymentSubheaderInCash from './assets/drakkar-payment-subheader-incash.png';
import { format } from 'date-fns';

// Font Registers
Font.register({
    family: 'Roboto Regular',
    format: 'truetype',
    src: RobotoRegular,
});
Font.register({
    family: 'Roboto Bold',
    format: 'truetype',
    src: RobotoBold,
});
Font.register({
    family: 'Roboto Medium',
    format: 'truetype',
    src: RobotoMedium,
});
Font.register({
    family: 'Roboto Light',
    format: 'truetype',
    src: RobotoLight,
});

const styles = StyleSheet.create({
    page: {
        fontSize: 10.5,
        fontFamily: 'Roboto Light',
    },
    tableContainer: {
        width: '97%',
        flexDirection: 'row',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        alignSelf: 'center',
        fontFamily: 'Roboto Light',
        transform: 'scale(0.7) !important',
    },
    textObservations: {
        width: '80%',
        fontSize: 10,
        justifyContent: 'left',
        alignSelf: 'left',
        fontWeight: 'bolder',
        padding: '2 5',
        fontFamily: 'Roboto Light',
        margin: '5px auto',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        alignSelf: 'center',
        fontFamily: 'Roboto Light',
    },
    headerDescription: {
        width: '150rem',
        fontSize: 14,
        padding: 5,
        fontFamily: 'Roboto Bold',
        margin: '5rem auto',
        left: 50,
    },
    headerValue: {
        width: '40%',
        fontSize: 14,
        fontWeight: 'normal',
        fontFamily: 'Roboto Bold',
        right: 40,
        textAlign: 'center',
        justifyItems: 'center',
    },
    description: {
        width: '55%',
        fontSize: 9,
        alignSelf: 'center',
        padding: '2 4',
        fontFamily: 'Roboto Light',
        margin: '5px auto',
        left: 50,
    },
    secondDescription: {
        width: '50%',
        fontSize: 10,
        alignSelf: 'center',
        fontWeight: 'bolder',
        padding: '2 5',
        fontFamily: 'Roboto Regular',
        margin: '5px auto',
        left: '140px',
    },

    secondDescriptionInitialValue: {
        width: '55%',
        fontSize: 10,
        alignSelf: 'center',
        fontWeight: 'bolder',
        padding: 5,
        fontFamily: 'Roboto Regular',
        margin: '5px auto',
        left: '155px',
    },

    secondDescriptionProfileMap: {
        width: '55%',
        fontSize: 12,
        alignSelf: 'center',
        fontWeight: 'bolder',
        padding: 5,
        fontFamily: 'Roboto Regular',
        margin: '5px auto',
        left: '155px',
    },

    importValue: {
        width: '80%',
        fontFamily: 'Roboto Medium',
        fontWeight: 'bold',
        alignSelf: 'center',
        letterSpacing: '0.8',
        textAlign: 'center',
        justifyItems: 'center',
    },

    importLastValue: {
        width: '80%',
        fontFamily: 'Roboto Medium',
        fontWeight: 'bold',
        alignSelf: 'center',
        letterSpacing: '0.8',
        textAlign: 'center',
        justifyItems: 'center',
        left: '20px',
    },
    positiveValue: {
        width: '40%',
        color: '#8C2F22',
        fontWeight: 'normal',
        textAlign: 'center',
        justifyItems: 'center',
    },
    negativeValue: {
        width: '40%',
        color: '#107000',
        fontWeight: 'normal',

        textAlign: 'center',
        justifyItems: 'center',
    },
    neutralValue: {
        width: '40%',
        color: '#000',
        fontWeight: 'normal',

        textAlign: 'center',
        justifyItems: 'center',
    },
    image: {
        marginBottom: '1vh',
        maxWidth: '100%',
    },

    imageDescription: {
        marginTop: '75px',
        marginBottom: '10vh',
        maxWidth: '90%',
        mixBlendMode: 'luminosity',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageFooter: {
        position: 'fixed',
        marginLeft: '160px',
        marginTop: '1vh',
        marginBottom: 0,
        width: '48%',
    },
    parcels: {
        marginTop: '10rem',
        width: '200%',
    },
    paymentHeader: {
        marginLeft: '-10px',
        maxWidth: '100%',
    },
    paymentSubheader: {
        paddingLeft: '200px',
        marginRight: 200,
        transform: 'scale(1.2) translate(0,-6rem)',
        justifyContent: 'center',
    },
    paymentOptions: {
        justifyContent: 'center',
        borderColor: '#e0e0e0',
        margin: '5px auto',
        marginRight: 175,
        textAlign: 'center',
        alignSelf: 'center',
        fontFamily: 'Roboto Medium',
    },
    paymentDescription: {
        fontSize: 15,
        fontFamily: 'Roboto Light',
        margin: '5px auto',
    },
    proposalInfos: {
        textAlign: 'left',
        fontSize: 17,
        padding: 5,
        fontWeight: 'bold',
        fontFamily: 'Roboto Bold',
    },
    signatureContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingTop: 70,
    },
    nameContainer: {
        display: 'grid',
        flexDirection: 'row',
        paddingTop: 10,
        paddingRight: 300,
    },
    dateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: 350,
        paddingRight: 30,
    },
    line: {
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width: 190,
    },
    occupation: {
        display: 'block',
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Roboto Medium',
        textAlign: 'center',
    },
    limitProposal: {
        position: 'absolute',
        transform: 'translate(-50%,100%)',
        right: '70%',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'right',
    },
    limitProposal2: {
        position: 'absolute',
        transform: 'translate(-50%,800%)',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'right',
    },
    limitProposal3: {
        position: 'absolute',
        transform: 'translate(-50%,810%)',
        right: '70%',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'right',
    },
    discount: {
        position: 'absolute',
        transform: 'translate(-50%, 10%)',
        left: '10%',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'left',
        color: 'green',
    },
    pageCounter: {
        position: 'absolute',
        transform: 'translate(0,100%)',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'center',
    },
    pageCounter2: {
        position: 'absolute',
        transform: 'translate(0,800%)',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'center',
    },
    pageCounter3: {
        position: 'absolute',
        transform: 'translate(0,810%)',
        fontSize: 9.5,
        fontWeight: 400,
        fontFamily: 'Roboto Medium',
        textAlign: 'center',
    },
    rowDad: {
        marginTop: '10px',
    },
    tableContainerDad: {
        marginTop: '3px',
    },
});

const additionalValue = (proposal) => {
    let sumAddValue = 0;
    Object.values(proposal.parcels).map((curr) =>
        curr.additionalValue ? (sumAddValue += curr.additionalValue) : 0
    );
    return sumAddValue;
};

const IncreaseValue = ({ value, areaMade, grid }) => {
    return (
        <Text style={styles.positiveValue}>
            {`+ ${(areaMade * grid * (value / 100)).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}`}
        </Text>
    );
};

const DiscountValue = ({ value, areaMade, grid }) => {
    return value === 0 ? (
        <Text style={styles.neutralValue}>
            {value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}
        </Text>
    ) : value === null ? (
        <Text style={styles.neutralValue}>{'---'}</Text>
    ) : (
        <Text style={styles.negativeValue}>
            {(areaMade * grid * (value / 100)).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}
        </Text>
    );
};
const WritePercentage = ({
    percentage,
    arrNames,
    paymentFields,
    proposalStatePrice,
    proposal,
    isProfileMap = false,
}) => {
    return (
        percentage &&
        Object.entries(percentage).map((item, index) => {
            if (item[0] === 'inCash') return null;
            return (
                <View style={styles.row} key={item[0]}>
                    <Text style={styles.description}>
                        {arrNames
                            ? paymentFields
                                ? `${arrNames[index]}: ${
                                      paymentFields[index - 1]
                                  }`
                                : arrNames[index]
                            : percentage
                            ? paymentFields
                                ? `${item[0]}: ${paymentFields[index - 1]}`
                                : isProfileMap
                                ? 'Mapa de Perfil: ' + item[0]
                                : item[0]
                            : Object.keys(percentage)[index]}
                    </Text>
                    {item[1] > 0 ? (
                        <IncreaseValue
                            value={item[1]}
                            areaMade={proposal.haMade}
                            grid={proposalStatePrice.grid[proposal.grid]}
                        />
                    ) : (
                        <DiscountValue
                            value={item[1]}
                            areaMade={proposal.haMade}
                            grid={proposalStatePrice.grid[proposal.grid]}
                        />
                    )}
                </View>
            );
        })
    );
};

const UpdateProposalPdf = ({
    proposal,
    client,
    proposalStatePrice,
    saName,
}) => {
    const VALUE_PER_HECTARE = proposal?.finalAmount / proposal?.haMade;

    const VALUE_PER_HECTARE_IN_BAGS = VALUE_PER_HECTARE / proposal?.soyValue;

    let fertilizer1 = null;
    let fertilizer2 = null;

    if (proposal && Object.keys(proposal.fertilizers).length !== 0) {
        const names = Object.keys(proposal.fertilizers);
        if (proposal?.fertilizers[names[0]]) {
            fertilizer1 = {
                name: names[0],
                baseValue: proposal.fertilizers[names[0]],
            };
        }
        if (proposal?.fertilizers[names[1]]) {
            fertilizer2 = {
                name: names[1],
                baseValue: proposal.fertilizers[names[1]],
            };
        }
    }

    const today = new Date(proposal.createdAt);
    const limitDate = new Date().setDate(today.getDate() + 15);

    const initialValueDiscount =
        proposal.isTotalArea === 'Sim'
            ? proposalStatePrice?.areaPercentage['100%'] +
              Math.floor(client.ha / proposalStatePrice?.areaMade.ha) *
                  proposalStatePrice?.areaMade.discountPerHa
            : Math.floor(proposal?.haMade / proposalStatePrice?.areaMade.ha) *
              proposalStatePrice?.areaMade.discountPerHa;

    return (
        <>
            {proposal && client && proposalStatePrice && (
                <Document>
                    <Page size="A4" style={styles.page} wrap>
                        <Image src={Header} style={styles.image} />
                        <View
                            style={{
                                flexDirection: 'row-reverse',
                            }}
                        >
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    fontFamily: 'Roboto Medium',
                                    fontSize: 15,
                                    color: 'red',
                                    paddingRight: 35,
                                }}
                            >
                                {proposal.proposalId}
                            </Text>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    paddingLeft: 60,
                                    fontSize: 15,
                                }}
                            >
                                Nº:
                            </Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row-reverse',
                            }}
                        >
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    fontFamily: 'Roboto Medium',
                                    fontSize: 15,
                                    paddingRight: 35,
                                }}
                            >
                                {new Date(proposal.createdAt).getFullYear()}
                            </Text>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    paddingLeft: 60,
                                    fontSize: 15,
                                }}
                            >
                                Ano:
                            </Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    paddingLeft: 60,
                                }}
                            >
                                Cliente:
                            </Text>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    fontFamily: 'Roboto Medium',
                                    paddingRight: 50,
                                }}
                            >
                                {client.name}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    paddingLeft: 60,
                                }}
                            >
                                Representante Técnico de Vendas:
                            </Text>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    fontFamily: 'Roboto Medium',
                                    paddingRight: 50,
                                }}
                            >
                                {saName}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    paddingLeft: 60,
                                }}
                            >
                                Criada por:
                            </Text>
                            <Text
                                style={{
                                    ...styles.proposalInfos,
                                    fontFamily: 'Roboto Medium',
                                    paddingRight: 50,
                                }}
                            >
                                {proposal.creatorname}
                            </Text>
                        </View>
                        <Image
                            src={Description}
                            style={styles.imageDescription}
                        />

                        <Image src={Footer} style={styles.imageFooter} />

                        <View>
                            <Text
                                style={styles.pageCounter}
                                render={({ pageNumber, totalPages }) =>
                                    `${pageNumber} / ${totalPages}`
                                }
                                fixed
                            />
                        </View>
                        <View>
                            <Text style={styles.limitProposal}>
                                Essa proposta vencerá dia{' '}
                                {new Date(limitDate).toLocaleDateString(
                                    'pt-br'
                                )}
                                .
                            </Text>
                        </View>
                    </Page>
                    <Page size="A4" style={styles.page} wrap={false}>
                        <View style={styles.tableContainerDad}>
                            <View style={styles.tableContainer}>
                                <Text
                                    style={styles.secondDescriptionInitialValue}
                                >
                                    {'Valor Inicial: Grid x Ha'}
                                </Text>
                                <Text style={styles.importValue}>
                                    {(
                                        proposalStatePrice.grid[proposal.grid] *
                                            proposal.haMade +
                                        additionalValue(proposal) +
                                        (proposal.finalAmount -
                                            proposal.initialAmount)
                                    ).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Desconto de área total'}
                            </Text>
                            {initialValueDiscount > 0 ? (
                                <IncreaseValue
                                    value={initialValueDiscount}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={initialValueDiscount}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Laboratório'}
                            </Text>
                            {proposal.specificLabPrice > 0 ? (
                                <IncreaseValue
                                    value={proposal.specificLabPrice}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={proposal.specificLabPrice}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>{'Ciclo'}</Text>
                            {proposalStatePrice.cycle[proposal.cycle] > 0 ? (
                                <IncreaseValue
                                    value={
                                        proposalStatePrice.cycle[proposal.cycle]
                                    }
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={
                                        proposalStatePrice.cycle[proposal.cycle]
                                    }
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Monitoramento'}
                            </Text>
                            {proposal.monitoring === 'Sim' ? (
                                <IncreaseValue
                                    value={proposalStatePrice.monitoring}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={null}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Acompanhamento'}
                            </Text>
                            {proposal.noAccompaniment === 'Sim' ? (
                                <DiscountValue
                                    value={null}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={proposalStatePrice.noAccompaniment}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Mapa de Colheita'}
                            </Text>
                            {proposal.noHarvestMap === 'Sim' ? (
                                <DiscountValue
                                    value={null}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            ) : (
                                <DiscountValue
                                    value={proposalStatePrice.noHarvestMap}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <View style={styles.row}>
                            <Text style={styles.description}>
                                {'Sazonalidade'}
                            </Text>
                            {proposal.seasonality ? (
                                proposalStatePrice.seasonality[
                                    proposal.seasonality
                                ] > 0 ? (
                                    <IncreaseValue
                                        value={
                                            proposalStatePrice.seasonality[
                                                proposal.seasonality
                                            ]
                                        }
                                        areaMade={proposal.haMade}
                                        grid={
                                            proposalStatePrice.grid[
                                                proposal.grid
                                            ]
                                        }
                                    />
                                ) : (
                                    <DiscountValue
                                        value={
                                            proposalStatePrice.seasonality[
                                                proposal.seasonality
                                            ]
                                        }
                                        areaMade={proposal.haMade}
                                        grid={
                                            proposalStatePrice.grid[
                                                proposal.grid
                                            ]
                                        }
                                    />
                                )
                            ) : (
                                <DiscountValue
                                    value={null}
                                    areaMade={proposal.haMade}
                                    grid={
                                        proposalStatePrice.grid[proposal.grid]
                                    }
                                />
                            )}
                        </View>

                        <WritePercentage
                            percentage={proposal.profileMap}
                            proposalStatePrice={proposalStatePrice}
                            proposal={proposal}
                            isProfileMap={true}
                        />

                        <WritePercentage
                            percentage={proposal.services}
                            proposalStatePrice={proposalStatePrice}
                            proposal={proposal}
                        />

                        <View style={styles.row}>
                            <Text style={styles.description}>{'Data de Coleta Prevista'}</Text>    
                            <Text style={[styles.neutralValue]}>
                                {format(new Date(proposal.expectedCollectDate), 'dd/MM/yyyy')}
                            </Text>
                        </View>
                            
                        <View style={styles.row}>
                            <Text style={styles.description}>{'Juros Residual'}</Text>
                            {proposal.interestSum === 0 ? (                
                                <Text style={[styles.positiveValue]}>
                                    {`+ ${proposal.interestSum.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}`}
                                </Text>
                            ): (
                                <Text style={styles.neutralValue}>{'R$ 0,00'}</Text>
                            )}
                        </View>
 
                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>{'Ha'}</Text>
                            <Text style={styles.importLastValue}>
                                {proposal.haMade.toLocaleString()}
                            </Text>
                        </View>

                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>{'Grid'}</Text>
                            <Text style={styles.importLastValue}>
                                {proposal.grid.toLocaleString()}
                            </Text>
                        </View>

                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>
                                {'Valor Final'}
                            </Text>
                            <Text style={styles.importLastValue}>
                                {Number(proposal.finalAmount).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }
                                )}
                                {proposal.parcels.hasOwnProperty('À vista') && (
                                    <Text style={{ color: 'green' }}>*</Text>
                                )}
                            </Text>
                        </View>
                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>
                                {'Valor Final/ha'}
                            </Text>
                            <Text style={styles.importLastValue}>
                                {(VALUE_PER_HECTARE
                                    ? VALUE_PER_HECTARE
                                    : 0
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </Text>
                        </View>
                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>
                                {'Valor Final/ha em sacas de Soja'}
                            </Text>
                            <Text style={styles.importLastValue}>
                                {(VALUE_PER_HECTARE_IN_BAGS
                                    ? VALUE_PER_HECTARE_IN_BAGS
                                    : 0
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </Text>
                        </View>
                        <View style={styles.tableContainer}>
                            <Text style={styles.secondDescription}>
                                {
                                    'Valor Final/ha em sacas de Soja diluído em 3 anos'
                                }
                            </Text>
                            <Text style={styles.importLastValue}>
                                {(VALUE_PER_HECTARE_IN_BAGS
                                    ? VALUE_PER_HECTARE_IN_BAGS / 3
                                    : 0
                                ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </Text>
                        </View>
                        {fertilizer1 && (
                            <View style={styles.tableContainer}>
                                <Text style={styles.secondDescription}>
                                    {fertilizer1.name + '/ha'}
                                </Text>
                                <Text style={styles.importLastValue}>
                                    {(
                                        VALUE_PER_HECTARE /
                                        fertilizer1.baseValue
                                    ).toFixed(2)}
                                </Text>
                            </View>
                        )}
                        {fertilizer2 && (
                            <View style={styles.tableContainer}>
                                <Text style={styles.secondDescription}>
                                    {fertilizer2.name + '/ha'}
                                </Text>
                                <Text style={styles.importLastValue}>
                                    {(
                                        VALUE_PER_HECTARE /
                                        fertilizer2.baseValue
                                    ).toFixed(2)}
                                </Text>
                            </View>
                        )}
                        {proposal.observations && (
                            <View style={styles.tableContainer}>
                                <Text style={styles.textObservations}>
                                    {proposal.observations}
                                </Text>
                            </View>
                        )}
                        {proposal.parcels.hasOwnProperty('À vista') && (
                            <View>
                                <Text style={styles.discount}>
                                    *5% de desconto à vista
                                </Text>
                            </View>
                        )}
                    </Page>
                    <Page size="A4" style={styles.page} wrap>
                        <View>
                            <Text
                                style={styles.pageCounter3}
                                render={({ pageNumber, totalPages }) =>
                                    `${pageNumber} / ${totalPages}`
                                }
                                fixed
                            />
                        </View>
                        <View>
                            <Text style={styles.limitProposal3}>
                                Essa proposta vencerá dia{' '}
                                {new Date(limitDate).toLocaleDateString(
                                    'pt-br'
                                )}
                                .
                            </Text>
                        </View>

                        <Image
                            src={PaymentHeader}
                            style={styles.paymentHeader}
                        />
                        {proposal.parcels.hasOwnProperty('À vista') ? (
                            <Image
                                src={PaymentSubheaderInCash}
                                style={styles.paymentSubheader}
                            />
                        ) : (
                            <Image
                                src={PaymentSubheader}
                                style={styles.paymentSubheader}
                            />
                        )}
                        <View style={styles.paymentOptions}>
                            {Object.values(proposal.parcels).map(
                                (curr, index) => (
                                    <Text
                                        key={index}
                                        style={styles.paymentDescription}
                                    >
                                        {`${index + 1}ª - ${Number(
                                            curr.value
                                        ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}   -   ${new Date(
                                            curr.dueDate
                                        ).toLocaleDateString()}`}
                                    </Text>
                                )
                            )}
                        </View>

                        <View style={styles.dateContainer}>
                            <View style={{ ...styles.line, width: 50 }} />
                            <Text style={styles.signatureText}>de</Text>
                            <View style={{ ...styles.line, width: 90 }} />
                            <Text style={styles.signatureText}>de</Text>
                            <View style={{ ...styles.line, width: 50 }} />
                            <Text style={styles.signatureText}>.</Text>
                        </View>

                        <View style={styles.signatureContainer}>
                            <View style={styles.line} />
                            <View style={styles.line} />
                        </View>

                        <View style={styles.nameContainer}>
                            <Text style={styles.occupation}>
                                Representante Técnico de Vendas
                            </Text>
                            <Text
                                style={{
                                    ...styles.occupation,
                                    paddingLeft: 330,
                                }}
                            >
                                Cliente
                            </Text>
                        </View>
                        <View style={styles.nameContainer}>
                            <Text style={styles.occupation}>
                                Drakkar Solos Consultoria LTDA
                            </Text>
                        </View>
                    </Page>
                </Document>
            )}
        </>
    );
};

export default UpdateProposalPdf;
