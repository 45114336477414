import { useEffect } from 'react';

import { DrawerAdjustment } from './goals.styles';

/* Components */
import Header from '../../components/header/header.component';
import GoalsData from '../../components/goals-data/goals-data.component';

export default function Goals() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <GoalsData />
        </DrawerAdjustment>
    );
}
