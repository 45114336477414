import { apiUrl } from './Api';
import Axios from 'axios';

export async function findAllFertilizer(auth, stateId) {
    try {
        return await Axios.get(`${apiUrl}/states/${stateId}/fertilizers`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function createFertilizer(auth, stateId, name, value) {
    try {
        return await Axios.post(
            `${apiUrl}/states/${stateId}/fertilizers`,
            {
                name,
                baseValue: value,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}
export async function editFertilizer(auth, stateId, name, value) {
    try {
        return await Axios.put(
            `${apiUrl}/states/${stateId}/fertilizers/editFertilizer`,
            {
                name,
                baseValue: value,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function deleteFertilizer(auth, stateId, name) {
    try {
        return await Axios.delete(
            `${apiUrl}/states/${stateId}/fertilizers/?name=${name}`,
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}
