import React, { useContext, useState, useEffect } from 'react';

import { EachRow, EachElement } from './grid-values.styles';

/* Material UI */
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

/* Context */
import { statePriceContext } from '../../contexts/StatePriceContext';
import { clientContext } from '../../contexts/ClientContext';

export default function GridValues({ proposalData }) {
    const { statePrice } = useContext(statePriceContext);
    const { setClient, setSaveOptions } = useContext(clientContext);
    const [radio, setRadio] = useState('grid 1 ha');

    useEffect(() => {
        if (proposalData) {
            setRadio(proposalData.grid);
        }
    }, [proposalData]);

    useEffect(() => {
        if (statePrice) {
            setClient((client) => {
                return { ...client, grid: statePrice.grid[radio] };
            });
            setSaveOptions((options) => ({
                ...options,
                grid: radio,
            }));
        }
    }, [radio, statePrice, setClient, setSaveOptions]);

    const orderedGrids =
        statePrice &&
        Object.entries(statePrice?.grid)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    return (
        <FormControl component="fieldset">
            <EachRow>
                <EachElement>
                    <Typography variant="h6">Grid</Typography>
                </EachElement>
                <EachElement>
                    <Typography variant="h6">Preço</Typography>
                </EachElement>
            </EachRow>
            <RadioGroup
                aria-label="grid"
                name="grid"
                value={radio}
                onChange={({ target }) => {
                    setRadio(target.value);
                }}
            >
                {statePrice &&
                    Object.entries(orderedGrids).map((grid, index) => (
                        <EachRow key={index}>
                            <EachElement>
                                <FormControlLabel
                                    value={grid[0]}
                                    control={
                                        <Radio
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={
                                                <CheckCircleIcon color="primary" />
                                            }
                                        />
                                    }
                                    label={`${grid[0].split(' ')[1]} ${
                                        grid[0].split(' ')[0]
                                    } (hectare)`}
                                />
                            </EachElement>
                            <EachElement>{`R$ ${grid[1]} /ha`}</EachElement>
                        </EachRow>
                    ))}
            </RadioGroup>
        </FormControl>
    );
}
