/* Styles */
import {
    Container,
    Column1,
    Column2,
    Column3,
    ContainerRow,
    InvisibleContainer,
    Title,
} from './specification.styles';

import YesNo from '../yes-no-button/yes-no-button.component';
import Price from '../price/price.component';
import { useContext, useState, useEffect } from 'react';
import DateSelector from '../date-selector/date-selector.component';
import Subtotal from '../subtotal/subtotal.component';

/* Material ui */
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ClearIcon from '@material-ui/icons/Clear';

/* Context */
import { statePriceContext } from '../../contexts/StatePriceContext';
import { authContext } from '../../contexts/AuthContext';
import { clientContext } from '../../contexts/ClientContext';

/* API */
import { findAllLaboratories } from '../../api/ApiLaboratory';
import CustomTextFieldValue from '../custom-text-field-value/custom-text-field-value.component';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '14rem',
        '@media (max-width: 970px)': {
            width: '10rem',
        },
        '@media (max-width: 759px)': {
            textAlign: 'center',
            justifyContent: 'center',
            margin: '2vh auto',
        },
    },
    formControlLab: {
        margin: theme.spacing(1),
        width: '14rem',
        '@media (max-width: 970px)': {
            width: '10rem',
            marginLeft: '1.6rem',
        },
        '@media (max-width: 759px)': {
            margin: 'auto',
            textAlign: 'center',
        },
    },
    columnTwoContent: {
        margin: '20px',
        '@media (max-width: 970px)': {
            margin: '0px',
            marginRight: 'auto',
            marginLeft: '2.2rem',
            justifyContent: 'start',
        },
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            marginLeft: 'auto',
        },
    },
    textControl: {
        width: '100%',
        paddingTop: '20px',
    },
    column3Title: {
        padding: 20,
        textAlign: 'center',
        '@media (max-width: 759px)': {
            display: 'none',
        },
    },
    soyNumber: {
        fontSize: 22,
        fontWeight: '600',
    },
    styleTitle: {
        padding: 20,
        '@media (max-width: 759px)': {},
    },
    subtotal: {
        padding: '40px 20px 20px 20px',
        color: '#424248',
        fontWeight: 300,
        '@media (max-width: 759px)': {
            padding: 'auto',
            paddingRight: -20,
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            marginBottom: '-2rem',
        },
    },
    subtotalPrice: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '28px',
        letterSpacing: '1.2px',
        fontWeight: '400px',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 0,
        '@media (max-width: 759px)': {
            transform: 'translateY(12px)',
            marginBottom: '2rem',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '-10px',
        },
        '@media (max-width: 360px)': {
            fontSize: '22px',
        },
    },
    tinyTextStyle: {
        paddingLeft: '10px',
        paddingBottom: '-10px',
        color: '#9E9E9E',
        fontWeight: 400,
        '@media (max-width: 970px)': {
            paddingLeft: '25px',
        },
        '@media (max-width: 759px)': {
            padding: 0,
        },
    },
    pricing: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 200,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 759px)': {
            marginTop: 35,
            marginBottom: 35,
            marginRight: 30,
            alignItems: 'end',
        },
    },
    typography: {
        display: 'grid',
        fontFamily: 'Roboto, sans-serif',
        paddingLeft: 10,
        letterSpacing: 0.8,
        fontWeight: 300,
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },
    urgency: {
        fontFamily: 'Roboto, sans-serif',
        display: 'grid',
        textAlign: 'center',
        color: '#5b5b5b',
        fontSize: '15px',
        width: '85%',
        margin: '3rem auto 3rem auto',
    },
}));

const checkYesNo = (option) => {
    return option === 'Sim' ? 1 : 0;
};

export default function Specification({ proposalData }) {
    const INVALID_DATES = 14;
    const { statePrice } = useContext(statePriceContext);
    const { auth } = useContext(authContext);
    const {
        client,
        setClient,
        setSpecificationPercentages,
        specificationSubPrice,
        setProfileMapPercentage,
        setSaveOptions,
    } = useContext(clientContext);

    // estados que guardam o valor selecionado de cada campo
    const [isTotalArea, setIsTotalArea] = useState(1);
    const [areaMade, setAreaMade] = useState(0);
    const [cycle, setCycle] = useState('1º ciclo');
    const [monitorament, setMonitorament] = useState(0);
    const [profileMap, setProfileMap] = useState('');
    const [accompaniment, setAccompaniment] = useState(1);
    const [harvestMap, setHarvestMap] = useState(1);
    const [urgency, setUrgency] = useState(0);
    const [seasonality, setSeasonality] = useState('Janeiro a Maio');
    const [specificLab, setSpecificLab] = useState(0);
    const [laboratory, setLaboratory] = useState(null);

    // estados que guardam as opções do input vindas do banco
    const [cycles, setCycles] = useState([]);
    const [profileMaps, setProfileMaps] = useState([]);
    const [seasonalities, setSeasonalities] = useState([]);
    const [laboratories, setLaboratories] = useState([]);

    const [selectedProfileMaps, setSelectedProfileMaps] = useState([]);

    // estados que guardam o preço de cada campo na tela
    const [areaMadePrice, setAreaMadePrice] = useState(0);
    const [cyclePrice, setCyclePrice] = useState(0);
    const [monitoramentPrice, setMonitoramentPrice] = useState(null);
    const [accompanimentPrice, setAccompanimentPrice] = useState(null);
    const [harvestMapPrice, setHarvestMapPrice] = useState(null);
    const [urgencyPrice, setUrgencyPrice] = useState(null);
    const [laboratoryPrice, setLaboratoryPrice] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        setAreaMade(client.ha);
        if (proposalData) {
            setIsTotalArea(proposalData?.isTotalArea === 'Sim' ? 1 : 0);
            setAreaMade(proposalData?.haMade);
            setCycle(proposalData?.cycle);
            setMonitorament(checkYesNo(proposalData?.monitoring));
            setAccompaniment(proposalData?.noAccompaniment === 'Sim' ? 1 : 0);
            setHarvestMap(proposalData?.noHarvestMap === 'Sim' ? 1 : 0);
            setUrgency(checkYesNo(proposalData?.urgency));
            setSeasonality(
                proposalData?.seasonality ? proposalData?.seasonality : null
            );
            setSpecificLab(proposalData?.laboratoryId ? 1 : 0);

            const lab = laboratories
                .map((lab) => {
                    if (lab.laboratoryId === proposalData?.laboratoryId)
                        lab.price = proposalData?.specificLabPrice;
                    return lab;
                })
                .filter(
                    (lab) => lab.laboratoryId === proposalData?.laboratoryId
                );
            setLaboratory(lab[0]);

            setSelectedProfileMaps(Object.keys(proposalData?.profileMap));

            Object.keys(proposalData?.profileMap).forEach((curr) => {
                setProfileMapPercentage((profileMapPercentage) => ({
                    ...profileMapPercentage,
                    [curr]: statePrice.profileMap[curr],
                }));
            });
        }
    }, [
        proposalData,
        setProfileMapPercentage,
        statePrice,
        setClient,
        laboratories,
        client.ha,
    ]);

    // coloca os ciclos no input
    useEffect(() => {
        if (statePrice) {
            setCycles(Object.keys(statePrice.cycle));
        }
    }, [statePrice]);

    //coloca os mapas de perfil no input
    useEffect(() => {
        if (statePrice) {
            setProfileMaps(Object.keys(statePrice.profileMap));
            setProfileMaps(
                profileMaps.sort((a, b) => {
                    if (
                        parseInt(a.split('-')[0]) !== parseInt(b.split('-')[0])
                    ) {
                        return (
                            parseInt(a.split('-')[0]) -
                            parseInt(b.split('-')[0])
                        );
                    } else {
                        return (
                            parseInt(a.split('(+')[1].split('%)')[0]) -
                            parseInt(b.split('(+')[1].split('%)')[0])
                        );
                    }
                })
            );
        }
    }, [profileMaps, statePrice]);

    // coloca as sazonalidades no input
    useEffect(() => {
        if (statePrice) {
            setSeasonalities(Object.keys(statePrice.seasonality));
        }
    }, [statePrice]);

    //coloca os laboratorios no input
    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllLaboratories(auth);
            const { message, payload } = response.data;
            if (response.status !== 200) throw Error(message);
            setLaboratories(payload);

            if (proposalData) {
                const lab = payload.filter((curr) => {
                    return curr.laboratoryId === proposalData.laboratoryId
                        ? true
                        : false;
                });
                if (lab.length > 1) setLaboratory(lab[0]);
            }
        };

        try {
            callApiFindAllStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth, proposalData]);

    useEffect(() => {
        if (isTotalArea) {
            setAreaMadePrice(
                statePrice?.areaPercentage['100%'] +
                    Math.floor(
                        (areaMade || proposalData?.haMade || client.ha) /
                            statePrice?.areaMade.ha
                    ) *
                        statePrice?.areaMade.discountPerHa <
                    -20
                    ? -20
                    : statePrice?.areaPercentage['100%'] +
                          Math.floor(
                              (areaMade || proposalData?.haMade || client.ha) /
                                  statePrice?.areaMade.ha
                          ) *
                              statePrice?.areaMade.discountPerHa
            );
            setSaveOptions((options) => ({
                ...options,
                isTotalArea: 1,
                areaMade: areaMade || proposalData?.haMade,
            }));
            setSpecificationPercentages((servicePercentage) => ({
                ...servicePercentage,
                totalAreaDiscount:
                    statePrice?.areaPercentage['100%'] +
                        Math.floor(
                            (areaMade || proposalData?.haMade || client.ha) /
                                statePrice?.areaMade.ha
                        ) *
                            statePrice?.areaMade.discountPerHa <
                    -20
                        ? -20
                        : statePrice?.areaPercentage['100%'] +
                          Math.floor(
                              (areaMade || proposalData?.haMade || client.ha) /
                                  statePrice?.areaMade.ha
                          ) *
                              statePrice?.areaMade.discountPerHa,
            }));
            setClient((oldClient) => ({
                ...oldClient,
                areaMade: Number(areaMade || proposalData?.haMade),
            }));
        } else {
            setAreaMadePrice(
                Math.floor(
                    (areaMade || proposalData?.haMade || client.ha) /
                        statePrice?.areaMade.ha
                ) *
                    statePrice?.areaMade.discountPerHa <
                    -10
                    ? -10
                    : Math.floor(
                          (areaMade || proposalData?.haMade || client.ha) /
                              statePrice?.areaMade?.ha
                      ) * statePrice?.areaMade?.discountPerHa
            );
            setSaveOptions((options) => ({
                ...options,
                isTotalArea: 0,
                areaMade: areaMade || proposalData?.haMade,
            }));
            setSpecificationPercentages((servicePercentage) => ({
                ...servicePercentage,
                totalAreaDiscount:
                    Math.floor(
                        (areaMade || proposalData?.haMade || client.ha) /
                            statePrice?.areaMade.ha
                    ) *
                        statePrice?.areaMade.discountPerHa <
                    -10
                        ? -10
                        : Math.floor(
                              (areaMade || proposalData?.haMade || client.ha) /
                                  statePrice?.areaMade.ha
                          ) * statePrice?.areaMade.discountPerHa,
            }));

            setClient((oldClient) => ({
                ...oldClient,
                areaMade: Number(areaMade || proposalData?.haMade),
            }));
        }
    }, [
        isTotalArea,
        areaMade,
        client.ha,
        statePrice,
        setSaveOptions,
        areaMadePrice,
        setSpecificationPercentages,
        proposalData,
        setClient,
    ]);

    useEffect(() => {
        if (statePrice) {
            setCyclePrice(statePrice.cycle[cycle]);
            setSpecificationPercentages((servicePercentage) => ({
                ...servicePercentage,
                cycle: statePrice.cycle[cycle],
            }));
            setSaveOptions((options) => ({
                ...options,
                cycle: cycle,
            }));
        }
    }, [cycle, setSpecificationPercentages, statePrice, setSaveOptions]);

    useEffect(() => {
        if (statePrice) {
            if (monitorament === 1) {
                setMonitoramentPrice(statePrice.monitoring);

                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    monitorament: statePrice.monitoring,
                }));
            } else {
                setMonitoramentPrice(null);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    monitorament: null,
                }));
            }
        }
    }, [monitorament, setSpecificationPercentages, statePrice]);

    useEffect(() => {
        if (statePrice) {
            if (accompaniment === 0) {
                setAccompanimentPrice(statePrice.noAccompaniment);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    accompaniment: statePrice.noAccompaniment,
                }));
            } else {
                setAccompanimentPrice(null);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    accompaniment: null,
                }));
            }
        }
    }, [accompaniment, setSpecificationPercentages, statePrice]);

    useEffect(() => {
        if (statePrice) {
            if (harvestMap === 0) {
                setHarvestMapPrice(statePrice.noHarvestMap);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    harvestMap: statePrice.noHarvestMap,
                }));
            } else {
                setHarvestMapPrice(null);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    harvestMap: null,
                }));
            }
        }
    }, [harvestMap, setSpecificationPercentages, statePrice]);

    useEffect(() => {
        if (statePrice) {
            if (urgency === 1) {
                setUrgencyPrice(statePrice.seasonality[seasonality]);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    seasonality: statePrice.seasonality[seasonality],
                }));
                setSaveOptions((options) => ({
                    ...options,
                    seasonality: seasonality,
                }));
            } else {
                setUrgencyPrice(null);
                setSpecificationPercentages((servicePercentage) => ({
                    ...servicePercentage,
                    seasonality: null,
                }));
                setSaveOptions((options) => ({
                    ...options,
                    seasonality: null,
                }));
            }
        }
    }, [
        seasonality,
        urgency,
        setSpecificationPercentages,
        statePrice,
        setSaveOptions,
    ]);

    useEffect(() => {
        if (specificLab === 1) {
            laboratories.forEach((curr) => {
                if (curr.laboratoryId === laboratory?.laboratoryId) {
                    setLaboratoryPrice(laboratory.price);
                    setSpecificationPercentages((servicePercentage) => ({
                        ...servicePercentage,
                        laboratory: laboratory.price,
                    }));
                    setSaveOptions((options) => ({
                        ...options,
                        laboratoryId: laboratory.laboratoryId,
                    }));
                }
            });
        } else {
            setSpecificationPercentages((servicePercentage) => ({
                ...servicePercentage,
                laboratory: null,
            }));
            setLaboratoryPrice(null);
            setSaveOptions((options) => ({
                ...options,
                laboratoryId: null,
            }));
        }
    }, [
        specificLab,
        laboratory,
        laboratories,
        setSpecificationPercentages,
        setSaveOptions,
    ]);

    const deleteProfileMapOption = (valueToDelete) => {
        setProfileMapPercentage((prevProfileMapPercentage) => {
          const newProfileMapPercentage = { ...prevProfileMapPercentage };
    
          delete newProfileMapPercentage[valueToDelete];
    
          return newProfileMapPercentage;
        });
    };

    const defaultPropsCycle = {
        options: cycles,
        value: cycle,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setCycle(newValue);
        },
    };

    const defaultPropsprofileMap = {
        options: profileMaps,
        value: profileMap,
        getOptionLabel: (option) => {
            return typeof option === 'object' ? option.toString() : option;
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        getOptionDisabled: (option) => {
            return !!selectedProfileMaps.find((curr) => curr === option);
        },
        onChange: (event, newValue) => {
            setProfileMap(newValue);
            if (!selectedProfileMaps.includes(newValue)) {
                setSelectedProfileMaps([...selectedProfileMaps, newValue]);
                setProfileMapPercentage((profileMapPercentage) => ({
                    ...profileMapPercentage,
                    [newValue]: statePrice.profileMap[newValue],
                }));
            }
        },
        onBlur: (event) => {
            if (
                event.target.value === profileMap &&
                event.target.value !== '' &&
                !selectedProfileMaps.includes(event.target.value)
            ) {
                setProfileMap(event.target.value);
                setSelectedProfileMaps([
                    ...selectedProfileMaps,
                    event.target.value,
                ]);
                setProfileMapPercentage((profileMapPercentage) => ({
                    ...profileMapPercentage,

                    [event.target.value]:
                        statePrice.profileMap[event.target.value],
                }));
            }
        },
    };

    useEffect(() => {
        if (statePrice && !proposalData) {
            const firstMap = Object.entries(statePrice.profileMap)[0];

            setProfileMaps(Object.keys(statePrice.profileMap));
            setSelectedProfileMaps([firstMap[0]]);

            setProfileMapPercentage((profileMapPercentage) => ({
                ...profileMapPercentage,
                [firstMap[0]]: firstMap[1],
            }));
        }
    }, [statePrice, setProfileMapPercentage, proposalData]);

    const defaultPropsSeasonality = {
        options: seasonalities,
        value: seasonality,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setSeasonality(newValue);
        },
    };

    const defaultPropsLab = {
        options: laboratories,
        value: laboratory,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setLaboratory(newValue);
        },
    };
    return (
        <>
            <InvisibleContainer id="specification-container"></InvisibleContainer>
            <Container>
                <ContainerRow>
                    <Column1>
                        <Title variant="h5">Especificações:</Title>
                    </Column1>
                    <Column3>
                        <Typography
                            className={classes.column3Title}
                            variant="h5"
                        >
                            Acréscimos ou descontos:
                        </Typography>
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Área total da propriedade?
                        </Typography>
                        <YesNo check={isTotalArea} setCheck={setIsTotalArea} />
                    </Column1>
                    <Column2 className={classes.columnTwoContent}>
                        <CustomTextFieldValue
                            className={classes.columnTwoContent}
                            initialText={'Qual deve ser a área?'}
                            inputMode="numeric"
                            isTotalArea={isTotalArea}
                            areaTotal={client.ha}
                            setAreaMade={setAreaMade}
                            initialValue={proposalData?.haMade || client.ha}
                        />
                    </Column2>
                    <Column3 className={classes.pricing}>
                        <Price price={areaMadePrice} observation={true} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Qual o ciclo?
                        </Typography>
                        <Autocomplete
                            {...defaultPropsCycle}
                            id="input-cycle"
                            autoSelect
                            disableClearable
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Column1>
                    <Column3 className={classes.pricing}>
                        <Price price={cyclePrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Monitoramento?
                        </Typography>
                        <YesNo
                            check={monitorament}
                            setCheck={setMonitorament}
                        />
                    </Column1>
                    <Column3 className={classes.pricing}>
                        <Price price={monitoramentPrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Mapa de perfil?
                        </Typography>
                        <Autocomplete
                            {...defaultPropsprofileMap}
                            id="input-profileMap"
                            autoSelect
                            blurOnSelect="mouse"
                            disableClearable
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Column1>
                </ContainerRow>
                {selectedProfileMaps.map((value, index) => {
                    return (
                        <ContainerRow key={value}>
                            <Column1 className={classes.title}>
                                <List
                                    dense
                                    key={value}
                                    className={classes.root}
                                >
                                    <ListItem
                                        key={value}
                                        className={classes.listItem}
                                        button
                                    >
                                        <ListItemText
                                            id={index}
                                            primary={value}
                                        />
                                        <Button
                                            startIcon={<ClearIcon />}
                                            onClick={() => {
                                                const newProfileMaps =
                                                    selectedProfileMaps.filter(
                                                        (curr) =>
                                                            curr !==
                                                            selectedProfileMaps[
                                                                index
                                                            ]
                                                );
                                                setSelectedProfileMaps(
                                                    newProfileMaps
                                                );
                                                deleteProfileMapOption(value);
                                            }}
                                        ></Button>
                                    </ListItem>
                                </List>
                            </Column1>
                            <Column3 className={classes.pricing}>
                                <Price price={statePrice.profileMap[value]} />
                            </Column3>
                        </ContainerRow>
                    );
                })}
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Acompanhamento?
                        </Typography>
                        <YesNo
                            check={accompaniment}
                            setCheck={setAccompaniment}
                        />
                    </Column1>
                    <Column3 className={classes.pricing}>
                        <Price price={accompanimentPrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Mapa de Colheita?
                        </Typography>
                        <YesNo check={harvestMap} setCheck={setHarvestMap} />
                    </Column1>
                    <Column3 className={classes.pricing}>
                        <Price price={harvestMapPrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Urgência?
                        </Typography>
                        <YesNo check={urgency} setCheck={setUrgency} />
                    </Column1>
                    <Column2 className={classes.columnTwoContent}>
                        <br />
                        <DateSelector
                            disabled={urgency ? false : true}
                            invalidDays={Number(INVALID_DATES)}
                        />
                    </Column2>
                    <Column3>
                        {' '}
                        <div className={classes.urgency}>
                            Observação: Em caso de não cumprimento do prazo, o
                            valor acrescido deverá ser removido.
                        </div>
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Sazonalidade
                        </Typography>

                        <Autocomplete
                            {...defaultPropsSeasonality}
                            id="input-seasonality"
                            autoSelect
                            disableClearable
                            disabled={urgency ? false : true}
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Column1>
                    <Column3 className={classes.pricing}>
                        <Price price={urgencyPrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.typography} variant="h6">
                            <br />
                            Laboratório Específico?
                        </Typography>
                        <YesNo check={specificLab} setCheck={setSpecificLab} />
                    </Column1>
                    <Column2 className={classes.formControlLab}>
                        <br />
                        <span className={classes.tinyTextStyle}>
                            Laboratório
                        </span>
                        <Autocomplete
                            {...defaultPropsLab}
                            id="input-lab"
                            autoSelect
                            disabled={specificLab ? false : true}
                            disableClearable
                            className={classes.formControlLab}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Column2>

                    <Column3 className={classes.pricing}>
                        <Price price={laboratoryPrice} />
                    </Column3>
                </ContainerRow>
                <ContainerRow>
                    <Column1>
                        <Typography className={classes.subtotal} variant="h4">
                            Subtotal do valor:
                        </Typography>
                    </Column1>
                    <Column3 className={classes.subtotalPrice}>
                        <Subtotal
                            subPrice={specificationSubPrice}
                            haMade={proposalData?.haMade}
                        />
                    </Column3>
                </ContainerRow>
            </Container>
        </>
    );
}
