import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 30px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    max-width: 80vw;
    @media screen and (max-width: 700px) {
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 94vw;
    }
`;

export const ContainerRow = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2 column3';
    grid-auto-columns: 2fr 0.3fr 1.14fr;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (max-width: 1400px) {
        grid-template-areas:
            'column1 column2'
            'column3 column3';
    }
    @media screen and (max-width: 1086px) {
        grid-template-areas:
            'column1'
            'column2'
            'column3';
    }
`;

export const Column1 = styled.div`
    display: grid;
    grid-area: column1;
    align-self: center;
    padding-bottom: 20px;
    @media screen and (max-width: 1400px) {
        border-right: 0px solid #e0e0e0;
    }
`;

export const Column2 = styled.div`
    grid-area: column2;
    border-right: 1px solid #e0e0e0;
    display: grid;
    padding: 50px 0;
    justify-content: center;
    @media (min-width: 760px) and (max-width: 970px) {
        padding: 0;
    }
`;

export const Column3 = styled.div`
    display: grid;
    grid-area: column3;
    margin: 0 auto;
    padding: 25px 0;
    align-self: start;
`;

export const InvisibleContainer = styled.div`
    height: 46px;
`;

export const CustomTitleParcel = styled.div`
    margin-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.44px;
    color: #000000;
`;

export const RemainingValue = styled.div`
    display: block;
    font-weight: 300;
    font-size: 28px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.44px;
    color: #000000;
`;

export const ResidualValue = styled.div`
    display: block;
    padding: 0 12px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.44px;
    color: #000000;
`;

export const ButtonText = styled.p`
    color: #000;
`;

export const Chip = styled.div``;

export const InCashSwitch = styled.div`
    margin: -20px auto;
    transform: translateY(-30px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.44px;
    color: #000000;
`;
