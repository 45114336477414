import { apiUrl } from './Api';
import Axios from 'axios';

export async function updateGoal(auth, saId, goal) {
    try {
        return await Axios.put(
            `${apiUrl}/sas/${saId}/goals`,
            {
                value: goal,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findAllGoals(auth, year) {
    try {
        return await Axios.get(`${apiUrl}/goals/?year=${year}`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findGoalYears(auth) {
    try {
        return await Axios.get(`${apiUrl}/goals/findYears`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}
