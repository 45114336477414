import { useState, useContext } from 'react';

/* Material ui*/
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

/* Style */
import { useStyles } from './header.styles';

/* Components */
import HeaderMobile from './header-components/header-mobile.component';
import HeaderDesktop from './header-components/header-desktop.component';

/* Context */
import { authContext } from '../../contexts/AuthContext';

export const StyledDrawer = withStyles({
    paper: {
        background: 'rgb(68, 68, 70)',
        '@media (max-width: 700px)': {
            width: 240,
        },
    },
})(SwipeableDrawer);

export default function Header({ navBar }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:700px)');
    const { setAuthData } = useContext(authContext);

    const onLogOut = () => {
        window.location.reload();
        setAuthData(null);
    }; //clearing the context

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            {isMobile ? (
                <HeaderMobile
                    toggleDrawer={toggleDrawer}
                    open={open}
                    onLogOut={onLogOut}
                    navBar={navBar}
                />
            ) : (
                <HeaderDesktop
                    toggleDrawer={toggleDrawer}
                    open={open}
                    onLogOut={onLogOut}
                    navBar={navBar}
                />
            )}
        </div>
    );
}
