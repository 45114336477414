import { useState, useContext, useEffect } from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';

/* Context */
import { clientContext } from '../../contexts/ClientContext';

import { addDays, format } from 'date-fns';

import { isValidDate } from '../../utils/isValidDate';

export default function DateSelector({ disabled, invalidDays }) {
    const { specificationPercentages, setSaveOptions } =
        useContext(clientContext);

    const today = new Date();
    const date = format(addDays(today, invalidDays), 'MM/dd/yyyy');

    const [selectedDate, setSelectedDate] = useState(date);

    // salva em SaveOptions o valor inicial da data, já que a função handleDateChange só executa no onChange
    useEffect(() => {
        if (specificationPercentages && specificationPercentages.seasonality) {
            setSaveOptions((options) => ({
                ...options,
                deadline: selectedDate,
            }));
        }
    }, [selectedDate, specificationPercentages, setSaveOptions]);

    const handleDateChange = (date) => {
        if (date && isValidDate(date)) {
            const dateChanged = new Date(date);
            const fulldate = format(dateChanged, 'dd/MM/yyyy');

            setSaveOptions((options) => ({
                ...options,
                deadline: fulldate,
            }));

            setSelectedDate(format(date, 'MM/dd/yyyy'));
        } else {
            setSaveOptions((options) => ({
                ...options,
                deadline: null,
            }));

            setSelectedDate('');
        }
    };

    return (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                disabled={disabled ? true : false}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker"
                label="Data de Entrega"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={date}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
