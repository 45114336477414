import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 30px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    max-width: 80vw;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    @media screen and (max-width: 700px) {
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 94vw;
        padding-left: 0px;
    }
`;

export const ContainerRow = styled.div`
    display: grid;
    grid-template-areas: 'column1 column1 column2';
    grid-auto-columns: 1fr;
    border-bottom: 1px solid #e0e0e0;

    @media screen and (max-width: 970px) {
        grid-template-areas:
            'column1'
            'column1'
            'column2';
    }
`;

export const Column1 = styled.div`
    grid-area: column1;
    padding-left: 20px;
    padding-bottom: 20px;
    ${
        '' /* @media (max-width: 759px) {
        padding: 0;
        justify-content: center;
        text-align: center;
    } */
    }
`;

export const Column2 = styled.div`
    grid-area: column2;
    border-left: 1px solid #e0e0e0;
    padding: 10px;
    @media screen and (max-width: 802px) {
        font-size: 1.5rem;
        border-left: 0px solid #e0e0e0;
    }
`;

export const InvisibleContainer = styled.div`
    height: 46px;
`;
