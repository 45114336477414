import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authContext } from './contexts/AuthContext';
import { findSaPermission } from './api/ApiPermission';
import getPermission from './utils/getPermission';
import { useState } from 'react';
import { useEffect } from 'react';

const callApiFindSaPermission = async (auth) => {
    const response = await findSaPermission(auth, auth.data.user.stateId, auth.data.user.saId);
    return response;
};

const PrivateRoute = ({ component: Component, module: Module, ...rest }) => {
    const { auth } = useContext(authContext);
    const { loading } = auth;
    const [ permission, setPermission ] = useState(true);

    useEffect (() => {
        if(!auth.data || !Module) return;
        const getPermissions = async () => {
            const response = await callApiFindSaPermission(auth);
            setPermission(getPermission(response, Module));
        }
        try {
            getPermissions();
        } catch (error) {
            console.log(error);
        }
    }, [auth, Module]);

    if (loading) {
        return (
            <Route
                {...rest}
                render={() => {
                    return <p>Loading...</p>;
                }}
            />
        );
    }
    // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                return auth.data ? (
                    permission ? <Component {...routeProps} /> : <Redirect to="/dashboard" />
                ) : (
                    <Redirect to="/login" />
                );
            }}
        />
    );
};

export default PrivateRoute;
