import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 30px auto;
    ${'' /* background-color: #fff; */}
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    max-width: 80vw;
    display: grid;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    grid-template-columns: 0.6fr 1fr;
    grid-template-areas:
        'title title'
        'select note';

    @media screen and (max-width: 1150px) {
        margin-top: 100px;
        margin-bottom: 50px;
        grid-template-areas:
            'title title'
            'select select'
            'note note';
        max-width: 80vw;
    }

    @media screen and (max-width: 700px) {
        max-width: 94vw;
    }
`;

export const Title = styled.div`
    grid-area: title;
    border-bottom: 1px solid #e0e0e0;
    font-size: 22px;
    padding: 10px 10px 10px 18px;
    font-weight: 500;
    color: #444446;
    font-family: 'Roboto';
`;

export const SelectStyle = styled.div`
    grid-area: select;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 3rem 30px 3rem;
`;

export const Note = styled.div`
    grid-area: note;
    border-left: 1px solid #e0e0e0;
    color: #2b2a2a;
    padding-left: 20px;
    display: flex;
    font-size: 1rem;
    width: 80%;
    display: grid;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1150px) {
        border-top: 1px solid #e0e0e0;
        width: 100%;
        padding: 20px;
        text-align: center;
    }
`;

export const Link = styled.a`
    color: #e25b53 !important;
    font-weight: 500;
    font-family: Roboto, sans-serif;
`;

export const NoteObservation = styled.div`
    display: inline;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #2b2a2a;
`;

export const InvisibleContainer = styled.div`
    height: 45px;
`;
