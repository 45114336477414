import { useContext } from 'react';
import clsx from 'clsx';

/* Material ui*/
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/* Icons */
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

/* Style */
import { useStyles } from './header-components.styles';
import { links } from './navData';

/* Components */
import ItemsList from './itemsList.component';
import { StyledDrawer } from './../header.component';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

export default function HeaderDesktop({
    toggleDrawer,
    open,
    onLogOut,
    navBar,
}) {
    const classes = useStyles();
    const { auth } = useContext(authContext);

    if (window.history.pushState) {
        window.history.pushState('', '/', window.location.pathname);
    } else {
        window.location.hash = '';
    }

    return (
        <>
            <AppBar
                color="primary"
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                            <div className="logo-title-nav">
                                Precificador{' '}
                                <img
                                    className="logo-nav"
                                    src="logo.svg"
                                    alt="logo_Drakkar"
                                />
                            </div>
                        </IconButton>
                        <div className="div-invisibility">
                            {navBar &&
                                links.map((link, index) => {
                                    return (
                                        <span className="nav" key={link.id}>
                                            <a
                                                className="nav-underline"
                                                href={link.url}
                                            >
                                                {link.text}
                                            </a>
                                        </span>
                                    );
                                })}
                        </div>
                    </>
                    <div className={classes.grow} />
                    <div className={classes.grow} />
                    <Typography variant="body1">
                        {auth.data.user.name}
                    </Typography>
                    <Button
                        style={{ backgroundColor: 'transparent' }}
                        onClick={onLogOut}
                    >
                        <ExitToAppIcon className={classes.iconSm} />
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
            <StyledDrawer
                variant="permanent"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <ItemsList toggleDrawer={toggleDrawer} />
            </StyledDrawer>
        </>
    );
}
