import { useContext, useState, useEffect, useRef } from 'react';
import {
    Container,
    Title,
    SelectStyle,
    Note,
    Link,
    NoteObservation,
    InvisibleContainer,
} from './find-client-modal.styles';

/* Material UI */
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

/* Icon */
import CachedIcon from '@material-ui/icons/Cached';

/* Context */
import { authContext } from '../../contexts/AuthContext';
import { statePriceContext } from '../../contexts/StatePriceContext';
import { clientContext } from '../../contexts/ClientContext';

/* API */
import { findAllClientsFromState, importClients } from '../../api/ApiClient';
import { findAllSaClients } from '../../api/ApiSa';
import { findAllStates } from '../../api/ApiState';
import { findPrincingFromState } from '../../api/ApiState';
import { findSaState } from '../../api/ApiSa';

import orderByObjectKey from '../../utils/orderByObjectKey';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    styleTitle: {
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    button: {
        fontFamily: 'Poppins, sans-serif',
        textTransform: 'none',
        padding: '12px 13px',
        width: '100%',
        marginBottom: 20,
        '@media (max-width: 830px)': {
            marginBottom: 20,
        },
    },
    alertButton: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14.5,
        fontWeight: '500',
        letterSpacing: 0.39998,
        color: '#616161',
        marginBottom: 20,
    },
    link: {
        fontWeight: '500',
        color: '#2aa2ed',
    },
    noteStyle: {
        padding: '35px',
        textAlign: 'justify',
        '@media (max-width: 700px)': {
            padding: '20px',
            textAlign: 'center',
        },
    },
    checkbox: {
        fontFamily: 'Poppins, sans-serif',

        padding: '12px 13px',
        marginBottom: 20,

        color: '#616161',
        transform: 'translate(4.5px, -2.5rem) scale(0.95)',

        '@media (max-width: 830px)': {
            marginBottom: 20,
        },
    },
    selectState: {
        padding: '35px',
        width: '60%',
        '@media (max-width: 700px)': {
            padding: '20px',
        },
    },
}));

export default function FindClientModal({
    proposalStatePrice = null,
    proposalData = null,
}) {
    const { auth } = useContext(authContext);
    const { setStatePrice } = useContext(statePriceContext);
    const { statePrice } = useContext(statePriceContext);
    const { client, setClient, saveOptions, setSaveOptions } =
        useContext(clientContext);

    const [clientList, setClientList] = useState([]);
    const [refreshClientNotification, setRefreshClientNotification] =
        useState(false);

    const [state, setState] = useState({
        uf: auth.data.user.uf,
        stateId: auth.data.user.stateId,
    });
    const [stateList, setStateList] = useState([]);

    const [hasSaNull, setHasSaNull] = useState(false);

    const timeoutRef = useRef();

    const classes = useStyles();

    useEffect(() => {
        if (client.stateId)
            setState({
                uf: client.uf,
                stateId: client.stateId,
            });
    }, [client.stateId, client.uf]);

    useEffect(() => {
        const callApiFindAllSaClients = async () => {
            const response = await findAllSaClients(
                auth,
                auth.data.user.saId,
                proposalData ? true : hasSaNull
            );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            let orderedClients = [];
            if (payload.length > 0) {
                orderedClients = orderByObjectKey(payload, 'name');
                setClientList(orderedClients);
            } else {
                setClientList([]);
            }

            if (proposalData) {
                const proposalClient = orderedClients.filter((curr) => {
                    return curr.clientId === proposalData.clientId
                        ? true
                        : false;
                });

                setClient({
                    ...proposalClient[0],
                    grid: statePrice.grid[saveOptions.grid],
                });
            }
        };

        const callApiFindAllClientsFromState = async () => {
            const response = await findAllClientsFromState(
                auth,
                statePrice.stateId
            );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            let orderedClients = [];
            if (payload.length > 0) {
                orderedClients = orderByObjectKey(payload, 'name');
                setClientList(orderedClients);
            } else {
                setClientList([]);
            }
            if (proposalData) {
                const proposalClient = orderedClients.filter((curr) => {
                    return curr.clientId === proposalData.clientId
                        ? true
                        : false;
                });

                setClient({
                    ...proposalClient[0],
                    grid: statePrice.grid[saveOptions.grid],
                });
            }
        };

        try {
            if (statePrice) {
                if (auth.data.user.permissionId === 3)
                    callApiFindAllSaClients();
                else callApiFindAllClientsFromState();
            }
        } catch (err) {
            console.log(err);
        }
    }, [
        auth,
        proposalData,
        statePrice,
        setClient,
        hasSaNull,
        saveOptions,
        refreshClientNotification,
    ]);

    useEffect(() => {
        const callApiImportClients = async () => {
            let response = null;
            if (refreshClientNotification) {
                response = await importClients(auth, auth.data.user.stateId);
                if (response.status !== 200) throw Error(response.data.message);
            }
        };

        try {
            callApiImportClients();
        } catch (err) {
            console.log(err);
        }
    }, [auth, refreshClientNotification]);

    const handleClickRefreshClients = () => {
        setRefreshClientNotification(true);

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setRefreshClientNotification(false);
        }, 4000);
    };

    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStates(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);
            if (proposalData) {
                const state = orderedStates.filter((curr) => {
                    return curr.stateId === proposalData.stateId ? true : false;
                });
                setState(state[0]);
            }
        };

        const callApiFindSaState = async () => {
            const response = await findSaState(auth, auth.data.user.saId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            const orderedStates = orderByObjectKey(payload, 'uf');

            setStateList(orderedStates);

            if (proposalData) {
                const state = orderedStates.filter((curr) => {
                    return curr.stateId === proposalData.stateId ? true : false;
                });
                setState(state[0]);
            }
        };

        try {
            if (auth.data.user.permissionId === 1) {
                // master
                callApiFindAllStates();
            } else {
                // gerente e AS
                callApiFindSaState();
            }
        } catch (err) {
            console.log(err);
        }
    }, [auth, proposalData]);

    useEffect(() => {
        const callApifindPrincingFromState = async () => {
            const response = await findPrincingFromState(auth, state.stateId);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            payload[0]['stateId'] = state.stateId;
            setStatePrice(payload[0]);
        };

        try {
            if (!proposalStatePrice) callApifindPrincingFromState();
        } catch (err) {
            console.log(err);
        }
    }, [auth, state, setStatePrice, proposalStatePrice]);

    const defaultPropsState = {
        options: stateList,
        value: state,
        getOptionLabel: (option) => {
            return option.uf || '';
        },
        getOptionSelected: (option) => {
            return option.uf || '';
        },
        onChange: (event, newValue) => {
            if (client.stateId !== newValue.stateId)
                setClient({
                    clientId: null,
                    name: '',
                    ha: null,
                    areaMade: null,
                    grid: null,
                    stateId: null,
                });
            setState({ uf: newValue.uf, stateId: newValue.stateId });
        },
    };

    const defaultPropsClient = {
        options: clientList,
        value: client,
        getOptionLabel: (option) => {
            return option.clientId
                ? option.clientId &&
                      `${option.clientId} - ${option.name} - ${
                          option.ha ? option.ha : 0
                      } ha`
                : '';
        },
        getOptionSelected: (option) => {
            return (
                option &&
                `${option.clientId} - ${option.name} - ${option.ha} ha - ${option.uf}`
            );
        },
        onChange: (event, newValue) => {
            setClient({ ...newValue, grid: statePrice.grid[saveOptions.grid] });
            setSaveOptions((options) => ({
                ...options,
                grid: saveOptions.grid,
            }));
        },
    };

    return (
        <>
            <InvisibleContainer id="find-client-container">
                {' '}
            </InvisibleContainer>
            <Container>
                <Title className={classes.styleTitle}>Buscar Cliente</Title>
                <SelectStyle>
                    <span className={classes.propId}>
                        {proposalData
                            ? 'Número da Proposta: ' + proposalData?.proposalId
                            : ''}
                    </span>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <Autocomplete
                            {...defaultPropsClient}
                            id="input-clients"
                            autoSelect
                            disableClearable
                            className={classes.formControl}
                            disabled={proposalData ? true : false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Id - Nome Cliente - Ha"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                    {!refreshClientNotification && (
                        <Button
                            color="secondary"
                            startIcon={<CachedIcon />}
                            className={classes.button}
                            onClick={handleClickRefreshClients}
                        >
                            Buscar novos cadastros
                        </Button>
                    )}

                    {refreshClientNotification && (
                        <Alert
                            className={classes.alertButton}
                            iconMapping={{
                                success: (
                                    <CheckCircleOutlineIcon fontSize="inherit" />
                                ),
                            }}
                        >
                            Clientes atualizados!
                        </Alert>
                    )}
                    {auth.data.user.permissionId !== 3 && !proposalData && (
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={
                                        <CheckCircleIcon color="primary" />
                                    }
                                    name="checkedH"
                                    onChange={() => setHasSaNull(!hasSaNull)}
                                />
                            }
                            label="Buscar clientes sem RTV"
                        />
                    )}
                </SelectStyle>
                <Note>
                    <FormControl
                        variant="outlined"
                        className={classes.selectState}
                    >
                        <Autocomplete
                            {...defaultPropsState}
                            id="input-states"
                            autoSelect
                            disableClearable
                            disabled={proposalData ? true : false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Estado"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                    <span className={classes.noteStyle}>
                        <NoteObservation>Nota</NoteObservation>: O cliente deve
                        estar cadastrado no Pipedrive antes de executar a
                        proposta, bem como, estar associado a um estado e
                        possuir hectares, caso não seja cadastrado ainda,
                        <Link
                            className={classes.link}
                            href="https://app.pipedrive.com/auth/login"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            clique aqui.{' '}
                        </Link>
                    </span>
                </Note>
            </Container>
        </>
    );
}
