import { useEffect } from 'react';

import { DrawerAdjustment } from './fertilizer.styles';

/* Components */
import Header from '../../components/header/header.component';
import FertilizerData from '../../components/fertilizer-data/fertilizer-data.component';

export default function Fertilizer() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <FertilizerData />
        </DrawerAdjustment>
    );
}
