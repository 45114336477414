/* Generic pop up component */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import SummaryProposalPdf from '../../utils/pdf/react-pdf/summaryProposalPdf';

export default function PopUp(props) {
    const [open, setOpen] = React.useState(true);
    const [ready, setReady] = React.useState(false);

    let history = useHistory();
    const handleClose = () => {
        /*Success = 0 -> Nothing (stay in the same page)
        Success = 1 -> Reload
        Success = 2 -> Specific route
        Success = 3 -> Reload the child of an tab component*/
        setOpen(false);
        if (props.success === 0) {
            props.setPopup(false);
        } else if (props.success === 1) {
            if (props.setPopup) props.setPopup(false);
            setOpen(false);
        }

        if (props.success === 1) {
            if (props.setPopup && props.isForPdf) {
                props.setPopup(false);
                window.location.reload();
            } else if (props.setPopup) props.setPopup(false);
            else window.location.reload();
        } else if (props.success === 2) {
            history.replace(props.route);
        } else if (props.success === 3) {
            props.setNewFatherContext();
        }

        /* Close the popUp in the father component,
        to be able to call a new popUp*/
        if (props.handleClose !== undefined) {
            props.handleClose();
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.string}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.acceptable && !props.agree ? (
                        <Button onClick={props.acceptFunction} color="primary">
                            Sim
                        </Button>
                    ) : null}
                    {props.isForPdf && props.title === 'Sucesso' ? (
                        <>
                            {ready && (
                                <BlobProvider
                                    document={
                                        <SummaryProposalPdf
                                            auth={props.auth}
                                            proposalId={props?.proposalId}
                                            saName={props.client.saName}
                                            client={props.client}
                                            specificationPercentages={
                                                props.specificationPercentages
                                            }
                                            servicesPercentages={
                                                props.servicesPercentages
                                            }
                                            paymentPercentages={
                                                props.paymentPercentages
                                            }
                                            paymentSubPrice={
                                                props.paymentSubPrice
                                            }
                                            saveOptions={props.saveOptions}
                                            profileMapPercentage={
                                                props.profileMapPercentage
                                            }
                                        />
                                    }
                                >
                                    {({ url }) => (
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => setReady(false)}
                                            >
                                                Visualizar
                                            </Button>
                                        </a>
                                    )}
                                </BlobProvider>
                            )}
                            {!ready && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setReady(false);
                                        setTimeout(() => {
                                            setReady(true);
                                        }, 1);
                                    }}
                                >
                                    Gerar PDF
                                </Button>
                            )}
                            <Button onClick={handleClose} color="primary">
                                Fechar
                            </Button>
                        </>
                    ) : props.isForPdf && props.title !== 'Sucesso' ? (
                        <Button onClick={handleClose} color="primary">
                            Ok
                        </Button>
                    ) : props.acceptable && !props.agree ? (
                        <Button onClick={handleClose} color="primary">
                            Não
                        </Button>
                    ) : (
                        <Button onClick={handleClose} color="primary">
                            Ok
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
