import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

/* Components */
import { SignInPage } from './pages/sign-in/sign-in.component';
import NewProposal from './pages/new-proposal/new-proposal.component';
import Proposals from './pages/proposals/proposals.component';
import Dashboard from './pages/dashboard/dashboard.component';
import Settings from './pages/settings/settings.component';
import UpdateProposal from './pages/update-proposal/update-proposa.component';
import Fertilizer from './pages/fertilizer/fertilizer.component';
import Goals from './pages/goals/goals.component';
import States from './pages/states/states.component';

/* Styles */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MyTheme from './MyTheme';
import Director from './pages/director/director.component';

function App() {
    return (
        <MuiThemeProvider theme={MyTheme}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={SignInPage} />
                    <Route exact path="/login" component={SignInPage} />
                    <PrivateRoute
                        exact
                        path="/new-proposal"
                        component={NewProposal}
                        module="Nova Proposta"
                    />
                    <PrivateRoute
                        exact
                        path="/proposals"
                        component={Proposals}
                        module="Edição de Proposta"
                    />
                    <PrivateRoute
                        exact
                        path="/update-proposal"
                        component={UpdateProposal}
                        module="Edição de Proposta"
                    />
                    <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                        module="Dashboard"
                    />
                    <PrivateRoute
                        exact
                        path="/fertilizer"
                        component={Fertilizer}
                        module="Menu de Fertilizantes"
                    />
                    <PrivateRoute 
                        exact 
                        path="/goals" 
                        component={Goals} 
                        module="Menu de Metas"
                    />
                    <PrivateRoute 
                        exact 
                        path="/director" 
                        component={Director}
                        module="Menu de Diretor" 
                    />
                    <PrivateRoute 
                        exact 
                        path="/state" 
                        component={States} 
                        module="Menu de Estados"
                    />
                    <PrivateRoute 
                        exact 
                        path="/settings" 
                        component={Settings} 
                    />
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>
    );
}

export default App;
