import { apiUrl } from './Api';
import Axios from 'axios';

export async function findPrincingFromState(auth, stateId) {
    try {
        return await Axios.get(`${apiUrl}/states/${stateId}/findPricing`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllStates(auth) {
    try {
        return await Axios.get(`${apiUrl}/states`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllStatesAndProposals(auth, year) {
    try {
        return await Axios.get(`${apiUrl}/states/findAllAndProposals`, {
            params: { year },
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findProposalsByStatus(auth, stateId, year) {
    try {
        return await Axios.get(
            `${apiUrl}/states/${stateId}/findProposalsByStatus`,
            {
                params: { year },
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function proposalValuesFromState(auth, stateId, year) {
    try {
        return await Axios.get(
            `${apiUrl}/states/${stateId}/proposalValuesFromState`,
            {
                params: { year },
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findGridsAndProposals(auth, stateId, saId, clientId, year) {
    try {
        return await Axios.get(
            `${apiUrl}/states/findGridsAndProposals`,
            {
                params: { stateId, saId, clientId, year },
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}
