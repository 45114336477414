import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 0px auto;
    font-family: Roboto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    max-width: 80vw;
    @media screen and (max-width: 700px) {
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 94vw;
    }
`;

export const ContainerRow = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2 column3';
    grid-auto-columns: 1fr;
    border-bottom: 1px solid #e0e0e0;
    @media (min-width: 760px) and (max-width: 970px) {
        grid-template-areas:
            'column1 column3'
            'column2 column3';
    }
    @media (min-width: 320px) and (max-width: 759px) {
        grid-template-areas:
            'column1'
            'column2'
            'column3';
    }
`;

export const Column1 = styled.div`
    grid-area: column1;
    padding-left: 20px;
    padding-bottom: 20px;
    @media (min-width: 760px) and (max-width: 970px) {
        padding-bottom: 0;
    }
    @media (max-width: 759px) {
        padding: 0;
        justify-content: center;
        text-align: center;
    }
`;

export const Column2 = styled.div`
    grid-area: column2;
    padding: 10px;
    display: grid;
    justify-content: center;
    @media (min-width: 760px) and (max-width: 970px) {
        padding: 0;
    }
`;

export const Column3 = styled.div`
    grid-area: column3;
    border-left: 1px solid #e0e0e0;
    padding-left: 20px;
    @media (max-width: 759px) {
        border-left: none;
    }
`;

export const InvisibleContainer = styled.div`
    height: 70px;
`;

export const Title = styled.div`
    grid-area: title;
    font-size: 22px;
    padding: 20px 10px 2px 18px;
    font-weight: 500;
    color: #444446;
    font-family: 'Roboto';
    @media (max-width: 759px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;
