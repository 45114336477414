import { apiUrl } from './Api';
import Axios from 'axios';

export async function findSaPermission(auth, stateId, saId) {
    try {
        return await Axios.get(
            `${apiUrl}/states/${stateId}/sas/${saId}/permissions`,
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findAllPermissions(auth) {
    try {
        return await Axios.get(`${apiUrl}/permissions/findAllPermissions`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}
