import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Components */
import CustomButton from '../custom-button/custom-button.component';
import PopUp from '../popup/popup.component';

/* Styles */
import {
    Container,
    MainTitle,
    FieldsContainer,
    PasswordContainer,
} from './sign-in.styles';

/* Material ui */
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/* Api */
import { login, forgotPassword } from '../../api/Api';
import { importSas } from '../../api/ApiSa';

/* Context */
import { authContext } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
    forgotPasswordButton: {
        display: 'grid',
        border: '0px solid black',
        margin: '0px auto',
        marginBottom: 5,
        cursor: 'pointer',
        width: '65%',
        textDecoration: 'none !important',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'grid',
        border: '0px solid black !important',
        justifyItems: 'center',
        width: '30rem',
        color: '#D73B30',
        borderRadius: '10px',
        textAlign: 'center',
    },
    field: {
        width: '100%',
        paddingBottom: 20,
        flex: 1,
    },
    button: {
        fontFamily: 'Poppins, sans-serif',
        textTransform: 'none',
        padding: '12px',
        marginBottom: 20,
    },
    alertButton: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14.5,
        fontWeight: '500',
        letterSpacing: 0.39998,
        color: '#616161',
        marginBottom: 20,
    },
    searchEmail: {
        display: 'grid',
        border: '0px solid black',
        margin: '0px auto',
        color: '#fff',
        borderRadius: '5px',
        width: '12rem !important',
        backgroundColor: '#D73B30',
        textTransform: 'capitalize',
    },
    showPassword: {
        height: 56,
        marginTop: '0px',
        border: '1px solid #bdbdbd',
        borderLeft: 'none',
    },
    forgotPassword: {
        display: 'block',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.39998,
        color: '#616161',
        margin: '0px auto',
    },
}));

export default function SignIn() {
    const { authData, setAuthData } = useContext(authContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [refreshClientNotification, setRefreshClientNotification] =
        useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const timeoutRef = useRef();

    let history = useHistory();

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const callApiImportSas = async () => {
            let response = null;
            if (refreshClientNotification) {
                response = await importSas();
                if (response.status !== 200) throw Error(response.data.message);
            }
        };

        try {
            callApiImportSas();
        } catch (err) {
            console.log(err);
        }
    }, [authData, refreshClientNotification]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const user = await login(email, password);
            if (user.error) {
                setPopupTitle('Erro');
                if (user.message.response.data.message === "Cannot destructure property 'encryptedPassword' of 'result[0]' as it is undefined.")
                    setPopupText(
                        'Esse usuário não existe ou está em análise.'
                    );
                else setPopupText('Verifique seu email e senha');
                setPopup(true);
            } else if (user.token) {
                setAuthData({
                    token: user.token,
                    user: user.data.data,
                });

                history.replace('/new-proposal');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitForgotPassword = async (event) => {
        try {
            let response = await forgotPassword(email);

            if (response.status === 200) {
                setPopupTitle('Sucesso!');
                setPopupText('Sua nova senha está em seu email!.');
                setPopup(true);
                setSuccess(1);
            } else {
                setPopupTitle('Erro');
                setPopupText(
                    'Sua senha não pôde ser recuperada, tente novamente.'
                );
                setPopup(true);
                setSuccess(1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') setEmail(value);
        else setPassword(value);
    };

    const handleClickRefreshClients = () => {
        setRefreshClientNotification(true);

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setRefreshClientNotification(false);
        }, 2000);
    };

    return (
        <Container>
            <MainTitle>
                Precificador{' '}
                <img className="logo" src="logo.svg" alt="logo_Drakkar" />
            </MainTitle>
            <form onSubmit={handleSubmit}>
                <FieldsContainer>
                    <TextField
                        id="email-input-login"
                        name="email"
                        color="secondary"
                        label="Email"
                        type="email"
                        variant="outlined"
                        onChange={handleChange}
                        className={classes.field}
                        value={email}
                    />
                    <PasswordContainer>
                        <TextField
                            id="password-input-login"
                            name="password"
                            color="secondary"
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            variant="outlined"
                            onChange={handleChange}
                            className={classes.field}
                            value={password}
                        ></TextField>
                        <Button
                            className={classes.showPassword}
                            onClick={togglePassword}
                        >
                            {showPassword ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </Button>
                    </PasswordContainer>
                    <Link
                        type="button"
                        onClick={handleOpen}
                        className={classes.forgotPasswordButton}
                    >
                        <Typography className={classes.forgotPassword}>
                            Esqueceu sua senha?
                        </Typography>
                    </Link>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <h2>Email de recuperação</h2>
                                <TextField
                                    id="email-input-login"
                                    name="email"
                                    color="secondary"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    className={classes.field}
                                    value={email}
                                />
                                <CustomButton
                                    className={classes.searchEmail}
                                    onClick={handleSubmitForgotPassword}
                                >
                                    Enviar
                                </CustomButton>
                            </div>
                        </Fade>
                    </Modal>
                    <CustomButton signInButton onClick={handleSubmit}>
                        Entrar
                    </CustomButton>
                </FieldsContainer>
            </form>

            {!refreshClientNotification && (
                <Button
                    color="secondary"
                    startIcon={<CachedIcon />}
                    className={classes.button}
                    onClick={handleClickRefreshClients}
                >
                    Buscar novos cadastros de RTV's
                </Button>
            )}
            {refreshClientNotification && (
                <Alert
                    className={classes.alertButton}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                >
                    RTV's atualizados!
                </Alert>
            )}
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                />
            ) : null}
        </Container>
    );
}
