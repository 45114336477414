import { apiUrl } from './Api';
import Axios from 'axios';

export async function findAllLaboratories(auth) {
    try {
        return await Axios.get(`${apiUrl}/laboratories`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}
