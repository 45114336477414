import { apiUrl } from './Api';
import Axios from 'axios';

export async function findAllSas(auth) {
    try {
        return await Axios.get(`${apiUrl}/sas/findAll`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function importSas() {
    try {
        return await Axios.post(`${apiUrl}/auth/import`, {});
    } catch (err) {
        return err;
    }
}

export async function findAllSaFromState(auth, stateId) {
    try {
        return await Axios.get(`${apiUrl}/states/${stateId}/sas`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllSaAndProposalsFromState(auth, stateId, year) {
    try {
        return await Axios.get(`${apiUrl}/states/${stateId}/sas/findAllAndProposalsFromState`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
            params: { year }
        });
    } catch (err) {
        return err;
    }
}

export async function findSaStatesAndProposals(auth, saId, year) {
    try {
        return await Axios.get(`${apiUrl}/sas/${saId}/findSaStatesAndProposals`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
            params: { year }
        });
    } catch (err) {
        return err;
    }
}

export async function findAllSaAndProposals(auth, year) {
    try {
        return await Axios.get(`${apiUrl}/sas/findAllAndProposals`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
            params: { year }
        });
    } catch (err) {
        return err;
    }
}

export async function changePassword(auth, password) {
    try {
        return await Axios.put(
            `${apiUrl}/sas/${auth.data.user.saId}/`,
            {
                password,
            },
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findAllSaClients(auth, saId, hasSaNull) {
    try {
        hasSaNull = Number(hasSaNull);
        return await Axios.get(`${apiUrl}/sas/${saId}/findAllSaClients`, {
            params: { hasSaNull },
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findSaState(auth, saId) {
    try {
        return await Axios.get(`${apiUrl}/sas/${saId}/findSaStates`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllStatesFromSas(auth) {
    try {
        return await Axios.get(`${apiUrl}/sas/findAllStatesFromSas`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function setSaState(auth, saId, stateId, permissionId) {
    try {
        return await Axios.post(
            `${apiUrl}/sas/${saId}/setSaState`,
            {
                stateId: stateId,
                permissionId: permissionId,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function deleteSaState(auth, saId, stateId) {
    try {
        return await Axios.delete(`${apiUrl}/sas/${saId}/deleteSaState`, {
            headers: {
                Authorization: `Bearer ${auth.data.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                stateId: stateId,
            },
        });
    } catch (err) {
        return err.response;
    }
}
