import { apiUrl } from './Api';
import Axios from 'axios';

export async function createDirector(auth, saId, directorId) {
    try {
        return await Axios.put(
            `${apiUrl}/sas/${saId}/setAsDirector`,
            {
                directorId,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function findAllSaFromDirector(auth, directorId) {
    try {
        return await Axios.get(
            `${apiUrl}/sas/getRelationshipDirector/${directorId}`,
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function findAllDirectors(auth) {
    try {
        return await Axios.get(
            `${apiUrl}/sas/findAllDirectors`,
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}
