import { useEffect } from 'react';

import { DrawerAdjustment } from './proposals.styles';

/* Components */
import Header from '../../components/header/header.component';
import ProposalTable from '../../components/edit-proposal-table/edit-proposal-table.component';

export default function Proposal() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <ProposalTable />
        </DrawerAdjustment>
    );
}
