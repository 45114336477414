import { useContext, useState } from 'react';

import {
    Container,
    Title,
    EachField,
    StyledInput1,
    StyledInput2,
    PasswordTitle,
} from './change-password.styles';

/* Components */
import PopUp from '../../components/popup/popup.component';

/*Material UI*/
import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

/* Context */
import { authContext } from '../../contexts/AuthContext';

// API
import { changePassword } from '../../api/ApiSa';

const theme = createTheme({
    palette: {
        primary: { main: red[600] },
    },
});

const useStyles = makeStyles((theme) => ({
    showPassword: {
        transform: 'translateX(-50%) scale(0.8)',
        color: '#444446',
    },
    showPasswordConfirmation: {
        transform: 'translateX(-65%) scale(0.8)',
        color: '#444446',
    },
    mainButton: {
        margin: '1.2rem auto 2.2rem auto',
        fontFamily: 'Roboto',
        fontSize: '18px',
        textTransform: 'capitalize',
        width: '16rem',
        borderRadius: '10px',
        position: 'relative',
        background: '#e0645e',
    },
}));

export default function ChangePassword() {
    const [password, setPassword] = useState('');
    const [verificationPassword, setVerificationPassword] = useState('');

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] =
        useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const { auth } = useContext(authContext);

    const togglePasswordConfirmation = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const handleSubmit = async (event) => {
        if (verificationPassword !== password) {
            setPopupTitle('Erro');
            setPopupText('As senhas inseridas são diferentes');
            setPopup(true);
            setSuccess(1);
            return;
        }

        try {
            let response = await changePassword(auth, password);

            if (response.status === 200) {
                setPopupTitle('Sucesso!');
                setPopupText('Sua senha foi alterada.');
                setPopup(true);
                setSuccess(1);
            } else {
                setPopupTitle('Erro');
                setPopupText(
                    'Sua senha não pôde ser alterada, tente novamente.'
                );
                setPopup(true);
                setSuccess(1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const classes = useStyles();

    return (
        <div className={classes.subContainer}>
            <Container>
                <Title>Configurações</Title>
                <div>
                    <PasswordTitle> Digite sua nova senha </PasswordTitle>
                    {
                        <EachField>
                            <StyledInput1
                                value={password}
                                onChange={({ target }) =>
                                    setPassword(target.value)
                                }
                                type={showPassword ? 'text' : 'password'}
                                name={password}
                                required={true}
                            />

                            <Button
                                className={classes.showPassword}
                                onClick={togglePassword}
                            >
                                {showPassword ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </Button>
                        </EachField>
                    }
                    <PasswordTitle> Confirme sua nova senha </PasswordTitle>
                    {
                        <EachField>
                            <StyledInput2
                                value={verificationPassword}
                                onChange={({ target }) =>
                                    setVerificationPassword(target.value)
                                }
                                type={
                                    showPasswordConfirmation
                                        ? 'text'
                                        : 'password'
                                }
                                name={verificationPassword}
                                required={true}
                            />

                            <Button
                                className={classes.showPasswordConfirmation}
                                onClick={togglePasswordConfirmation}
                            >
                                {showPassword ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </Button>
                        </EachField>
                    }

                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.mainButton}
                            onClick={handleSubmit}
                        >
                            Alterar senha
                        </Button>
                    </ThemeProvider>
                </div>
            </Container>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                />
            ) : null}
        </div>
    );
}
