import { useEffect } from 'react';
import { createContext, useState } from 'react';

export const clientContext = createContext({});

const ClientProvider = ({ children }) => {
    const [client, setClient] = useState({
        clientId: null,
        name: '',
        ha: null,
        areaMade: null,
        grid: null,
        stateId: null,
    });

    const [saveOptions, setSaveOptions] = useState(null);

    const [specificationPercentages, setSpecificationPercentages] =
        useState(null);

    const [servicesPercentages, setServicesPercentages] = useState(null);
    const [paymentPercentages, setPaymentPercentages] = useState(null);
    const [profileMapPercentage, setProfileMapPercentage] = useState(null);

    const [specificationSubPrice, setSpecificationSubPrice] = useState(0);
    const [serviceSubPrice, setServiceSubPrice] = useState(0);
    const [paymentSubPrice, setPaymentSubPrice] = useState(0);

    useEffect(() => {
        let finalPercentage = 0;

        if (profileMapPercentage) {
            for (let [, value] of Object.entries(profileMapPercentage)) {
                if (value !== undefined) {
                    finalPercentage += value;
                }
            }
        }

        if (specificationPercentages) {
            for (let [, value] of Object.entries(specificationPercentages)) {
                if (value !== undefined) {
                    finalPercentage += value;
                }
            }
        }
        setSpecificationSubPrice(
            (saveOptions?.areaMade || client.ha) * client.grid +
                (saveOptions?.areaMade || client.ha) *
                    client.grid *
                    (finalPercentage / 100)
        );
    }, [client, profileMapPercentage, specificationPercentages, saveOptions]);

    useEffect(() => {
        let finalPercentage = 0;

        if (servicesPercentages) {
            for (let [, value] of Object.entries(servicesPercentages)) {
                if (value !== undefined) {
                    finalPercentage += value;
                }
            }
        }

        setServiceSubPrice(
            specificationSubPrice +
                specificationSubPrice * (finalPercentage / 100)
        );
    }, [client, specificationSubPrice, servicesPercentages]);

    useEffect(() => {
        if (paymentPercentages) {
            setPaymentSubPrice(() => {
                if (paymentPercentages.buyIn === null)
                    return serviceSubPrice + paymentPercentages.interestSum;
                else
                    return (
                        serviceSubPrice +
                        serviceSubPrice * (paymentPercentages.buyIn / 100)
                    );
            });
        }
    }, [client, serviceSubPrice, paymentPercentages]);

    return (
        <clientContext.Provider
            value={{
                client,
                setClient,
                specificationPercentages,
                setSpecificationPercentages,
                servicesPercentages,
                setServicesPercentages,
                paymentPercentages,
                setPaymentPercentages,
                profileMapPercentage,
                setProfileMapPercentage,
                specificationSubPrice,
                serviceSubPrice,
                paymentSubPrice,
                saveOptions,
                setSaveOptions,
            }}
        >
            {children}
        </clientContext.Provider>
    );
};

export default ClientProvider;
