import styled from 'styled-components';

export const Container = styled.div`
    margin: 4.8rem auto;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    max-width: 80vw;
    padding-bottom: 30px;
    display: grid;
    min-width: fit-content;
    ${'' /* justify-content: center; */}
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    grid-template-areas:
        'title'
        'input1'
        'input2'
        'button';

    @media screen and (max-width: 700px) {
        grid-template-areas:
            'title'
            'input1'
            'input2'
            'button';
        max-width: 80vw;
        margin-top: 100px;
        margin-bottom: 50px;
        max-width: 94vw;
        padding-left: 0px;
    }
`;

export const StyledInput1 = styled.input`
    grid-area: input1;
    border: none;
    border: 1px solid #bdbdbd;
    font-size: 16px;
    height: 40px;
    margin: 10px 0;
    text-align: center;
    color: #757575;
    border-radius: 8px;
    letter-spacing: 1.2px;
    width: 16rem;
    transform: translateX(13%);
    transition: 0.3s;
    font-family: 'Roboto', sans-serif;
    &:focus {
        border: 1px solid #ff654c;
        outline: none;
    }
    @media screen and (max-width: 650px) {
        min-width: 0;
    }
`;

export const StyledInput2 = styled.input`
    grid-area: input2;
    border: none;
    border: 1px solid #bdbdbd;
    font-size: 16px;
    height: 40px;
    margin: 10px;
    text-align: center;
    letter-spacing: 1.2px;
    color: #757575;
    border-radius: 8px;
    width: 16rem;
    transform: translateX(13%);
    transition: 0.3s;
    font-family: 'Roboto', sans-serif;
    &:focus {
        border: 1px solid #ff654c;
        outline: none;
    }
    @media screen and (max-width: 650px) {
        min-width: 0;
    }
`;

export const Title = styled.div`
    grid-area: title;
    border-bottom: 1px solid #e0e0e0;
    font-size: 22px;
    text-align: left;
    padding: 10px 10px 10px 18px;
    font-weight: 500;
    color: #444446;
    font-family: 'Roboto';
    @media screen and (max-width: 759px) {
        justify-content: center;
        text-align: center;
    }
`;

export const EachField = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px;
    flex: 0 0 50%;
`;

export const PasswordTitle = styled.div`
    margin-top: 2rem;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #4f4f4f;
    transform: translateY(20px);
`;
