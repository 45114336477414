import { useState, useEffect, useContext } from 'react';
import React from 'react';

import {
    Container,
    Column1,
    Column3,
    Row1,
    Row2,
    Row3,
    ContainerRow,
    Title,
    InvisibleContainer,
} from './points-program.styles';

/* Material UI */
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

/* Components */
import TablePointsProgram from '../table-points-program/table-points-program.component';

/* API */
import { findAllPointsProgram } from '../../api/ApiPointsProgram';

/* Context */
import { authContext } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '50%',
        '@media (max-width: 759px)': {
            margin: '0.5rem auto !important',
            textAlign: 'center',
        },
        '@media (max-width: 680px)': {
            paddingTop: 20,
            margin: '0.5rem auto !important',
            textAlign: 'center',
        },
    },
    subtotal: {
        padding: '20px',
        fontWeight: 400,
        '@media (max-width: 740px)': {
            fontSize: '1.2rem',
            margin: '0.5rem auto !important',
            textAlign: 'center',
            fontWeight: 400,
        },
    },
    typography: {
        display: 'grid',
        fontFamily: 'Roboto, sans-serif',
        margin: '0 auto',
        letterSpacing: 0.8,
        fontWeight: 300,
        '@media (max-width: 740px)': {
            fontSize: '1.2rem',
            margin: '0.5rem auto !important',
            textAlign: 'center',
            fontWeight: 400,
        },
    },
    textControl: {
        width: '50%',
        '@media (max-width: 740px)': {
            width: '60%',
            margin: '1rem auto !important',
            textAlign: 'center',
        },
    },
    discountValue: {
        '@media (max-width: 740px)': {
            textAlign: 'center',
            margin: 'auto',
            fontWeight: 600,
        },
    },
    switchControl: {
        paddingLeft: '50px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        '@media (max-width: 740px)': {
            display: 'block',
            paddingLeft: '0px',
        },
    },
}));

export default function PointsProgram() {
    const { auth } = useContext(authContext);

    const [program, setProgram] = useState({
        name: '',
        pointsProgramId: 1,
        baseValue: 0,
    });

    const [programs, setPrograms] = useState([]);
    const [pointAmount, setPointAmount] = useState('');

    const [error, setError] = useState(false);

    // State para o Collapse
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const defaultPropsProgram = {
        options: programs,
        value: program,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setProgram({
                name: newValue.name,
                programId: newValue.pointsProgramId,
                baseValue: newValue.baseValue,
            });
        },
    };

    useEffect(() => {
        const callApiFindAllPointsProgram = async () => {
            const response = await findAllPointsProgram(auth);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setPrograms(payload);

            setProgram(payload[0]);
        };

        try {
            callApiFindAllPointsProgram();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    const validate = ({ target }) => {
        if (target.value <= 0) {
            setPointAmount(0);
            setError(true);
            return;
        }
        setError(false);
        setPointAmount(target.value);
    };

    const classes = useStyles();

    return (
        <>
            <InvisibleContainer id="points-program-container"></InvisibleContainer>

            <Container>
                <ContainerRow>
                    <Column1>
                        <Title>
                            Programa de pontos{' '}
                            <FormControlLabel
                                className={classes.switchControl}
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                    />
                                }
                                label="Mostrar"
                            />
                        </Title>
                    </Column1>
                </ContainerRow>
                <Collapse in={checked} unmountOnExit>
                    <ContainerRow>
                        <Column1>
                            <Row1>
                                <Typography
                                    className={classes.typography}
                                    variant="h6"
                                >
                                    {' '}
                                    Programas:{' '}
                                </Typography>
                                <Autocomplete
                                    {...defaultPropsProgram}
                                    id="input-program"
                                    autoSelect
                                    disableClearable
                                    className={classes.formControl}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Row1>
                            <Row2>
                                <Typography
                                    className={classes.typography}
                                    variant="h6"
                                >
                                    {' '}
                                    Quantia de pontos resgatados:{' '}
                                </Typography>
                                <TextField
                                    id="points-quantity"
                                    className={classes.textControl}
                                    style={{ margin: 8 }}
                                    placeholder="Informe aqui"
                                    margin="normal"
                                    inputMode="numeric"
                                    type="number"
                                    error={error}
                                    value={pointAmount}
                                    onChange={(event) => validate(event)}
                                />
                            </Row2>
                            <Row3>
                                <Typography
                                    variant="h6"
                                    className={classes.subtotal}
                                >
                                    Valor em reais:{' '}
                                    {(
                                        program.baseValue * pointAmount
                                    ).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Typography>
                            </Row3>
                        </Column1>

                        <Column3>
                            <TablePointsProgram baseValue={program.baseValue} />
                        </Column3>
                    </ContainerRow>
                </Collapse>
            </Container>
        </>
    );
}
