import { useState, useContext, useEffect } from 'react';

import { DrawerAdjustment } from './update-proposal.styles';

/* Components */
import Header from '../../components/header/header.component';
import FindClientModal from '../../components/find-client-modal/find-client-modal.component';
import GeneralData from '../../components/general-data/general-data.component';
import Specification from '../../components/specification/specification.component';
import ServicesIncluded from '../../components/services-included/services-included.component';
import PaymentMethod from '../../components/payment-method/payment-method.component';
import SoyValue from '../../components/soy-value/soy-value.component';
import PointsProgram from '../../components/points-program/points-program.component';
import GeneralSummary from '../../components/general-summary/general-summary-component';
import ToggleMenu from '../../components/toggle-menu/toggle-menu.component';

/* Context */
import StatePriceProvider from '../../contexts/StatePriceContext';
import ClientProvider from '../../contexts/ClientContext';
import { authContext } from '../../contexts/AuthContext';

/* API */
import { findPricingProposal } from '../../api/ApiProposal';
import { findProposal } from '../../api/ApiProposal';

export default function UpdateProposal(props) {
    const { auth } = useContext(authContext);

    const [proposal, setProposal] = useState(null);
    const [proposalStatePrice, setProposalStatePrice] = useState(null);

    const proposalId = props.history.location.proposalId;

    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    useEffect(() => {
        const callFindPricingProposal = async () => {
            const response = await findPricingProposal(auth, proposalId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setProposalStatePrice(payload[0]);
        };

        try {
            if (proposalId) callFindPricingProposal();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, proposalId]);

    // talvez criar um context, analisar se tem mto prop drilling e se todos os componentes abaixo vão receber
    useEffect(() => {
        const callFindProposal = async () => {
            const response = await findProposal(auth, proposalId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setProposal(payload[0]);
        };

        try {
            if (proposalId) callFindProposal();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, proposalId]);

    return (
        <DrawerAdjustment>
            <StatePriceProvider proposalStatePrice={proposalStatePrice}>
                <ClientProvider>
                    <ToggleMenu />
                    <Header navBar={true} />
                    <FindClientModal proposalData={proposal} />
                    <GeneralData
                        proposalStatePrice={proposalStatePrice}
                        proposalData={proposal}
                    />
                    <Specification proposalData={proposal} />
                    <ServicesIncluded proposalData={proposal} />
                    <PaymentMethod proposalData={proposal} />
                    <SoyValue proposalData={proposal} />
                    <PointsProgram />
                    <GeneralSummary proposalData={proposal} />
                </ClientProvider>
            </StatePriceProvider>
        </DrawerAdjustment>
    );
}
