import { useContext } from 'react';
import clsx from 'clsx';

/* Material ui*/
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/* Icons */
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

/* Style */
import { useStyles } from './header-components.styles';

/* Components */
import ItemsList from './itemsList.component';
import { StyledDrawer } from './../header.component';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

export default function HeaderMobile({ toggleDrawer, open, onLogOut, navBar }) {
    const classes = useStyles();
    const { auth } = useContext(authContext);

    return (
        <AppBar>
            <Toolbar>
                <>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}
                        edge="start"
                        className={clsx(
                            classes.menuButton,
                            open && classes.hide
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <StyledDrawer
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <ItemsList toggleDrawer={toggleDrawer} />
                    </StyledDrawer>
                </>
                <div className={classes.grow} />
                <div className={classes.grow} />
                <Typography variant="body1">{auth.data.user.name}</Typography>
                <IconButton
                    className="logo-container"
                    aria-label="show 4 new mails"
                    color="inherit"
                    component={Button}
                    to="/notifications"
                    onClick={onLogOut}
                >
                    <ExitToAppIcon className={classes.iconSm} />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}
