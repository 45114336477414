import { Text, Value, Empty } from './table-summary-value.styles';

export default function TableSummaryValue({
    value,
    areaMade,
    grid,
    points,
    interestSum,
    buyIn = false,
}) {
    return (
        <>
            {value !== null && areaMade && grid ? (
                <Text>
                    <Value value={Number(value)}>
                        {value > 0 ? '+' : ''}
                        {(areaMade * grid * (value / 100)).toLocaleString(
                            'pt-br',
                            {
                                style: 'currency',
                                currency: 'BRL',
                            }
                        )}
                    </Value>
                </Text>
            ) : null}

            {interestSum ? (
                <Text>
                    <Value value={Number(value)}>
                        {value > 0 ? '+' : ''}
                        {value.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </Value>
                </Text>
            ): null}

            {buyIn ? (
                <Text>
                    <Value value={Number(value)}>
                        {value > 0 ? '+' : ''}
                        {value.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </Value>
                </Text>
            ) : null}
            {/* Primeiro parentese é acionado quando a tela renderiza sem cliente selecionado */}
            {/* Segundo parentese é acionado quando um valor é zero ou não existe e um cliente já foi selecionado */}
            {(!points && !grid && !areaMade && !buyIn && !interestSum) ||
            (value !== 0 && !value && !points) ? (
                <Empty> --- </Empty>
            ) : null}
        </>
    );
}
