import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { blue } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Backdrop from '@material-ui/core/Backdrop';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

import {
    Container,
    Title,
    Acumulator,
    AcumulatorTitle,
    AcumulatorTable,
    ContainerFilters,
    SearchButton,
} from './edit-proposal-table.styles';

/* Context */
import { authContext } from '../../contexts/AuthContext';

/* API */
import { findSaPermission } from '../../api/ApiPermission';

import { 
    findSaStatesAndProposals, 
    findAllSaAndProposals, 
    findAllSaAndProposalsFromState 
} from '../../api/ApiSa';

import {
    findProposal,
    updateProposalStatus,
    findPricingProposal,
    findStatusAndProposals,
    findProposalsWithFilters
} from '../../api/ApiProposal';

import {
    findClientById,
    findAllAndProposals,
} from '../../api/ApiClient';

import { 
    findAllStatesAndProposals,
    findGridsAndProposals
} from '../../api/ApiState';

import { findGoalYears } from '../../api/ApiGoal';

/* Components */
import PopUp from '../popup/popup.component';
import ActionButtons from '../action-buttons/action-buttons.component';

/* Utils  */
import getPermission from '../../utils/getPermission';
import { BlobProvider } from '@react-pdf/renderer';
import UpdateProposalPdf from '../../utils/pdf/react-pdf/updateProposalPdf';
import { isValidDate } from '../../utils/isValidDate';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    switch: {
        display: 'none',
    },
    formControl: {
        width: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(11rem, 1fr))',
    },
    insideFormControl: {
        width: '10rem',
        margin: '30px 10px',
    },
    stateDropdownPaper: {
        width: '250px',
        overflowY: 'auto',
        marginLeft: '-50px',
        marginTop: theme.spacing(0.5),
    },
    saDropdownPaper: {
        width: '375px',
        overflowY: 'auto',
        marginLeft: '-115px',
        marginTop: theme.spacing(0.5),
    },
    clientDropdownPaper: {
        width: '550px',
        overflowY: 'auto',
        marginLeft: '-200px',
        marginTop: theme.spacing(0.5),
    },
    gridDropdownPaper: {
        width: '250px',
        overflowY: 'auto',
        marginLeft: '-45px',
        marginTop: theme.spacing(0.5),
    },
    statusDropdownPaper: {
        width: '250px',
        overflowY: 'auto',
        marginLeft: '-50px',
        marginTop: theme.spacing(0.5),
    },
    PDF: {
        height: 50,
        marginTop: 20,
        fontWeight: 'bolder',
        fontSize: '18px',
        width: '60%',
        color: '#fff',
        backgroundColor: 'red',
        marginBottom: theme.spacing(2),
        textTransform: 'capitalize',
        border: '1px solid grey',
        borderRadius: '10px',
    },
    paper: {
        borderRadius: '10px',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    paperModal: {
        '& .MuiFormLabel-root': {
            marginLeft: '2rem',
            fontSize: '1.5rem',
            fontWeight: '700',
        },
        position: 'relative',
        marginTop: '14.5%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: '2rem',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    paperModal2: {
        '& .MuiFormLabel-root': {
            marginTop: '-1.0rem',
            marginLeft: 0,
            fontSize: '1rem',
            fontWeight: '500',
        },
        marginTop: '1.2rem',
        marginLeft: '0rem',
        width: 305,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    modal: {
        width: 370,
        padding: '2rem',
        marginLeft: '-1.1rem',
        paddingBottom: '1rem',
        textAlign: 'left',
        fontSize: '1rem',
    },
    clientName: {
        fontFamily: 'Roboto',
        fontWeight: '400 !important',
        width: 'available',
        letterSpacing: '-0.5px',
        fontSize: 15,
        color: '#000',
    },
    gridColumn: {
        width: '10%',
    },
    searchIcon: {
        color: 'rgb(215, 59, 48)',
        fontSize: '32px',
    },
    input: {
        color: 'white',
    },
    datePickerFirst: {
        justifySelf: 'center',
        width: '70%',
        alignSelf: 'center',
    },
    datePickerSecond: {
        justifySelf: 'center',
        width: '70%',
        alignSelf: 'center',
    },
    editIcon: {
        color: '#524e4e',
        fontSize: '1.4rem',
        transform: 'translate(5px, 4px)',
    },
    pdfIcon: {
        color: '#524e4e',
        fontSize: '1.4rem',
        transform: 'translate(5px, 4px)',
    },
    backdrop: {
        backgroundColor: 'rgba(100, 100, 100, 0.25)',
      },
}));

const theme = createTheme({
    overrides: {
        CurrencyTextField: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                color: 'orange',
                '&$focused': {
                    // increase the specificity for the pseudo class
                    color: 'blue',
                },
            },
        },
    },
    palette: {
        primary: {
            main: 'rgb(215, 69, 48)',
        },
        secondary: {
            main: 'rgb(53, 199, 89)',
        },
    },
});

const theme2 = createTheme({
    palette: {
        primary: { main: blue[900] },
    },
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const headCells = [
    {
        id: 'proposalId',
        label: 'ID',
        align: 'center',
        minWidth: 280,
    },
    {
        id: 'clientName',
        label: 'Nome',
        align: 'center',
        minWidth: 280,
    },
    {
        id: 'grid',
        label: 'Grid',
        align: 'center',
        minWidth: '220',
    },
    {
        id: 'haMade',
        label: 'Área contratada',
        minWidth: 40,
        align: 'left',
    },
    {
        id: 'initialAmount',
        label: 'Valor inicial',
        minWidth: '40',
        align: 'left',
    },
    {
        id: 'initialAmountPerHec',
        label: 'Valor inicial por hectare',
        minWidth: '40',
        align: 'left',
    },
    {
        id: 'finalAmount',
        label: 'Valor final',
        align: 'left',
        minWidth: 180,
    },
    {
        id: 'finalAmountPerHa',
        label: 'Valor final por hectare',
        align: 'left',
        minWidth: 180,
    },
    {
        id: 'discount',
        label: 'Desconto ou Acréscimo',
        align: 'center',
        minWidth: 180,
    },
    {
        id: 'createdAt',
        label: 'Data de criação',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'creatorName',
        label: 'Criada por',
        align: 'center',
        minWidth: 120,
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 20,
        align: 'left',
    },
];

const totalTableHeader = [
    'Área Contratada',
    'Valor Inicial',
    'Valor Inicial/Hectare',
    'Valor Final',
    'Valor Final/Hectare',
    'Desconto ou Acréscimo',
];

export default function EditProposalTable({ staticRows, isDashboard }) {
    let history = useHistory();

    const [proposals, setProposals] = useState([]);

    const { auth } = useContext(authContext);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(staticRows ? staticRows : 5);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [open, setOpen] = useState(false);
    const [openPdfModal, setOpenPdfModal] = useState(false);

    const [proposalId, setProposalId] = useState(null);
    const [lastSelectClicked, setLastSelectClicked] = useState(null);
    const [finalValue, setFinalValue] = useState(0);
    const [observation, setObservation] = useState('');

    // filtros
    const [client, setClient] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [sa, setSa] = useState([]);
    const [saList, setSaList] = useState([]);
    const [grid, setGrid] = useState(null);
    const [gridOptions, setGridOptions] = useState([]);
    const [status, setStatus] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [state, setState] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [appliedComercialYear, setAppliedComercialYear] = useState(null);

    // states para gerenciamento dos filtros
    const [showStateFilter, setShowStateFilter] = useState(false);
    const [showSaFilter, setShowSaFilter] = useState(false);
    const [showClientFilter, setShowClientFilter] = useState(false);
    const [showAllStatesFilter, setShowAllStatesFilter] = useState(false);

    // controle e dados do PDF
    const [ready, setReady] = useState(false);
    const [proposal, setProposal] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [proposalStatePrice, setProposalStatePrice] = useState(null);

    // Verificar o filtro selecionado
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedGrid, setSelectedGrid] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedSa, setSelectedSa] = useState(null);
    const [selectedComercialYear, setSelectedComercialYear] = useState(null);
    const [years, setYears] = useState([]);


    const classes = useStyles();

    const proposalTotalizer = useMemo(() => {
        return proposals.reduce((parcelsAcc, curr, index) => {
            if (index === 0)
                return {
                    haMade: curr.haMade,
                    initialAmount: +curr.initialAmount,
                    initialAmountPerHec: curr.initialAmount / curr.haMade,
                    finalAmount: +curr.finalAmount,
                    finalAmountPerHec: curr.finalAmount / curr.haMade,
                    discount: curr.finalAmount - curr.initialAmount,
                };

            return {
                haMade: +parcelsAcc.haMade + +curr.haMade,
                initialAmount: +parcelsAcc.initialAmount + +curr.initialAmount,
                initialAmountPerHec:
                    (+parcelsAcc.initialAmount + +curr.initialAmount) /
                    (+parcelsAcc.haMade + +curr.haMade),
                finalAmount: +parcelsAcc.finalAmount + +curr.finalAmount,
                finalAmountPerHec:
                    (+parcelsAcc.finalAmount + +curr.finalAmount) /
                    (+parcelsAcc.haMade + +curr.haMade),
                discount:
                    +parcelsAcc.discount +
                    (+curr.finalAmount - +curr.initialAmount),
            };
        }, []);
    }, [proposals]);

    // configura os estados de gerenciamento de filtros
    useEffect(() => {
        const callCheckPermission = async () => {
            const response = await findSaPermission(
                auth,
                state !== null && auth.data.user.permissionId !== 1
                    ? state.stateId
                    : auth.data.user.stateId,
                auth.data.user.saId
            );
            setShowStateFilter(getPermission(response, 'Filtro de Estado'));
            setShowSaFilter(getPermission(response, 'Filtro de AS'));
            setShowClientFilter(getPermission(response, 'Filtro de Clientes'));
            setShowAllStatesFilter(
                getPermission(response, 'Filtro de Todos Estados')
            );
        };

        try {
            callCheckPermission();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, state]);

    useEffect(() => {
        const getFilterDates = () => {
            if (!appliedComercialYear) {
                return { start: null, end: null };
            }
    
            const start = new Date(appliedComercialYear - 1, 10, 1);
            const end = new Date(appliedComercialYear, 9, 31);
            return { start, end };
        };
    
        const callApiFindProposalsWithFilters = async () => {
            try {
                const { start, end } = getFilterDates();
    
                const saId =
                    sa?.saId ||
                    (auth.data.user.permissionId !== 1 && !showSaFilter && !state
                        ? auth.data.user.saId
                        : state
                        ? null
                        : auth.data.user.saId);

                const response = await findProposalsWithFilters(
                    auth,
                    saId,
                    isDashboard,
                    client?.clientId,
                    grid?.grid,
                    status?.status,
                    state?.stateId,
                    start,
                    end
                );
    
                const { message, payload } = response.data;
    
                if (response.status !== 200) {
                    throw new Error(message);
                }
    
                setProposals(payload);
            } catch (err) {
                console.log(err);
            }
        };
    
        try {
            if(state) 
                callApiFindProposalsWithFilters();
            else
                setProposals([]);
        } catch (err) {
            console.log(err);
        }
    }, [
        auth,
        isDashboard,
        client,
        appliedComercialYear,
        sa,
        grid,
        status?.status,
        state,
        showAllStatesFilter,
        showSaFilter,
    ]);
    
    

    // busca os clientes do estado do AS ou do input
    useEffect(() => {
        const callApiFindAllClients = async () => {
            let response;
            response = await findAllAndProposals(
                auth, 
                selectedState?.stateId ? selectedState.stateId : null,
                selectedSa?.saId ? selectedSa.saId : showSaFilter ? null : auth.data.user.saId,
                selectedComercialYear ? selectedComercialYear : null
            );
            
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setClientList(payload); 
        };

        try {
            if(state && selectedState) callApiFindAllClients();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedComercialYear, selectedSa, selectedState, showSaFilter]);

    useEffect(() => {
        const callApiFindStatusAndProposals = async () => {
            let response;
            response = await findStatusAndProposals(auth, 
                state?.stateId ? state.stateId : null,
                sa?.saId ? sa.saId : selectedSa?.saId ? selectedSa.saId : showSaFilter ? null : auth.data.user.saId,
                client?.clientId ? client.clientId : selectedClient?.clientId ? selectedClient.clientId : null,
                selectedComercialYear ? selectedComercialYear : null
            );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);
            setStatusList(payload)
        };

        try {
            if (state && selectedState) callApiFindStatusAndProposals();
        } catch (err) {
            console.log(err);
        }
    }, [sa, selectedComercialYear, selectedSa, state, selectedState, client, selectedClient, showSaFilter]);

    useEffect(() => {
        const callFindGoalYears = async () => {
            const response = await findGoalYears(auth);
            let { message, payload } = response.data;
            if (response.status !== 200) throw Error(message);
    
            payload = payload.map((curr) => String(curr.year));
    
            setYears(payload);
        };
    
        try {
            callFindGoalYears();
        } catch (err) {
         
        }
    }, [auth, selectedSa]);

    // busca os ASs do estado
    useEffect(() => {
        const callApiFindAllSaFromState = async () => {
            if (selectedState) {
                let response;
                if (selectedState.stateId === 0) {
                    response = await findAllSaAndProposals(auth, selectedComercialYear ? selectedComercialYear : null);
                } else {
                    response = await findAllSaAndProposalsFromState(
                        auth,
                        selectedState?.stateId,
                        selectedComercialYear ? selectedComercialYear : null
                    );
                }
                const { message, payload } = response.data;

                if (response.status !== 200) throw Error(message);
                setSaList(payload);
            }
        };

        try {
            callApiFindAllSaFromState();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedComercialYear, selectedState]);

    // busca os grids do estado do AS
    useEffect(() => {
        const callApiFindGridsAndProposals = async () => {
            const response = await findGridsAndProposals(
                auth,
                state?.stateId,
                sa?.saId ? sa.saId : selectedSa?.saId ? selectedSa.saId : showSaFilter ? null : auth.data.user.saId,
                client?.clientId ? client.clientId : selectedClient?.clientId ? selectedClient.clientId : null,
                selectedComercialYear ? selectedComercialYear : null
            );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setGridOptions(payload);
        };

        try {
            if(state && selectedState) callApiFindGridsAndProposals();
        } catch (err) {
            console.log(err);
        }
    }, [state, selectedComercialYear, selectedState, sa, selectedSa, client, selectedClient, showSaFilter]);

    // busca todos os estados, futuramente deve buscar os estados que o usuário possui
    useEffect(() => {
        const callApiFindAllStates = async () => {
            const response = await findAllStatesAndProposals(auth, selectedComercialYear ? selectedComercialYear : null  );
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setStateList(payload);
            setState(payload[0])
            setSelectedState(payload[0])  
        };

        const callApiFindSaState = async () => {
            const response = await findSaStatesAndProposals(auth, auth.data.user.saId, selectedComercialYear ? selectedComercialYear : null);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setStateList(payload);
            setState(payload[0])
            setSelectedState(payload[0])
        };

        try {
            if (auth.data.user.permissionId !== 1) callApiFindSaState();
            else callApiFindAllStates();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedComercialYear]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, proposals.length - page * rowsPerPage);

    const handleStatusChange = (event, proposalId, finalAmount) => {
        setProposalId(proposalId);
        setFinalValue(finalAmount);
        setLastSelectClicked(event.target.value);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        window.location.reload();
    };

    const handleOpenPdf = (proposalId) => {
        setOpenPdfModal(true);
        setProposalId(proposalId);
    };
    const handleClosePdf = () => {
        setOpenPdfModal(false);
    };

    const handleProposal = async (status) => {
        try {
            const response = await updateProposalStatus(
                auth,
                proposalId,
                lastSelectClicked,
                status === 'sent' ? null : finalValue
            );
            if (response.status !== 200) throw Error(response.data.message);
            setPopupTitle('Sucesso');
            setPopupText('Proposta atualizada com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Proposta não pode ser atualizada: ${error.message}`);
            setPopup(true);
            setSuccess(1);
        }
    };

    const body = (
        <div className={classes.paperModal}>
            <CurrencyTextField
                className={classes.modal}
                label="Valor Final"
                disabled={lastSelectClicked === 'Recusada' ? true : false}
                variant="standard"
                value={finalValue}
                currencySymbol="$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                onChange={(event, value) => setFinalValue(value)}
            />
            <TextField
                label="Observações sobre o novo valor final"
                className={classes.paperModal2}
                multiline
                minRows={1}
                maxRows={7}
                value={observation}
                onChange={(event) => {
                    setObservation(event.target.value);
                }}
            />
            <ThemeProvider theme={theme}>
                <ActionButtons
                    label={
                        lastSelectClicked === 'Aceita'
                            ? 'Aprovar Proposta'
                            : lastSelectClicked === 'Recusada'
                            ? 'Recusar Proposta'
                            : 'Enviar Proposta'
                    }
                    color={
                        lastSelectClicked === 'Recusada'
                            ? 'primary'
                            : 'secondary'
                    }
                    startIcon={
                        lastSelectClicked === 'Recusada' ? (
                            <ThumbDownIcon />
                        ) : (
                            <ThumbUpIcon />
                        )
                    }
                    onClick={handleProposal}
                />
            </ThemeProvider>
        </div>
    );

    useEffect(() => {
        const callFindPricingProposal = async () => {
            const response = await findPricingProposal(auth, proposalId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setProposalStatePrice(payload[0]);
        };

        try {
            if (proposalId) callFindPricingProposal();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, proposalId]);

    useEffect(() => {
        const callFindProposal = async () => {
            const response = await findProposal(auth, proposalId);

            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setProposal(payload[0]);
        };

        try {
            if (proposalId) callFindProposal();
        } catch (error) {
            console.log(error.message);
        }
    }, [auth, proposalId]);

    useEffect(() => {
        const callApiFindClientById = async () => {
            const response = await findClientById(auth, proposal?.clientId);
            const { message, payload } = response.data;

            if (response.status !== 200) throw Error(message);

            setClientData(payload[0]);
        };

        try {
            if (proposal?.clientId) callApiFindClientById();
        } catch (err) {
            console.log(err);
        }
    }, [auth, proposal]);

    const sentBody = (
        <div className={classes.paperModal}>
            {
                <ThemeProvider theme={theme}>
                    <ActionButtons
                        label="Atualizar Status"
                        color="secondary"
                        onClick={() => handleProposal('sent')}
                        startIcon={<RefreshIcon />}
                    />
                    {ready && (
                        <BlobProvider
                            document={
                                <UpdateProposalPdf
                                    proposal={proposal}
                                    client={clientData}
                                    proposalStatePrice={proposalStatePrice}
                                    saName={proposal.saName}
                                />
                            }
                        >
                            {({ url }) => (
                                <a href={url} target="_blank" rel="noreferrer">
                                    <ThemeProvider theme={theme2}>
                                        {' '}
                                        <ActionButtons
                                            label="Visualizar PDF"
                                            className={classes.PDF}
                                            color="primary"
                                            startIcon={<PictureAsPdfIcon />}
                                            onClick={() => setReady(false)}
                                        />
                                    </ThemeProvider>
                                </a>
                            )}
                        </BlobProvider>
                    )}
                    {!ready && (
                        <ActionButtons
                            className={classes.PDF}
                            color="primary"
                            label="Carregar PDF"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => {
                                setReady(false);
                                setTimeout(() => {
                                    setReady(true);
                                }, 1);
                            }}
                        />
                    )}
                </ThemeProvider>
            }
        </div>
    );
    const pdfModalBody = (
        <div className={classes.paperModal}>
            {
                <ThemeProvider theme={theme}>
                    {ready && (
                        <BlobProvider
                            document={
                                <UpdateProposalPdf
                                    proposal={proposal}
                                    client={clientData}
                                    proposalStatePrice={proposalStatePrice}
                                    saName={proposal.saName}
                                />
                            }
                        >
                            {({ url }) => (
                                <a href={url} target="_blank" rel="noreferrer">
                                    <ThemeProvider theme={theme2}>
                                        {' '}
                                        <ActionButtons
                                            label="Visualizar PDF"
                                            className={classes.PDF}
                                            color="primary"
                                            startIcon={<PictureAsPdfIcon />}
                                            onClick={() => setReady(false)}
                                        />
                                    </ThemeProvider>
                                </a>
                            )}
                        </BlobProvider>
                    )}
                    {!ready && (
                        <ActionButtons
                            className={classes.PDF}
                            color="primary"
                            label="Carregar PDF"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => {
                                setReady(false);
                                setTimeout(() => {
                                    setReady(true);
                                }, 2);
                            }}
                        />
                    )}
                </ThemeProvider>
            }
        </div>
    );

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);

    const defaultPropsComercialYear = {
        options: years,
        value: selectedComercialYear,
        getOptionLabel: (option) => (option ? option.toString() : ''),
        getOptionSelected: (option) => (option ? option.toString() : ''),
        onChange: (event, newValue) => setSelectedComercialYear(newValue),
    };

    const defaultPropsClient = {
        options: clientList,
        value: selectedClient,
        getOptionLabel: (option) => option && option.nameAndProposals,
        getOptionSelected: (option) => option && option.nameAndProposals,
        onChange: (event, newValue) => setSelectedClient(newValue)
    };

    const defaultPropsGrid = {
        options: gridOptions,
        value: selectedGrid,
        getOptionLabel: (option) => option && option.gridAndProposals,
        getOptionSelected: (option) => option && option.gridAndProposals,
        onChange: (event, newValue) => setSelectedGrid(newValue),
    };

    const defaultPropsStatus = {
        options: statusList,
        value: selectedStatus,
        getOptionLabel: (option) => option && option.statusAndProposals,
        getOptionSelected: (option) => option && option.statusAndProposals,
        onChange: (event, newValue) => setSelectedStatus(newValue),
    };

    const defaultPropsState = {
        options: stateList,
        value: selectedState,
        getOptionLabel: (option) => option && option.uf,
        getOptionSelected: (option) => option && option.uf,
        onChange: (event, newValue) => {
            if (!newValue) {
                if (auth.data.user.permissionId !== 3) {
                    setSa(null);
                    setSelectedSa(null);
                    setSaList([]);
                }
                setGridOptions([]);
                setGrid(null);
                setSelectedGrid(null); 
                setStatusList([]);
                setStatus(null);
                setSelectedStatus(null);
            }
            setClientList([])
            setSelectedClient(null);
            setClient(null)
            setSelectedState(newValue);
        },
    };

    const defaultPropsSa = {
        options: saList,
        value: selectedSa,
        getOptionLabel: (option) => option && option.nameAndProposals,
        getOptionSelected: (option) => option && option.nameAndProposals,
        onChange: (event, newValue) => setSelectedSa(newValue),
    };

    const handleSearchClick = () => {
        setClient(selectedClient);
        setGrid(selectedGrid);
        setStatus(selectedStatus);
        setState(selectedState);
        setSa(selectedSa);
        setAppliedComercialYear(selectedComercialYear);
    };

 
    return (
        <>
            {!staticRows && (
                <Container>
                    <ContainerFilters>
                        <div className={classes.formControl}>
                        <Autocomplete
                                {...defaultPropsComercialYear}
                                id="year-selector"
                                autoSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.insideFormControl}
                                        label="Ano Comercial"
                                        variant="outlined"
                                    />
                                )}
                            />
                            {showStateFilter && (
                                <Autocomplete
                                    {...defaultPropsState}
                                    id="input-states"
                                    autoSelect
                                    PaperComponent={({ children }) => (
                                        <Paper elevation={2} className={classes.stateDropdownPaper}>
                                          {children}
                                        </Paper>
                                    )}
                                    noOptionsText={'Não há Estados'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label= "Estado"
                                            required={!state}
                                            className={
                                                classes.insideFormControl
                                            }
                                            variant="outlined"
                                            error={!state}
                                            helperText={
                                                !state
                                                ? 'Selecione um estado.'
                                                : ''
                                            }
                                        />
                                    )}
                                />
                            )}
                            
                            {showSaFilter && (
                                <Autocomplete
                                    {...defaultPropsSa}
                                    id="input-sas"
                                    autoSelect
                                    PaperComponent={({ children }) => (
                                        <Paper elevation={2} className={classes.saDropdownPaper}>
                                          {children}
                                        </Paper>
                                    )}
                                    noOptionsText={'Não há RTV\'s para esse Estado.'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={
                                                classes.insideFormControl
                                            }
                                            label="RTV"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                            {showClientFilter && (
                                <Autocomplete
                                    {...defaultPropsClient}
                                    id="input-clients"
                                    autoSelect
                                    noOptionsText={'Não há clientes para esse Estado ou para esse RTV.'}
                                    PaperComponent={({ children }) => (
                                        <Paper elevation={2} className={classes.clientDropdownPaper}>
                                          {children}
                                        </Paper>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={
                                                classes.insideFormControl
                                            }
                                            label="Cliente"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                            <Autocomplete
                                {...defaultPropsGrid}
                                id="input-grids"
                                autoSelect
                                noOptionsText={'Não há Grids'}
                                PaperComponent={({ children }) => (
                                    <Paper elevation={2} className={classes.gridDropdownPaper}>
                                      {children}
                                    </Paper>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.insideFormControl}
                                        label="Grid"
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Autocomplete
                                {...defaultPropsStatus}
                                id="input-status"
                                autoSelect
                                PaperComponent={({ children }) => (
                                    <Paper elevation={2} className={classes.statusDropdownPaper}>
                                      {children}
                                    </Paper>
                                )}
                                noOptionsText={'Não há opções de status.'}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.insideFormControl}
                                        label="Status"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <SearchButton onClick={handleSearchClick}>
                            <SearchIcon className={classes.searchIcon} />
                        </SearchButton>
                    </ContainerFilters>
                </Container>
            )}
            <Container>
                <Acumulator>
                    <AcumulatorTitle>
                        {!isDashboard ? 'Total' : 'Total das propostas abertas'}
                    </AcumulatorTitle>
                    <AcumulatorTable>
                        <Table size="medium" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {totalTableHeader.map((header, index) => {
                                        return (
                                            <TableCell
                                                key={`${header}-${index}`}
                                                align="center"
                                            >
                                                {header}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    {Object.values(proposalTotalizer).map(
                                        (current, index) => {
                                            return (
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderBottom: 'none',
                                                    }}
                                                    key={`${current}-${index}`}
                                                >
                                                    {index === 0
                                                        ? current.toLocaleString(
                                                              'pt-br'
                                                          )
                                                        : current.toLocaleString(
                                                              'pt-br',
                                                              {
                                                                  style: 'currency',
                                                                  currency:
                                                                      'BRL',
                                                              }
                                                          )}
                                                </TableCell>
                                            );
                                        }
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AcumulatorTable>
                </Acumulator>

                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Title
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {staticRows
                                ? 'Últimas propostas abertas'
                                : 'Propostas'}
                        </Title>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(
                                        proposals,
                                        getComparator(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.clientName
                                                        )
                                                    }
                                                    key={row.createdAt}
                                                >
                                                    <TableCell align="left">
                                                        {row.proposalId}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.clientName
                                                        }
                                                        align="center"
                                                    >
                                                        {row.clientName}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.gridColumn
                                                        }
                                                        align="center"
                                                    >
                                                        {`${
                                                            row.gridName.split(
                                                                ' '
                                                            )[0]
                                                        } ${
                                                            row.gridName.split(
                                                                ' '
                                                            )[1]
                                                        } - ${row.gridvalue}`}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.haMade}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {numberFormat(
                                                            row.initialAmount
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {numberFormat(
                                                            row.initialAmount /
                                                                row.haMade
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {numberFormat(
                                                            row.finalAmount
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {numberFormat(
                                                            row.finalAmount /
                                                                row.haMade
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {numberFormat(
                                                            row.finalAmount -
                                                                row.initialAmount
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {new Date(
                                                            row.createdAt
                                                        ).toLocaleDateString()}
                                                        {' | '}
                                                        {new Date(
                                                            row.createdAt
                                                        ).getHours()}
                                                        {'h'}
                                                        {new Date(
                                                            row.createdAt
                                                        ).getUTCMinutes()}
                                                        {'min'}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.clientName
                                                        }
                                                        align="center"
                                                    >
                                                        {row.creatorName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.status ===
                                                            'Aceita' ||
                                                        (row.status ===
                                                            'Recusada' &&
                                                            auth.data.user
                                                                .permissionId !==
                                                                1) ? (
                                                            row.status
                                                        ) : (
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={
                                                                    row.status
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleStatusChange(
                                                                        event,
                                                                        row.proposalId,
                                                                        row.finalAmount
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem
                                                                    value="Em Andamento"
                                                                    disabled
                                                                >
                                                                    Em andamento{' '}
                                                                </MenuItem>

                                                                <MenuItem value="Enviada">
                                                                    Enviada
                                                                </MenuItem>
                                                                <MenuItem value="Aceita">
                                                                    Aceita
                                                                </MenuItem>
                                                                <MenuItem value="Recusada">
                                                                    Recusada
                                                                </MenuItem>
                                                            </Select>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.status ===
                                                            'Aceita' ||
                                                        row.status ===
                                                            'Recusada' ? (
                                                            <div>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleOpenPdf(
                                                                            row.proposalId
                                                                        )
                                                                    }
                                                                >
                                                                    <PictureAsPdfIcon
                                                                        className={
                                                                            classes.pdfIcon
                                                                        }
                                                                    />
                                                                </Button>
                                                                <Modal
                                                                    open={
                                                                        openPdfModal
                                                                    }
                                                                    onClose={
                                                                        handleClosePdf
                                                                    }
                                                                    aria-labelledby="simple-modal-title"
                                                                    aria-describedby="simple-modal-description"
                                                                    BackdropComponent={Backdrop}
                                                                    BackdropProps={{
                                                                        classes: {
                                                                        root: classes.backdrop,
                                                                        },
                                                                    }}
                                                                >
                                                                    {
                                                                        pdfModalBody
                                                                    }
                                                                </Modal>
                                                            </div>
                                                        ) : (
                                                            <Button
                                                                onClick={() =>
                                                                    history.push(
                                                                        {
                                                                            pathname:
                                                                                '/update-proposal',
                                                                            proposalId:
                                                                                row.proposalId,
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon
                                                                    className={
                                                                        classes.editIcon
                                                                    }
                                                                />
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height:
                                                    (dense ? 33 : 53) *
                                                    emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            labelRowsPerPage={'Linhas por página'}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            component="div"
                            count={proposals.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    checked={true}
                                    onChange={handleChangeDense}
                                />
                            }
                            label="Dense padding"
                        />
                    </Paper>
                    {lastSelectClicked === 'Enviada' ? (
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            {sentBody}
                        </Modal>
                    ) : (
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            {body}
                        </Modal>
                    )}

                    {popup ? (
                        <PopUp
                            title={popupTitle}
                            string={popupText}
                            success={success}
                        />
                    ) : null}
                </div>
            </Container>
        </>
    );
}
