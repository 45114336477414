import styled from 'styled-components/macro';

export const Value = styled.h2`
    color: ${({ value }) =>
        value === 0 ? '#444449' : value > 0 ? '#8C2F22' : '#107000'};
    font-weight: 400;
    font-family: 'Roboto';
    margin-right: 5px;
    font-size: 25px;
`;

export const Text = styled.div`
    font-family: Roboto;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Empty = styled.div`
    color: #a3a3a3;
    font-size: 30px;
    letter-spacing: 2px;
`;
