/* Generic button component, has a global button styles and depending on the flag parameter it adds the desired style */
import React from 'react';

/* Style */
import { CustomButtonContainer } from './custom-button.styles';

const CustomButton = ({ children, ...otherProps }) => (
    <CustomButtonContainer {...otherProps}> {children} </CustomButtonContainer>
);

export default CustomButton;
