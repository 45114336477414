import { useState, useEffect, useContext } from 'react';

/* Styles */
import {
    Container,
    Column1,
    Column2,
    Column3,
    ContainerRow,
    Title,
} from './soy-value.styles';

/* Material UI */
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

/* Context */
import { clientContext } from '../../contexts/ClientContext';

const useStyles = makeStyles((theme) => ({
    typography: {
        display: 'grid',
        fontFamily: 'Roboto, sans-serif',
        paddingLeft: 10,
        letterSpacing: 0.8,
        fontWeight: 300,
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },
    columnTwoContentSoy: {
        margin: '20px',
        '@media (max-width: 970px)': {
            margin: '12px',
            marginRight: 'auto',
            marginLeft: '2.2rem',
            justifyContent: 'start',
        },
        '@media (max-width: 759px)': {
            justifyContent: 'center',
            marginLeft: 'auto',
        },
    },
    urgency: {
        fontFamily: 'Roboto, sans-serif',
        display: 'grid',
        textAlign: 'center',
        color: '#5b5b5b',
        fontSize: '15px',
        width: '85%',
        margin: '3rem auto 3rem auto',
    },
    column3Title: {
        padding: 20,
        textAlign: 'center',
        '@media (max-width: 759px)': {
            display: 'none',
        },
    },
}));

export default function SoyValue({ proposalData }) {
    const { client, paymentSubPrice, saveOptions, setSaveOptions } =
        useContext(clientContext);

    const [soy, setSoy] = useState('');

    const VALUE_PER_HECTARE = client.clientId
        ? paymentSubPrice /
          (saveOptions?.areaMade ? saveOptions?.areaMade : client.ha)
        : null;

    const VALUE_PER_HECTARE_IN_BAGS =
        !soy || !client.clientId ? 0 : VALUE_PER_HECTARE / soy;

    useEffect(() => {
        setSaveOptions((options) => ({
            ...options,
            soyValue: soy,
        }));
    }, [setSaveOptions, soy]);

    useEffect(() => {
        if (proposalData) {
            setSoy(proposalData?.soyValue);
        }
    }, [proposalData]);

    const classes = useStyles();
    return (
        <Container>
            <ContainerRow>
                <Column1>
                    <Title variant="h5">Cálculo da Soja:</Title>
                </Column1>
                <Column3>
                    <Typography className={classes.column3Title} variant="h5">
                        Valor em sacas de soja:
                    </Typography>
                </Column3>
            </ContainerRow>
            <ContainerRow>
                <Column1>
                    <Typography className={classes.typography} variant="h6">
                        <br />
                        {'Valor atual da soja'}
                    </Typography>
                </Column1>
                <Column2 className={classes.columnTwoContentSoy}>
                    <CurrencyTextField
                        label="Valor da Soja"
                        variant="standard"
                        value={soy}
                        currencySymbol="$"
                        minimumValue="0"
                        outputFormat={'number'}
                        decimalCharacter=","
                        digitGroupSeparator="."
                        onChange={(event, value) => {
                            setSoy(value);
                            setSaveOptions((options) => ({
                                ...options,
                                soyValue: value,
                            }));
                        }}
                    />
                </Column2>
                <Column3>
                    <div className={classes.urgency}>
                        {' '}
                        {`${VALUE_PER_HECTARE_IN_BAGS.toFixed(
                            2
                        )} em sacas`}{' '}
                    </div>
                </Column3>
            </ContainerRow>
        </Container>
    );
}
