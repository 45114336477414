import { useContext } from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import { clientContext } from '../../contexts/ClientContext';

const useStyles = makeStyles((theme) => ({
    priceSoyBag: {
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: 20,
        transform: 'translateY(-10px)',
        letterSpacing: '0.3px',

        fontWeight: '300',
        color: '#2B303B',
    },
}));

export default function Subtotal({ subPrice, haMade = null }) {
    const { client } = useContext(clientContext);
    const classes = useStyles();

    return (
        <>
            {subPrice.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}
            <Typography className={classes.priceSoyBag}>
                {`${(!subPrice /
                    (haMade
                        ? haMade
                        : client.areaMade
                        ? client.areaMade
                        : client.ha) || !client.clientId
                    ? 0
                    : subPrice /
                      (haMade
                          ? haMade
                          : client.areaMade
                          ? client.areaMade
                          : client.ha)
                ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })} / ha `}
            </Typography>
        </>
    );
}
