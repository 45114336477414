import { useEffect } from 'react';

import { DrawerAdjustment } from './dashboard.styles';

/* Components */
import Header from '../../components/header/header.component';
import DashboardData from '../../components/dashboard-data/dashboard-data.component';

export default function Dashboard() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <DashboardData />
        </DrawerAdjustment>
    );
}
