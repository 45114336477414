import { useEffect } from 'react';

import { DrawerAdjustment } from './settings.styles';

/* Components */
import Header from '../../components/header/header.component';
import ChangePassword from '../../components/change-password/change-password.component';

export default function Settings() {
    useEffect(() => {
        document.body.style.background = '#fafafa';
    }, []);

    return (
        <DrawerAdjustment>
            <Header />
            <ChangePassword />
        </DrawerAdjustment>
    );
}
