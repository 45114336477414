/* Components */
import SignIn from '../../components/sign-in/sign-in.component';

export function SignInPage() {
    return (
        <>
            <SignIn />
        </>
    );
}
