import { useContext } from 'react';

import { NavLink } from 'react-router-dom';

/* Style */
import { useStyles } from './header-components.styles';

/* Material ui*/
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/* Icons */
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { GiFertilizerBag } from 'react-icons/gi';
import { FaBlackTie } from 'react-icons/fa';
import statesIcon from '../../../assets/statesIcon.svg';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

export default function ItemsList({ toggleDrawer }) {
    const { auth } = useContext(authContext);

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={toggleDrawer(false)}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon style={{ color: '#fff' }} />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </div>
            <Divider />
            <List>
                <NavLink to="/dashboard" activeClassName={classes.active}>
                    <ListItem button>
                        <ListItemIcon className={classes.colorText}>
                            <PersonIcon className={classes.iconLg} />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.colorText}
                            primary="Dashboard"
                        />
                    </ListItem>
                </NavLink>
                <NavLink to="/new-proposal" activeClassName={classes.active}>
                    <ListItem button>
                        <ListItemIcon className={classes.colorText}>
                            <AddIcon className={classes.iconLg} />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.colorText}
                            primary="Nova Proposta"
                        />
                    </ListItem>
                </NavLink>

                <NavLink to="/proposals" activeClassName={classes.active}>
                    <ListItem button>
                        <ListItemIcon className={classes.colorText}>
                            <AssignmentIcon className={classes.iconLg} />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.colorText}
                            primary="Propostas"
                        />
                    </ListItem>
                </NavLink>

                {auth.data.user.permissionId === 1 && (
                    <NavLink to="/fertilizer" activeClassName={classes.active}>
                        <ListItem button>
                            <ListItemIcon className={classes.colorText}>
                                <GiFertilizerBag className={classes.iconLg} />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.colorText}
                                primary="Adubos"
                            />
                        </ListItem>
                    </NavLink>
                )}

                {auth.data.user.permissionId === 1 && (
                    <NavLink to="/goals" activeClassName={classes.active}>
                        <ListItem button>
                            <ListItemIcon className={classes.colorText}>
                                <AssessmentIcon className={classes.iconLg} />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.colorText}
                                primary="Menu de Metas"
                            />
                        </ListItem>
                    </NavLink>
                )}

                {auth.data.user.permissionId === 1 && (
                    <NavLink to="/director" activeClassName={classes.active}>
                        <ListItem button>
                            <ListItemIcon className={classes.colorText}>
                                <FaBlackTie className={classes.iconLg} />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.colorText}
                                primary="Menu de Diretor"
                            />
                        </ListItem>
                    </NavLink>
                )}

                {auth.data.user.permissionId === 1 && (
                    <NavLink to="/state" activeClassName={classes.active}>
                        <ListItem button>
                            <ListItemIcon className={classes.colorText}>
                                <img
                                    src={statesIcon}
                                    alt=""
                                    style={{ width: '35px', height: '35px' }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.colorText}
                                primary="Menu de Estados"
                            />
                        </ListItem>
                    </NavLink>
                )}

                <NavLink to="/settings" activeClassName={classes.active}>
                    <ListItem button>
                        <ListItemIcon className={classes.colorText}>
                            <SettingsIcon className={classes.iconLg} />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.colorText}
                            primary="Configurações"
                        />
                    </ListItem>
                </NavLink>
            </List>
        </div>
    );
}
