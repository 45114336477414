import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 30px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    max-width: 80vw;
    display: grid;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    grid-template-areas:
        'title title'
        'table buttons';

    @media screen and (max-width: 1400px) {
        max-width: 94vw;
    }
    @media screen and (max-width: 1250px) {
        grid-template-areas:
            'title title'
            'table table'
            'buttons buttons';
    }
`;

export const SummaryTable = styled.div`
    display: grid;
    grid-area: table;
    justify-content: center;
`;
export const SendButtons = styled.div`
    display: grid;
    grid-area: buttons;
    justify-content: center;
    align-content: center;
    @media (min-width: 835px) and (max-width: 1250px) {
        margin-top: -15%;
        margin-bottom: 50px;
    }
    @media (min-width: 680px) and (max-width: 834px) {
        margin-top: -25%;
        margin-bottom: 50px;
    }
    @media (min-width: 590px) and (max-width: 679px) {
        margin-top: -30%;
        margin-bottom: 50px;
    }
    @media (min-width: 450px) and (max-width: 589px) {
        margin-top: -48%;
        margin-bottom: 50px;
    }
    @media (min-width: 390px) and (max-width: 449px) {
        margin-top: -85%;
        margin-bottom: 50px;
    }
    @media (min-width: 320px) and (max-width: 389px) {
        margin-top: -130%;
        margin-bottom: 50px;
    }
    @media (max-width: 319px) {
        margin-top: -185%;
        margin-bottom: 50px;
    }
`;

export const Title = styled.div`
    grid-area: title;
    border-bottom: 1px solid #e0e0e0;
    font-size: 22px;
    padding: 10px 10px 10px 18px;
    font-weight: 500;
    color: #444446;
    font-family: 'Roboto';
`;

export const InvisibleContainer = styled.div`
    height: 52px;
`;
